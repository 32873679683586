import React, { useState } from 'react';
//import { useQuery } from '@tanstack/react-query';
//import { getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useAddProductGroup } from "../../../components/project-builder-custom-hooks";
import Modal from 'react-modal';


export default function CustomGroupModal({ buttonClass, openButtonText, type, emptyGroups, setEmptyGroups }) {
    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    //const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', projectId ? projectId : currentProjectId], () => getProject(projectId ? projectId : currentProjectId));

    const { mutate: addProductGroup, isLoading: editIsLoading } = useAddProductGroup(currentProjectId);

    const [name, setName] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }


    const handleChange = (e) => {
        setName(e.target.value);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let groupData = {
            name: name
        }

        addProductGroup(groupData, {
            onSuccess: (responseData) => {
                closeModal();
                setName("");
                setEmptyGroups(
                    [
                        ...emptyGroups,
                        responseData
                    ]
                )
            }
        });

    }

    return (
        <>
            {type == "Add"
                ? <button className={buttonClass} type='button' onClick={openModal}>{openButtonText}</button >
                : <li onClick={openModal}>Edit Section</li>
            }
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="project-builder-modal project-builder-modal--xs project-builder-modal--paddingSmall"
            >
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container project-builder">
                    <div className='modal-title'>
                        {type == "Add" ? "Add" : "Edit" } Custom Group
                    </div>

                    <form className="project-builder-form" method="post" onSubmit={handleFormSubmit}>

                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="groupName">Group Name</label>
                                <input className="form-control" type="text" id="name" name="name" required value={name} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="form-actions">
                            <button className='btn btn-outline-alt' type='button' disabled={editIsLoading} onClick={closeModal} >cancel</button>
                            <button className='btn no-arrow' type='submit' disabled={editIsLoading}>save {type == "Add" ? "Group" : "changes"}</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
}