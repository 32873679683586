import React, { useState, useEffect } from 'react';

import ManufacturerOption from './ManufacturerOption.jsx';
import ModelOption from './ModelOption.jsx';

export default function GlassInfoModel({ type, setSearchBySize, setInfoSelected, modelInfo, setModelInfo }) {

    const handleManuChange = (value) => {
        setModelInfo(prevState => ({
            //...prevState,
            manufacturer: value
        }));
    }

    const handleModelChange = (e) => {
        let model = e.target.value;
        let selectElement = document.getElementById("model");
        let selectedIndex = selectElement.options[selectElement.selectedIndex];
        let dIn = selectedIndex.getAttribute("din");
        let dCm = selectedIndex.getAttribute("dcm");
        let hIn = selectedIndex.getAttribute("hin");
        let hCm = selectedIndex.getAttribute("hcm");
        let diagIn = selectedIndex.getAttribute("diagin");
        let diagCm = selectedIndex.getAttribute("diagcm");

        setModelInfo(prevState => ({
            ...prevState,
            model: model,
            dIn: dIn,
            dCm: dCm,
            hIn: hIn,
            hCm: hCm,
            diagIn: diagIn,
            diagCm: diagCm
        }));
    }

    const handleSearchBy = (e) => {
        e.preventDefault();
        setSearchBySize(prevState => !prevState);
    }

    useEffect(() => {
        modelInfo.manufacturer && modelInfo.model ? setInfoSelected(true) : setInfoSelected(false);
    }, [modelInfo]);

    useEffect(() => {
        setModelInfo({
            manufacturer: null,
            model: null,
            dIn: null,
            dCm: null,
            hIn: null,
            hCm: null
        });
        setInfoSelected(false);
    }, [type]);

    return (
        <div className="step-content no-pad-top">
            <div className="step-options step-options--column">
                <select
                    className="form-control"
                    name="manufacturer"
                    id="manufacturer"
                    value={modelInfo.manufacturer ? modelInfo.manufacturer : ""}
                    onChange={event => handleManuChange(event.target.value)}>

                    <option value="">Select Manufacturer</option>
                    <ManufacturerOption type={type} />
                </select>
                <select
                    className="form-control"
                    disabled={!modelInfo.manufacturer}
                    name="model"
                    id="model"
                    value={modelInfo.model ? modelInfo.model : ""}
                    onChange={event => handleModelChange(event)}>

                    <option>Select Model</option>
                    <ModelOption type={type} selectedManu={modelInfo.manufacturer} />
                </select>
            </div>

            {modelInfo.model !== null && modelInfo.model &&
                <div className="wwSearch-dimensions">
                    <p><u>Manufacturer Dimensions</u></p>
                    <p>Diameter: {modelInfo.dIn}in / {modelInfo.dCm}cm</p>
                    <p>Height: {modelInfo.hIn}in / {modelInfo.hCm}cm</p>
                    {type === 2 &&
                        <p>Diagonal: {modelInfo.diagIn}in / {modelInfo.diagCm}cm</p>
                    }
                </div>
            }
            {/*}
            <hr className="or-divider" />
            <p className="text-center">
                <a className="btn btn-outline-alt" onClick={event => handleSearchBy(event)} href="">Search by Size</a>
            </p>
            */}
        </div>
    )
}