import React from 'react';
import LayoutGroups from './LayoutGroups.jsx';

export default function Step3({ selectedProduct, selectedLayout, onChange }) {
	let selectedLayoutName = "";
	if (!(typeof selectedLayout.displayName === 'undefined')) {
		selectedLayoutName = selectedLayout.displayName.split(" ").reverse().join(",").replace(",", "-");
	}

	return (
		<div className={`lidSaverContainer lidSaverContainerStep3 ${selectedLayoutName}`}>
			<div className="stepHeader">
				<h2>Select Dispenser Layout</h2>
			</div>
			<LayoutGroups
				selectedProduct={selectedProduct}
				selectedLayout={selectedLayout}
				onChange={onChange}
			/>
		</div>
	)
}
