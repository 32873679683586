import React from 'react'

export default function Step1({ currency, onChange }) {

	return (
		<div className="lidSaverContainer lidSaverContainerStep1">
			<div className="stepHeader">
				<h2>Select Currency</h2>
			</div>
			<div className="stepContainer regionContainer step1">
				<select
					name="regionName"
					id="regionName"
					value={currency}
					onChange={event => onChange(event.target.value)}
				>
					<option value="0">US Dollars ($)</option>
					<option value="1">Euros (&euro;)</option>
				</select>
			</div>

		</div>
	)
}
