import React from 'react'

export default function Selections({ currency, dispenserType, selectedLayout }) {

    return (
        <div className="lidSaverSelections">
            <ul>
                <li>
                    <strong>Currency: </strong>{currency === "0" ? "US Dollars ($)" : "Euros (" + String.fromCharCode(8364) +")"}
                </li>
                <li>
                    <strong>Dispenser Type: </strong>{dispenserType}
                </li>
                <li>
                    <strong>Layout: </strong>{selectedLayout.displayName}
                </li>
            </ul>
        </div>
    )
}
