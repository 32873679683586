import React, { useState } from 'react';
import { getProject } from '../../../apis/project-builder';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useAddProduct, useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import Loader from '../../../../shared/react/components/loader.jsx';


export default function AddRemoveProductsButton({ productNodeId, parentProductId }) {
    //use productNodeId for adding products
    //use nodeId for adding videos
    //use itemSlug for adding documents

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: addProduct, isLoading: addIsLoading } = useAddProduct(currentProjectId);
    const { mutate: updateProduct, isLoading: updateIsLoading } = useUpdateProduct(currentProjectId);

    //const [isAdded, setIsAdded] = useState(false);

    const handleAdd = () => {
        const existingProduct = data.products.find(product => product.productNodeId === productNodeId);

        let productData = {
            productNodeId: productNodeId,
            quantity: 1,
            parentProductId: parentProductId
        };

        if (existingProduct) {
            productData = {
                id: existingProduct.id,
                quantity: existingProduct.quantity + 1
            }

            updateProduct(productData);
        }
        else {
            addProduct(productData);
        }
    }

    //const handleDelete = () => {
    //    console.log("delete");
    //    setIsAdded(false);
    //}

    return (
        <>
            {(isLoading || addIsLoading || updateIsLoading)
                ? <Loader className="ml-auto" show={true} /> 
                : <img className="icon-addItem ml-auto" src="/img/icon-add-item.png" alt="add item" onClick={handleAdd} />
            }
        </>
    );
}