import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import Video from '../video-item.jsx';
import LoaderSection from '../loader-section.jsx';
import { getUrlParams, generatePaginationHref } from '../../../utils/';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import Filters from '../filters.jsx';
import { getVideosBySearch } from '../../../apis/inspiration';
import { getVideoFilters } from '../../../apis/search'
import Magnific from '../magnific.jsx';
import toast from '../../../components/toast';
import FilteringBase from '../filtering-base';

class SearchVideos extends FilteringBase {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.baseState, {
            filters: {},
            pageSize: 12,
            firstSearch: true,
            showModal: false,
            modalIndex: 0,
            q: props.q
        });

        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleVideoClick = this.handleVideoClick.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.loadDebounce = debounce(this.load.bind(this), 1000);
    }

    get galleryItems() {
        return this.state.items.map(v => {
            return {
                type: v.url.indexOf('.mp4') >= 0 ? 'video' : 'iframe',
                src: v.url,
                description: v.title
            };
        });
    }

    setFilters() {

        return getVideoFilters().then(data => {
            var filters = JSON.parse(data);
            const tags = filters.Tags;
            const categories = filters.Categories;
            tags.forEach(t => t.selected = false);
            categories.forEach(c => c.selected = false);

            console.log(tags);
            this.setState({
                filters: {
                    Categories: categories,
                    Tags: tags
                }
            });
            return data;
        }).catch(err => {
            console.log(err);
        });

    }

    componentDidMount() {
        this.setFilters().then(() => {
            this.updateFilters();
            let params = getUrlParams();
            let pageNum;
            if (Object.keys(params).length) {
                pageNum = params.page !== '1' ? params.page : 1;
                this.setState({ page: pageNum }, () => {
                    this.load();
                });
            } else {
                this.load();
            }

            window.addEventListener('popstate', e => {
                this.updateFilters();
                let params = getUrlParams();
                let pageNum;
                if (Object.keys(params).length) {
                    pageNum = params.page !== '1' ? params.page : 1;
                    this.setState({ page: pageNum }, () => {
                        this.load(false);
                    });
                } else {
                    this.setState({ page: 1 }, () => { this.load(false) });
                }
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.q !== this.props.q) {
            this.setState({
                q: this.props.q
            }, () => {
                this.load();
            });
        }
    }

    // load products from the api
    load(pushState = true) {
        if (this.ajaxPromise) this.ajaxPromise.controller.abort();

        this.setState({
            isLoading: true,
            items: []
        });

        if (pushState && !this.state.firstSearch) {
            history.pushState(null, document.title, this.getUrl());
        }

        // put together api parameters
        const params = this.getAllFilters();
        params.pageSize = this.state.pageSize;
        // remove empty or undefined params
        Object.keys(params).forEach(key => {
            if (!params[key]) delete params[key];
        });

        this.setState({
            firstSearch: false
        });

        this.ajaxPromise = getVideosBySearch(params);


        this.ajaxPromise.then(data => {
            console.log(data);
            this.setState({
                items: data.records,
                totalRecords: data.totalRecords,
                isLoading: false
            });
            return data;
        })
            .catch(error => {
                console.log(error);
                if (error.name === 'AbortError') return error;
                this.setState({
                    isLoading: false
                });
                toast.error('There was an error getting videos');
                return error;
            });

        return this.ajaxPromise;
    }

    // set the selected state of the filters to false
    resetFilterData(data) {
        for (const [key, value] of Object.entries(data)) {
            value.forEach(v => v.selected = false);
        }
    }

    // update filters on page using the url parameters
    updateFilters() {
        const params = getUrlParams(),
            filters = Object.assign({}, this.state.filters);

        // reset filters to be unselected
        this.resetFilterData(filters);
        // go through params and update selected filters
        for (const paramKey in params) {
            const values = params[paramKey].split('|');
            const filterSection = Object.entries(filters).find(([key]) => kebabCase(key) === paramKey);
            console.log(filterSection, values);

            if (filterSection) {
                values.forEach(value => {
                    const filter = filterSection[1].find(f => f.Id === +value);
                    if (filter) filter.selected = true;
                });
            }
        }

        this.setState({
            filters: filters,
            page: params['page'] ? +params['page'] : 1
        });
    }

    // get all the selected filters at this time
    getAllFilters() {
        const params = {
            q: this.state.q,
            page: this.state.page
        };

        for (const [key, value] of Object.entries(this.state.filters)) {
            value.forEach(v => {
                if (v.selected) {
                    const paramKey = kebabCase(key),
                        encodedValue = v.Id;
                    if (params[paramKey]) params[paramKey].push(encodedValue);
                    else params[paramKey] = [encodedValue];
                }
            });
        }

        // pipe delimit array values
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) params[key] = params[key].join(',');
        });

        return params;
    }

    // get the url for the current window location
    getUrl() {
        const baseUrl = `${window.location.pathname}`,
            filters = this.getAllFilters(),
            params = [];
        for (const key in filters) {
            const val = filters[key];
            if (((key === 'page' && val > 1) || key !== 'page')) {
                if (val) params.push(`${key}=${encodeURIComponent(val)}`);
            }
        }

        return `${baseUrl}${params.length ? '?' : ''}${params.join('&')}`;
    }

    handleChange(e) {
        const target = event.target,
            isChecked = target.checked,
            name = target.name,
            value = +target.value,
            filters = Object.assign({}, this.state.filters);

        Object.keys(filters).forEach(key => {
            const match = filters[key].find(f => f.Id === value && key === name);
            if (match) match.selected = isChecked;
        });

        this.setState({
            filters: filters,
            page: 1
        }, () => {
            this.load();
        });
    }

    handleVideoClick(e) {
        e.preventDefault();

        let link = e.target;
        while (link.tagName !== 'A') {
            link = link.parentElement;
        }
        console.log(link);
        this.setState({
            showModal: true,
            modalIndex: +link.getAttribute('data-index')
        });
    }

    handleModalClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        let pagination,
            loader,
            results,
            count;
        if (this.showPagination()) {
            pagination = <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.pages()}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={this.state.page - 1}
                hrefBuilder={generatePaginationHref}
            />;
        }

        if (this.state.items.length > 0) {
            results = <div className="row">
                {this.state.items.map((video, index) => (
                    <Video key={index} {...video} onClick={this.handleVideoClick} index={index} quickAdd={this.props.quickAdd} />
                ))}
            </div>;
        }
        else if (!this.state.isLoading) {
            results = <p>No results found</p>;
        }

        if (this.state.isLoading) loader = <LoaderSection show={true} />;

        count = this.state.isLoading
            ? <></>
            : <p className="search-result-message"><span className="search-accent">{this.state.totalRecords}</span> results found for <span className="search-accent">'{this.state.q}'</span></p>;

        const selected =
            <div className="row">
                {Object.entries(this.state.filters).map(([key, value]) => (
                    <div key={key}>
                        {value.length > 0 &&
                            <div>
                                {value.filter(m => m.selected).map((filter, index) => (
                                    <div className="filter-bullets" key={index}>
                                        <input className="filter-bullets-checkbox" id={`${filter.Name}`
                                        } type="checkbox" name={key} value={filter.Id
                                        } onChange={this.handleChange} />
                                        <label htmlFor={`${filter.Name}`}>
                                            <span>{filter.Name}<span className="dull">&nbsp;&nbsp;X</span></span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                ))}
            </div>;

        return (
            <div>
                {count}
                <div className="row">
                    <div className="col-lg-3">
                        <Filters q={this.state.q} showSearch={false}>
                            {Object.entries(this.state.filters).map(([key, value]) => (
                                <div key={key}>
                                    {value.length > 0 &&
                                        <details className="filter-accordion" open role="group" aria-labelledby={'filter-' + kebabCase(key)}>
                                            <summary className="filter-accordion-header" id={'filter-' + kebabCase(key)}><span>{key}</span></summary>
                                            <div className="filter-accordion-content">
                                                {value.map((filter, index) => (
                                                    <div key={index}>
                                                        <input className="custom-checkbox square" id={filter.Name} type="checkbox" name={key} value={filter.Id} onChange={this.handleChange} checked={filter.selected} />
                                                        <label htmlFor={filter.Name}>
                                                            <span>{filter.Name}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </details>
                                    }
                                </div>
                            ))}
                        </Filters>
                    </div>
                    <div id="results" className="col-lg-9">
                        {selected}
                        {loader}
                        {results}
                        {pagination}
                    </div>

                    <Magnific items={this.galleryItems} show={this.state.showModal} onClose={this.handleModalClose} index={this.state.modalIndex} />
                </div>
            </div>
        );
    }
}

SearchVideos.defaultProps = {
    tags: [],
    categories: []
};

export default SearchVideos;