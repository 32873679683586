import React from "react";

const SearchInput = props => {

    return (
        <div className="row justify-content-center">
            <form method="get" onSubmit={props.onSubmit} className="col-lg-10">
                <div className="downloads-input-box">
                    <label className="sr-only" htmlFor="search-field">Search</label>
                    <input ref={props.searchInput} className="search-drawer-input document-search-input" value={props.q
                    } id="search-field" type="text" name="q" placeholder="Search..." required autoCapitalize="none" autoCorrect="off" autoFocus="true" />
                    <button className="search-drawer-btn document-search search-page-bar">
                        <img src="/img/search-glass.svg" alt="Search" height="40" width="40" />
                        <span className="sr-only">Search</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SearchInput;