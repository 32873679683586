import React from 'react'

export default function Product({ productGroup, dispenserType, onChange }) {
	const products = productGroup.baseProducts;

	return (
		<ul>
			{products.map((product, j) => (
				<li key={j} className={dispenserType === (productGroup.abbreviatedDisplayName + " - " + product.displayName) ? "is-active" : ""}>
					<label>
						<input
							type="radio"
							name="dispenserType"
							value={productGroup.abbreviatedDisplayName + " - " + product.displayName}
							onChange={event => onChange(event, products, j)}
							checked={dispenserType === (productGroup.abbreviatedDisplayName + " - " + product.displayName) ? "checked" : ""}
						/>
						{product.displayName}
					</label>
				</li>
			))}
		</ul>
	)
}
