import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProject } from '../../../apis/project-builder';
import { useEditProject } from '../../../components/project-builder-custom-hooks';
import EditProjectModal from './edit-project-modal.jsx';
import AttachmentsList from './attachments-list.jsx';
import SendQuoteConfirmationModal from './send-quote-confirmation-modal.jsx';
import Breadcrumbs from './breadcrumbs.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import ProductsList from './products-list.jsx';
import ProductsListByFamily from './products-list-by-family.jsx';
import ProductsListHeader from './products-list-header.jsx';
import ProductNotesList from './product-notes-list.jsx';
import Sidebar from './sidebar.jsx';
import toast from '../../../../shared/components/toast';


export default function ReviewQuote({ expandAll, setExpandAll }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: editProject, isLoading: editIsLoading } = useEditProject(currentProjectId);

    const [projectNotes, setProjectNotes] = useState(data.notes);
    const [headerText, setHeaderText] = useState(data.headerText)

    let notes = [];

    if (isSuccess) {
        data.products.map((product) => {
            notes.push(product.notes);
        })
    }

    const handleSaveProjectNotes = () => {
        const value = document.getElementById('project-notes').value;
        setProjectNotes(value);
        const payload = {
            id: currentProjectId,
            notes: value
        }
        editProject(payload, {
            onSuccess: () => toast.success("Project notes saved")
        });
    }

    const handleSaveProjectHeader = () => {
        const payload = {
            id: currentProjectId,
            headerText: headerText
        }
        editProject(payload, {
            onSuccess: () => toast.success("Project header saved")
        });
    }

    return (
        <div className="project-builder container section equal-margin">
            <Breadcrumbs />
            {isSuccess ? (
                <>
                    <div className='section-title'>{data.name}</div>
                    <div className="mobile-downloads-container">
                        <hr />
                        <div className="quote-downloads">
                            <button type="button" className="link-btn"><span className="icon icon--download"></span>PDF QUOTE</button>
                            <button type="button" className="link-btn"><span className="icon icon--download"></span>CSV QUOTE</button>
                            <button type="button" className="link-btn"><span className="icon icon--download"></span>SELL SHEETS</button>
                        </div>
                    </div>
                <div className="row review-quote-content">
                    <div className="col-lg-8">
                        <div className="added-section added-information">
                            <div className="section-header section-header--borderTop">
                                <div className="section-number">1</div>
                                <div className="section-title">Information</div>
                                <div className="quote-details">
                                    <ul>
                                            <li>Account Name: <span>{data.accountName}</span></li>
                                            <li>Phone Number: <span>{data.contactPhoneNumber}</span></li>
                                            <li>Email Address: <span>{data.contactEmailAddress}</span></li>
                                            <li>Zip Code: <span>{data.zipCode}</span></li>
                                    </ul>
                                </div>
                                <EditProjectModal
                                    formType="edit"
                                    openButtonText="Edit"
                                    buttonClass="link-btn underline" />
                            </div>
                        </div>

                            <div className="added-section added-information added-project-notes">
                                <div className="section-header section-header--borderTop">
                                    <div className="section-number">2</div>
                                    <div className="section-title">Project Header</div>
                                </div>
                                <div>
                                    <form className="project-builder-form">
                                        <label className="sr-only">Project Header</label>
                                        <textarea
                                            id="project-header"
                                            className="rounded-border rounded-border--paddingSmall"
                                            rows="8"
                                            value={headerText}
                                            placeholder="Write custom project header text..."
                                            onChange={(e) => setHeaderText(e.target.value) }
                                        >
                                        </textarea>
                                        <button type="button" className="btn no-arrow mt-1" disabled={editIsLoading} onClick={handleSaveProjectHeader}>Save Header</button>
                                    </form>
                                </div>
                            </div>

                            <div className="added-section added-products review-products">
                                <div className="section-header">
                                    <div className="section-number">3</div>
                                    <div className="section-title mr-auto">Products</div>
                                    {(data.products.length > 0) &&
                                        <ProductsListHeader
                                            expandAll={expandAll}
                                            setExpandAll={setExpandAll}
                                            reviewList
                                        />
                                    }
                            </div>

                            {(data.sortType == "ProductFamilyGrouping" || data.sortType == "CustomGrouping")
                                ? <div className="rounded-border rounded-border--noPadding"><ProductsListByFamily expandAll={expandAll} reviewList /></div>
                                : <div className="rounded-border"><ProductsList reviewList /></div>
                            }
                        </div>

                        <div className="added-section added-attachments">
                            <div className="section-header">
                                    <div className="section-number">4</div>
                                <div className="section-title">Attachments</div>
                            </div>
                            <div className="section-subtitle">Documents</div>
                            <div className="rounded-border mb-1">
                                <AttachmentsList
                                    deleteOnly={true}
                                    collapsedText="Show All Documents"
                                    expandedText="Show Less Documents"
                                    recommendedAttachments={data.attachements.filter((attachment) => attachment.isDocument)}
                                />
                            </div>

                            <div className="section-subtitle">Videos</div>
                            <div className="rounded-border rounded-border--paddingSmall">
                                <AttachmentsList
                                    deleteOnly={true}
                                    collapsedText="Show All Videos"
                                    expandedText="Show Less Videos"
                                    recommendedAttachments={data.attachements.filter((attachment) => attachment.isVideo)}
                                />
                            </div>
                        </div>

                        <div className="added-section added-information added-project-notes">
                            <div className="section-header">
                                    <div className="section-number">5</div>
                                <div className="section-title">Project Notes</div>
                            </div>
                            <div>
                                <form className="project-builder-form">
                                    <label className="sr-only">Project Notes</label>
                                        <textarea
                                            id="project-notes"
                                            className="rounded-border rounded-border--paddingSmall"
                                            rows="8"
                                            value={projectNotes}
                                            placeholder="Write any notes for the person you're sending the quote to here..."
                                        >
                                        </textarea>
                                        <button type="button" className="btn no-arrow mt-1" disabled={editIsLoading} onClick={handleSaveProjectNotes}>Save Notes</button>
                                </form>
                            </div>
                        </div>

                        <div className="added-section added-information added-product-notes">
                            <div className="section-header">
                                    <div className="section-number">6</div>
                                <div className="section-title">Product Notes</div>
                            </div>
                            <div className="rounded-border rounded-border--paddingSmall">
                                <ProductNotesList />
                            </div>
                        </div>

                            {data.isMarketingProject
                                ? null
                                : <div className="send-quote-card-container rounded-border mt-2">
                                    <div className="send-quote-card">
                                        <SendQuoteConfirmationModal currentProjectId={currentProjectId} />
                                        <div className="send-quote-card-content">
                                            <div className="quote-price"><span>QUOTE PRICE:</span> ${data.totalNetPrice.toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                    </div>

                        <Sidebar review />
                    </div>
                </>
            ) : <LoaderSection />}
		</div>
	);
}