import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProjects, getProject } from '../../../apis/project-builder';
import { useAddProduct, useAddAttachment, useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import Popover from '@mui/material/Popover';
import Select from 'react-select';
import DiscountDropdown from './discount-dropdown.jsx';
import toast from '../../../../shared/components/toast';

export default function QuickAdd({ item, index, videoNodeId, videoTitle, buttonText }) {

    const projectBuilderSearch = document.querySelector('.project-builder-search');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'quick-add' : undefined;

    //const { currentProjectId } = useCurrentProjectIdContext();
    const currentProjectId = null;

    const [fetchProjectDetail, setFetchProjectDetail] = useState(false);
    const [addToProjectId, setAddToProjectId] = useState(currentProjectId);

    const { data, isSuccess } = useQuery(['projects-list'], getProjects);

    const { data: projectData, isSuccess: projectIsSuccess } = useQuery({
        queryKey: ['project-detail', addToProjectId], 
        queryFn: () => getProject(addToProjectId),
        enabled: fetchProjectDetail
    });

    const [quantity, setQuantity] = useState(1);

    const { mutate: addProduct, isLoading } = useAddProduct(addToProjectId);
    const { mutate: addAttachment, isLoading: attachmentIsLoading } = useAddAttachment(addToProjectId);
    const { mutate: updateProduct, isLoading: updateIsLoading } = useUpdateProduct(addToProjectId);

    const [projectOptions, setProjectOptions] = useState([]);


    const [newDiscount, setNewDiscount] = useState({
        //id: videoNodeId ? "" : item.Id,
        discountType: "None",
        discountValue: 0
    });

    const [percentageAmount, setPercentageAmount] = useState(0);
    const [dollarAmount, setDollarAmount] = useState(0);

    const increaseQuantity = () => {
        setQuantity(prevState => prevState + 1);
    }

    const decreaseQuantity = () => {
        setQuantity(prevState => prevState - 1);
    }

    const handleSelectProject = (value) => {
        setAddToProjectId(value.value);
        setFetchProjectDetail(true);
    };

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddItem = () => {
        if (videoNodeId) {
            addAttachment({ videoNodeId: videoNodeId }, {
                onSuccess: () => toast.success("Video added to project")
            })
            return;
        }

        if (projectIsSuccess) {

            const existingProduct = projectData.products.find(product => product.productNodeId === parseInt(item.nodeId));

            let productData = {
                productNodeId: item.nodeId,
                quantity: quantity,
                discountType: newDiscount.discountType,
                discountValue: newDiscount.discountValue
            }

            if (existingProduct) {
                productData = {
                    id: existingProduct.id,
                    quantity: existingProduct.quantity + quantity,
                    discountType: newDiscount.discountType,
                    discountValue: newDiscount.discountValue
                }

                updateProduct(productData, {
                    onSuccess: () => {
                        handleClose();
                    }
                });
            }
            else {
                addProduct(productData, {
                    onSuccess: () => {
                        handleClose();
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (isSuccess) {
            let newOptions = [];
            data.forEach((project) => {
                let id = project.id;
                let name = project.name;
                let productCount = project.products.length;
                newOptions.push({
                    value: id,
                    label: name + " (" + productCount + ")"
                });
            });
            setProjectOptions(newOptions);
        }
    }, [data])

    return (
        <>
            {isSuccess ? 
            <>
                <button
                    className="btn btn--quickAdd btn-outline-alt"
                    aria-describedby={id}
                    onClick={handleClick}
                    type="button"
                    >
                    {projectBuilderSearch ? "Quick Add" : "Add to project builder"}                
                </button>

                <Popover
                    className="quick-add"
                    role="presentation"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: ((index + 1) % 2 == 0) ? 'left' : 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: ((index + 1) % 2 == 0) ? 'right' : 'left'
                    }}
                >
                    <div className="project-builder">
                        <div className="popper quick-add rounded-border">
                            <button className="project-builder-modal-close" onClick={handleClose}>
                                <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                            </button>
                            <div className="product-number">{item?.itemNumber}</div>
                            <div className="product-title">{item ? item.productName : videoTitle}</div>
                            <div className="product-details-container">
                                {item ?
                                    <>
                                        <a href={item?.pageUrl}>See Full Details</a>
                                        <div className="ml-auto quick-add-quantity">
                                            <span className="quantity--decrease" onClick={decreaseQuantity}><img src="/img/icon-decrease.png" alt="decrease quantity" /></span>
                                            <span>{quantity}</span>
                                            <span className="quantity--increase" onClick={increaseQuantity}><img src="/img/icon-increase.png" alt="increase quantity" /></span>
                                        </div>
                                    </>
                                    : null
                                }
                            </div>

                            <label>Select Project</label>
                            <Select
                                classNamePrefix="styled-select"
                                options={projectOptions}
                                //value={projectOptions.find(({ value }) => value === currentProjectId)}
                                value={projectOptions.find(({ value }) => value === addToProjectId)}
                                onChange={handleSelectProject}
                            />

                            {videoNodeId ? null :
                                <>
                                    <label>Discount</label>
                                    <DiscountDropdown
                                        newDiscount={newDiscount}
                                        setNewDiscount={setNewDiscount}
                                        percentageAmount={percentageAmount}
                                        setPercentageAmount={setPercentageAmount}
                                        dollarAmount={dollarAmount}
                                        setDollarAmount={setDollarAmount}
                                    />
                                </>
                            }

                                <button type="button" className="btn btn-block no-arrow mt-1" onClick={handleAddItem} disabled={!projectIsSuccess}>add to project</button>
                        </div>
                    </div>
                </Popover>
                </>
                : null}
        </>
    );
}