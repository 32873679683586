import React from 'react';

export default function SearchSection(props) {


    return (
        <section className={`section ${props.className ? props.className : ''}`}>
            <header className="section-header no-border">
                <h3 className="section-title">{props.title} <span>{props.titleBold}</span></h3>
                <div className="spacer"></div>
            </header>
            {props.children}
        </section>
    );
}