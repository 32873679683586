import jfetch from '@jontetz/jfetch';
import toast from '../../shared/components/toast';
import { useQueryClient, useMutation } from '@tanstack/react-query';

const apiHeader = () => {
    let authToken = JSON.parse(sessionStorage.getItem('authToken'));
    let header = {
        'Authorization': 'Bearer ' + authToken.Token
    }
    return header;
}

//Projects
export const useEditProject = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((updatedProject) => fetch(`/api/projectBuilder/projects`, {
            method: 'PATCH',
            body: JSON.stringify(updatedProject),
            headers: apiHeader()
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['projects-list']);
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                toast.success('Project updated');
            }
        })
    )
};


export const useDuplicateProject = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation(() => jfetch.post(`/api/projectBuilder/projects/${currentProjectId}/clone`, {}, { headers: apiHeader() }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['projects-list']);
                toast.success('Project duplicated');
            }
        })
    )
};

//Product Groups
export const useAddProductGroup = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((newProductGroup) => jfetch.post(`/api/projectBuilder/projects/${currentProjectId}/productGroups`, newProductGroup, { headers: apiHeader() }), {
            onSuccess: () => {
                //queryClient.invalidateQueries(['projects-list']);
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                toast.success("Custom product group added");
            }
        })
    )
};

export const useUpdateProductGroup = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation(({ ...payload }) => fetch(`/api/projectBuilder/projects/${currentProjectId}/productGroups`, {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: apiHeader()
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                toast.success("Custom product group updated");
            }
        })
    )
};

//Products
export const useUpdateProduct = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation(({ ...payload }) => fetch(`/api/projectBuilder/projects/${currentProjectId}/products`, {
            method: 'PATCH',
            body: JSON.stringify(payload),
            headers: apiHeader()
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['projects-list']);
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                toast.success("Product updated");
            }
        })
    )
};

export const useAddProduct = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((newProduct) => jfetch.post(`/api/projectBuilder/projects/${currentProjectId}/products`, newProduct, { headers: apiHeader() }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['projects-list']);
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                toast.success("Product added");
            }
        })
    )
};

export const useDeleteProduct = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((productId) => jfetch.del(`/api/projectBuilder/projects/${currentProjectId}/products/${productId}`, {}, { headers: apiHeader() }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['projects-list']);
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                //toast.success("Product removed");
            }
        })
    )
};

//Attachments
export const useAddAttachment = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((newAttachment) => jfetch.post(`/api/projectBuilder/projects/${currentProjectId}/attachments`, newAttachment, { headers: apiHeader() }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                queryClient.invalidateQueries(['project-detail', currentProjectId, 'recommendedProjectAttachments']);
                toast.success("Attachment added");
            }
        })
    )
};

export const useDeleteAttachment = (currentProjectId) => {
    const queryClient = useQueryClient();

    return (
        useMutation((attachmentId) => jfetch.del(`/api/projectBuilder/projects/${currentProjectId}/attachments/${attachmentId}`, {}, { headers: apiHeader() }), {
            onSuccess: () => {
                queryClient.invalidateQueries(['project-detail', currentProjectId]);
                queryClient.invalidateQueries(['project-detail', currentProjectId, 'recommendedProjectAttachments']);
                //toast.success("Attachment removed");
            }
        })
    )
};


