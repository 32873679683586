import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { generateCertificate, getEventsWithCodes } from '../../../../shared/apis/account';
import animatedScrollTo from 'animated-scroll-to';
const DashboardVU = props => {

    const navigate = useNavigate();
    const [eventsWithCodes, setEventsWithCodes] = useState([]);
    const [eventsUnique, setEventsUnique] = useState([]);
    useEffect(() => {


        const params = {
            email: props.contact.Email
        }

        props.getQuizzesForContact(params);
        props.getEventsForContact(params);

        getEventsWithCodes({ unique: false })
            .then(data => {
                setEventsWithCodes(data);

            })
            .catch(error => {
                console.log(error);

            });

        getEventsWithCodes({ unique: true })
            .then(data => {
                setEventsUnique(data);

            })
            .catch(error => {
                console.log(error);

            });

        animatedScrollTo(document.querySelector('#vu-top'));

    }, [props.contact]);


    const generateCertificatePdf = (item) => {

        const params = {
            quizId: item.id,
            contactEmail: props.contact.Email
        }

        generateCertificate(params)
            .then(x => {
                debugger;
                let a = document.createElement('a');
                a.href = x;
                a.click();
                a.remove();

                return false;
            })
    }

    const navigateToEventCode = (item) => {
        navigate("/eventcodes", { state: { data: eventsWithCodes.filter(m => m.eventId === item.eventId) } })
    }


    const navigateToEventCodes = () => {
        navigate("/my-events", { state: { data: eventsUnique, allCodes: eventsWithCodes } })
    }

    return (
        <div id="vu-top">
            {eventsUnique && eventsUnique.length ?
                <div className="container section equal-margin">
                    <div className="section-header"><h3 className="section-title"><span>VU Event Codes</span></h3><div className="spacer"></div><span className="section-header-link" onClick={() => navigateToEventCodes()}>View All &gt;</span></div>
                    <ul className="row">
                        {eventsUnique.slice(0, 4).map((item, index) => {
                            const startDate = new Date(Date.parse(item.eventDetails.startDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
                            const endDate = new Date(Date.parse(item.eventDetails.endDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
                            const closedDate = new Date(Date.parse(item.closedDate)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
                            return <li key={index} className="col-md-3">
                                <div className="vu-dashboard-quiz events">
                                    <div>
                                        <a className="text-link quiz-btn" onClick={() => navigateToEventCode(item)}><img src={item.eventDetails.mainImage} alt="quiz badge" /></a>
                                    </div>
                                    <div>
                                        <p className="card-title">{`${item.eventDetails.title}`}  </p>
                                        <br />
                                        <p className="vu-dashboard-content">{`Event Date:`}  </p>
                                        <p className="vu-dashboard-content">{`${startDate} - ${endDate}`}  </p>
                                        <br />
                                        <p className="vu-dashboard-content" style={{ fontSize: "19px", fontStyle: "italic", fontWeight: "bold", lineHeight: "1.21", letterSpacing: ".1px" }}>{`Event Registration Closes`}  </p>
                                        <p className="vu-dashboard-content" style={{ fontSize: "19px", fontStyle: "italic", fontWeight: "bold", lineHeight: "1.21", letterSpacing: ".1px" }}>{`${closedDate}.`}  </p>
                                        <br />
                                        <a className="text-link quiz-btn" onClick={() => navigateToEventCode(item)} >See codes for this event</a>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>

                </div> :
                <>
                </>
            }

            {props.events && props.events.length ?
                <div className="container section equal-margin" id="events">
                    <div className="section-header"><h3 className="section-title"><span>My Vollrath University Events</span></h3><div className="spacer"></div></div>
                    <ul className="row">
                        {props.events.map((item, index) => {
                            const startDate = new Date(Date.parse(item.eventDetails.startDate)).toDateString();
                            const endDate = new Date(Date.parse(item.eventDetails.endDate)).toDateString();
                            return <li key={index} className="col-md-3">
                                <div className="vu-dashboard-quiz events">
                                    <div>
                                        <a href={item.eventUrl}><img src={item.eventDetails.mainImage} alt="quiz badge" /></a>
                                    </div>
                                    <div>
                                        <p className="card-title">{`${item.eventDetails.title}`}  </p>
                                        <p className="vu-desc">{`${startDate} - ${endDate}`}  </p>
                                        <p className="vu-dashboard-content">{`Invite Code: ${item.registrationCode}`}</p>
                                        <a className="text-link" href={item.eventUrl} >Go To Event Page</a>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>

                </div> :
                <div className="container section equal-margin">
                    <div className="section-header"><h3 className="section-title"><span>My Vollrath University Events</span></h3><div className="spacer"></div></div>
                    <p>We could not find any upcoming events</p>
                </div>
            }
            {props.quizzes && props.quizzes.length ?
                <div className="container section equal-margin" id="quizzes">
                    <div className="section-header"><h3 className="section-title"><span>My Vollrath University Quizzes</span></h3><div className="spacer"></div></div>
                    <ul className="row">
                        {props.quizzes.map((item, index) =>

                            <li key={index} className="col-md-3">
                                <div className="vu-dashboard-quiz">
                                    <div>
                                        <img src={item.quizInfo.badgeUrl} alt="quiz badge" />
                                    </div>
                                    <div className="">
                                        <p className="vu-dashboard-title">{`${item.quizInfo.quizName} - ${item.quizInfo.category.categoryName} - ${item.quizInfo.level.levelName}`}  </p>
                                        <p className="vu-dashboard-content">{`Total Points: ${item.TotalScore}`}</p>
                                        <a className="text-link" style={{ cursor: 'pointer' }} onClick={() => generateCertificatePdf({ id: item.quizInfo.quizId })}> Download your certificate</a>

                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>

                </div> :
                <div className="container section equal-margin">
                    <div className="section-header"><h3 className="section-title"><span>My Vollrath University Quizzes</span></h3><div className="spacer"></div></div>
                    <p>We could not find any quizzes with a passing score</p>
                </div>
            }
        </div>
    );
}

export default DashboardVU;