import React from 'react';
import { transformBytes } from '../../utils/';

const DocumentCard = props => {

    const linkExtension = props.extension ? props.extension.toUpperCase() : 'PDF';
    const convertedFileSize = transformBytes(props.fileSize, true);
    const fileSize = convertedFileSize ? convertedFileSize : 'File Size Unknown';
    const updatedUrl = `/downloads/widen/${props.localSlug}?source=${props.sourceUrl}&ext=${linkExtension}`;
    const name = props.displayName !== "N/A" ? props.displayName : props.localSlug;

    return (
        <div className={props.colClass}>
            <article className="document-container">
                <div className="document-image">
                    <a className="document-img" target="_blank" href={props.sourceUrl}>
                        <svg className="icon icon-document-file-pdf"><use xlinkHref="#icon-document-file-pdf"></use></svg>
                    </a>
                </div>
                <div className="document-info">
                    <h3 className="document-title">
                        <a href={props.sourceUrl} target="_blank">
                            {name}
                        </a>
                    </h3>
                    <span>{fileSize}</span>
                    <a className="document-link" href={props.sourceUrl} target="_blank">
                        <span> - Download {linkExtension}</span>
                    </a>
                </div>
            </article>
        </div>
    );
};

export default DocumentCard;