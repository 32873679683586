import React, { useState, useEffect, useRef } from 'react';

export default function ContactForm({ setShowResults, showContactForm, contactFormItemNumber, searchBySize, type, modelInfo, size, brand, selectedColors, height, width, diagonal}) {
	const contactForm = useRef();

	const [error, setError] = useState(null);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formData, setFormData] = useState({
		FirstName: "",
		LastName: "",
		EmailAddress: "",
		Zipcode: "",
		PhoneNumber: "",
		Company: "",
		ItemNumber: contactFormItemNumber,
		Message: ""
	})

	const handleFormChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();

		let allData = {
			...formData,
			//Height: searchBySize ? parseFloat(stringToDecimal(sizeInfo.maxHeight)).toFixed(4) : parseFloat(modelInfo.hIn).toFixed(4),
			//Width: searchBySize ? parseFloat(stringToDecimal(sizeInfo.maxDia)).toFixed(4) : parseFloat(modelInfo.dIn).toFixed(4),
			//Diagonal: searchBySize ? (type === 2 ? parseFloat(stringToDecimal(sizeInfo.maxDiaWithHandle)).toFixed(4) : null) : (type === 2 ? parseFloat(modelInfo.diagIn).toFixed(4) : null),
			Height: height,
			Width: width,
			Diagonal: diagonal,
			RackColorCode: selectedColors.rackColorCode,
			RiserColorCode: selectedColors.riserColorCode,
			HasDifferentColoredTopRiser: selectedColors.hasDifferentColoredTopRiser,
			RackSize: size === 1 ? "Full" : "Half",
			RackBrand: brand === 1 ? "Signature" : "Traex",
			GlassType: type === 1 ? "Glass" : "Mug/Cup",
			GlasswareManufacturer: !searchBySize ? modelInfo.manufacturer : null,
			GlasswareModel: !searchBySize ? modelInfo.model : null
		};

		const requestOptions = {
			body: JSON.stringify(allData),
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			}
		};

		let url = '/api/WarewashingConfiguration/SubmitContactForm';

		fetch(url, requestOptions)
			.then((response) => {
				if (response.ok) {
					setShowResults(false);
					setFormSubmitted(true);
				}
				else {
					throw new Error('An error occured while submitting your form');
				}
			})
			.catch((error) => {
				setError(true);
				setShowResults(false);
				setFormSubmitted(true);
				//console.log(error);
			})
	}

	useEffect(() => {
		contactForm.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}, []);

	return (
		<>
			{showContactForm && !formSubmitted && !error &&
				<div>
					<div ref={contactForm} className="section section-intro">
						<div className="row justify-content-md-center">
							<div className="col-lg-8">
								<div className="intro-blk no-top-margin">
									<h2 className="intro-blk-title">Connect with a sales Representative</h2>
									<p className="intro-blk-copy">
										Send in your requests or questions today and a qualified Sales Representative will connect with you.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="row justify-content-md-center">
							{showContactForm && !formSubmitted &&
								<div className="col-lg-10">
									<form id="ww-contact-form" action="#" method="post" onSubmit={handleFormSubmit}>
										<div className="form-group">
											<label htmlFor="FirstName">First Name*</label>
									<input className="form-control" name="FirstName" type="text" placeholder="John" onChange={handleFormChange} required />
										</div>
										<div className="form-group">
											<label htmlFor="LastName">Last Name*</label>
									<input className="form-control" name="LastName" type="text" placeholder="Doe" onChange={handleFormChange} required />
										</div>
										<div className="form-group">
											<label htmlFor="EmailAddress">Your Email Address*</label>
											<input className="form-control" name="EmailAddress" type="email" placeholder="hello@vollrath.com" onChange={handleFormChange} required />
										</div>
										<div className="form-group">
											<label htmlFor="ZipCode">Zipcode*</label>
											<input className="form-control" name="Zipcode" type="number" placeholder="12345" onChange={handleFormChange} required />
										</div>
										<div className="form-group">
											<label htmlFor="PhoneNumber">Phone Number*</label>
									<input className="form-control" name="PhoneNumber" type="tel" placeholder="123-456-7891" onChange={handleFormChange} required />
										</div>
										<div className="form-group">
											<label htmlFor="Company">Company</label>
									<input className="form-control" name="Company" type="text" placeholder="Acme Inc" onChange={handleFormChange} />
										</div>
										<div className="form-group">
											<label htmlFor="ItemNumber">Item Number</label>
											<input className="form-control" name="ItemNumber" type="text" placeholder="5330" type="text" value={formData.ItemNumber} onChange={handleFormChange} />
										</div>
										<div className="form-group">
											<label htmlFor="Message">Message</label>
									<textarea className="form-control" name="Message" placeholder="Start Typing..." rows="4" onChange={handleFormChange} />
										</div>
										<div className="form-actions">
											<button className="btn btn-outline-alt">Submit</button>
										</div>
									</form>
								</div>
							}
						
						</div>
					</div>
				</div>
			}
			{formSubmitted && !error &&
				<div className="section">
					<div className="row justify-content-md-center text-center">
						<h3>Your Message has been sent. <br/>A sales representative will contact you soon.</h3>
					</div>
				</div>
			}
			{error &&
				<div className="section text-center">
					<h3>An error occured while submitting your form. Please try again later.</h3>
				</div>
			}
		</>
	)
}
