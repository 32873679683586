import React from 'react';
import Layout from './Layout.jsx';

export default function LayoutGroups({ selectedProduct, selectedLayout, onChange }) {
	const layoutGroups = selectedProduct.layoutGroups;

	const printSquares = (layoutGroup) => {
		const groupName = layoutGroup.displayName;
		let totalSquares = 1;
		if (groupName === 'Vertical' || groupName === 'Horizontal' || groupName === 'Square') {
			totalSquares = parseInt(layoutGroup.layouts[layoutGroup.layouts.length - 1].code.charAt(0));
		}

		let content = [];
		for (let i = 0; i < totalSquares; i++) {
			content.push(<span key={i} className="square"></span>)
		}
		return content;
	}

	return (
		<div className="stepContainer dispenserLayout step3">
			{layoutGroups.map((layoutGroup, i) => (
				<div
					key={i}
					className={`layoutContainer layoutContainer${(i + 1) + " " + layoutGroup.displayName}`}
				>
					<div className="squareContainer">
						{printSquares(layoutGroup)}
					</div>
					<Layout
						layoutGroup={layoutGroup}
						selectedLayout={selectedLayout}
						onChange={onChange}
					/>
				</div>
			))}
		</div>
	)
}
