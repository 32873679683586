import React, { useEffect, useState } from 'react';

const DashboardProfile = props => {

    const [contactInfo, setContactInfo] = useState({
        email: '',
        fName: '',
        lName: '',
        oldPassword: '',
        emailChangePassword: '',
        newPassword: '',
        confirmPassword: '',
        title: '',
        company: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        phoneNumber: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingSecurity, setIsChangingSecurity] = useState(false);

    const handleChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);

        const email = !contactInfo.email ? props.contact.Email : contactInfo.email;

        const params = {
            email: email,
            firstName: contactInfo.fName,
            lastName: contactInfo.lName,
            emailChangePassword: contactInfo.emailChangePassword,
            oldPassword: contactInfo.oldPassword,
            password: contactInfo.newPassword,
            passwordConfirmation: contactInfo.confirmPassword,
            contactTitle: contactInfo.title,
            company: contactInfo.company,
            street: contactInfo.street,
            city: contactInfo.city,
            state: contactInfo.state,
            country: contactInfo.country,
            zipCode: contactInfo.zipCode,
            phoneNumber: contactInfo.phoneNumber
        }
        setIsEditing(false);
        setIsChangingSecurity(false);
        props.updateContact(params);
    }

    const toggleEditing = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    }

    const toggleSecurity = (e) => {
        e.preventDefault();
        setIsChangingSecurity(!isChangingSecurity);
    }

    const resetForm = (e) => {
        e.preventDefault();
        setIsChangingSecurity(false);
        setIsEditing(false);
    }

    useEffect(() => {
        setContactInfo({
            fName: props.contact.FirstName,
            lName: props.contact.LastName,
            title: props.contact.Title,
            company: props.contact.Company,
            street: props.contact.Street,
            city: props.contact.City,
            state: props.contact.State,
            country: props.contact.Country,
            zipCode: props.contact.ZipCode,
            phoneNumber: props.contact.PhoneNumber
        });
    }, [props.contact]);

    const editClass = isEditing ? '' : 'event-confirmation-info';
    return (
        <div className="container section profile equal-margin">

            <div className="section-header"><h3 className="section-title"><span>My Profile</span></h3><div className="spacer"></div></div>

            <form id="profile-form" action="#" method="post" onSubmit={handleSubmit} ref="">
                {(!isEditing && !isChangingSecurity) ?
                    <ul className="btn-container add-margin-btm col-md-8 justify-content-center">
                        <li className="form-actions">
                            <button className=" btn btn-outline no-arrow left-padding-20  margin-right-40" onClick={toggleEditing}>Edit</button>
                        </li>
                        <li className="form-actions">
                            <button className=" btn btn-outline no-arrow left-padding-20" onClick={toggleSecurity}>Update Security Items</button>
                        </li>
                    </ul>
                    :
                    <ul className="btn-container add-margin-btm col-md-8 justify-content-center">
                        <li className="form-actions">
                            <button className="btn no-arrow left-padding-20 margin-right-40" type="submit">Update</button>
                        </li>
                        <li className="form-actions">
                            <button className="btn btn-outline no-arrow left-padding-20" onClick={resetForm}>Cancel</button>
                        </li>
                    </ul>
                }
                {!isChangingSecurity &&
                    <div className="row justify-content-center">
                        <div className="form-group col-md-6">
                            <div className="form-group border">
                                <label htmlFor="fName" className="small-label">First Name</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="fName" name="fName" type="text" value={contactInfo.fName}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="lName" className="small-label">Last Name</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="lName" name="lName" type="text" value={contactInfo.lName}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="title" className="small-label">Title</label>
                                <div className="inner title">
                                    <img alt="Briefcase" src="/img/briefcase-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="title" name="title" type="text" value={contactInfo.title}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="company" className="small-label">Company</label>
                                <div className="inner company">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="company" name="company" type="text" value={contactInfo.company}
                                        onChange={handleChange} />

                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="phoneNumber" className="small-label">Phone Number</label>
                                <div className="inner phone">
                                    <img alt="Phone" src="/img/icon-phone.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="phoneNumber" name="phoneNumber" type="text" value={contactInfo.phoneNumber}
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="form-group border">
                                <label htmlFor="Street" className="small-label">Street</label>
                                <div className="inner name">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="street" name="street" type="text" value={contactInfo.street}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="city" className="small-label">City</label>
                                <div className="inner name">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="city" name="city" type="text" value={contactInfo.city}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="state" className="small-label">State</label>
                                <div className="inner title">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="state" name="state" type="text" value={contactInfo.state}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="country" className="small-label">Country</label>
                                <div className="inner company">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="country" name="country" type="text" value={contactInfo.country}
                                        onChange={handleChange} />

                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="zipCode" className="small-label">Postal Code</label>
                                <div className="inner phone">
                                    <img alt="Building" src="/img/building-icon.svg" />
                                    <input className={`form-control ${editClass}`} disabled={!isEditing} id="zipCode" name="zipCode" type="text" value={contactInfo.zipCode}
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {isChangingSecurity &&
                    <div className="row justify-content-center col-md-12">
                        <div className="form-group col-md-6">
                            <div className="section-header"><h3 className="section-title"><span>Update Password</span></h3><div className="spacer"></div></div>
                            <div className="form-group border">
                                <label htmlFor="oldPassword" className="small-label">Current Password</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control`} id="oldPassword" name="oldPassword" type="text" value={contactInfo.oldPassword}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="newPassword" className="small-label">New Password</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control`} id="newPassword" name="newPassword" type="text" value={contactInfo.newPassword}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="confirmPassword" className="small-label">Confirm Password</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control`} id="confirmPassword" name="confirmPassword" type="text" value={contactInfo.confirmPassword}
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="section-header"><h3 className="section-title"><span>Update Email</span></h3><div className="spacer"></div></div>
                            <div className="form-group border">
                                <label htmlFor="email" className="small-label">New Email</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control`} id="email" name="email" type="text" value={contactInfo.email}
                                        onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group border">
                                <label htmlFor="emailChangePassword" className="small-label">Current Password</label>
                                <div className="inner name">
                                    <img alt="Profile Icon" src="/img/profile-icon.svg" />
                                    <input className={`form-control`} id="emailChangePassword" name="emailChangePassword" type="text" value={contactInfo.emailChangePassword}
                                        onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>

        </div>
    );
}



DashboardProfile.defaultProps = {
    contact: {}
};

export default DashboardProfile;