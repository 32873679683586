import React, { useState, useEffect, createContext, useContext } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate,
    useLocation
} from "react-router-dom";
import {
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MyProjects from './my-projects.jsx';
import ProjectDetail from './project-detail.jsx';
import ReviewQuote from './review-quote.jsx';
import ReceivedQuote from './received-quote.jsx';
import Search from './search.jsx';

//this flag is used to render the ReceivedQuote component on the /projects/index mvc view
let isReceivedQuoteView = false;
let modelProjectId = null;
const container = document.getElementById("received-quote-view");

if (container) {
    isReceivedQuoteView = true;
    modelProjectId = container.dataset.projectid;
}

export const CurrentProjectIdContext = createContext();
export const useCurrentProjectIdContext = () => useContext(CurrentProjectIdContext);

export const CurrentProjectIdProvider = ({ children }) => {
    const [currentProjectId, setCurrentProjectId] = useState(modelProjectId ? modelProjectId : null);

    return (
        <CurrentProjectIdContext.Provider value={{ currentProjectId, setCurrentProjectId }}>
            {children}
        </CurrentProjectIdContext.Provider>    
    )
};

const queryClient = new QueryClient();

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


export default function ProjectBuilderMain() {
    //const [sortBy, setSortBy] = useState(1);
    const [expandAll, setExpandAll] = useState(true);

    const [authToken, setAuthToken] = useState({
        ExpiresAtUtc: null,
        Token: null
    });

    const [isTokenError, setIsTokenError] = useState(false);

    let clientId = 'vollrathfoodservice.com';
    let clientSecret = 'bd278b28-bf05-42be-a6b2-ad935d93388d';


    const getToken = async () => {
        let apiUrl = '/api/ClientToken/Get?clientId=';
        await fetch(apiUrl + clientId + '&clientSecret=' + clientSecret)
            .then(response => {
                if (!response.ok) {
                    setIsTokenError(true)
                    setAuthToken({
                        ExpiresAtUtc: null,
                        Token: null
                    })
                };
                return response.json();
            })
            .then(data => {
                setAuthToken({
                    ExpiresAtUtc: data.expiresAtUtc,
                    Token: data.token
                });
                sessionStorage.setItem('authToken', JSON.stringify(data));
            });
    }

    useEffect(() => {
        let sessionToken = JSON.parse(sessionStorage.getItem('authToken'));
        if (!(sessionToken === null)) {
            if (typeof sessionToken.Token !== 'undefined') {
                let tokenExpirationDate = new Date(sessionToken.ExpiresAtUtc);
                let now = new Date();
                if (tokenExpirationDate < now) {
                    sessionStorage.removeItem('authToken');
                    getToken();
                }
                else {
                    setAuthToken({
                        ExpiresAtUtc: sessionToken.ExpiresAtUtc,
                        Token: sessionToken.Token
                    });
                }
            }
            else {
                getToken();
            }
        }
        else {
            getToken();
        }
    }, [])

    return (
        <CurrentProjectIdProvider>
            <QueryClientProvider client={queryClient}>
                {authToken.Token ?
                    isReceivedQuoteView
                    ? <Router>
                        <Routes>
                            <Route path="*" element={<ReceivedQuote expandAll={expandAll} setExpandAll={setExpandAll} />} />
                        </Routes>
                        </Router>
                    : <>
                        <ScrollToTop />
                        <Routes>
                            <Route index element={<MyProjects />} />
                            <Route path=":project" element={<ProjectDetail expandAll={expandAll} setExpandAll={setExpandAll} />} />
                            <Route path=":project/review-quote" element={<ReviewQuote expandAll={expandAll} setExpandAll={setExpandAll} />} />
                            <Route path=":project/received-quote" element={<ReceivedQuote expandAll={expandAll} setExpandAll={setExpandAll} />} />
                            <Route path="search/*" element={<Search />} />
                        </Routes>
                    </>
                : <div className="container text-center">Getting Authorization Token...</div>
                }
                <ReactQueryDevtools />
            </QueryClientProvider>
        </CurrentProjectIdProvider>
    );
}
