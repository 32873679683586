import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useDeleteProduct, useDeleteAttachment, useEditProject, useAddProduct, useAddAttachment } from '../../../components/project-builder-custom-hooks';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import Modal from 'react-modal';
import toast from '../../../../shared/components/toast';

export default function OverviewModal({ projectId, isOpen, closeOverviewModal }) {
    //type can be project, product, or family
    //change text accordingly
    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', projectId], _ => {
        return getProject(projectId)
    });

    const USDollarFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const projectCost = applyDiscount => {

        let projectCost = data.totalProductPrice.toFixed(2);
        if (applyDiscount) projectCost -= data.projectDiscount;

        return USDollarFormat.format(projectCost);
    }

    useEffect(() => {
        console.log(data)
    }, [data]);

    return (
        <>
            {data &&
                <Modal
                    width="800px"
                    isOpen={isOpen}
                    onRequestClose={closeOverviewModal}
                    className="project-builder project-builder-modal project-builder-modal--large"
                >

                    <button className="project-builder-modal-close" onClick={closeOverviewModal}>
                        <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                    </button>

                    <div className="container text-center">
                        <div className='modal-title mb-2'>{data.projectName} Overview</div>
                        <div className="project-builder-overview text-left">

                            <div className="overview-row">
                                <div className="overview-container">
                                    <div className="label">Project Name</div>
                                    {data.name}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Project ID</div>
                                    {data.id}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Account Name</div>
                                    {data.accountName}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Zip Code</div>
                                    {data.zipCode}
                                </div>

                                <div className="overview-container">
                                    <div className="label">phone number <span>(customer)</span></div>
                                    {data.contactPhoneNumber}
                                </div>

                                <div className="overview-container">
                                    <div className="label">email address <span>(customer)</span></div>
                                    {data.contactEmailAddress}
                                </div>
                            </div>

                            <div className="overview-divider"></div>

                            <div className="overview-row">
                                <div className="overview-container">
                                    <div className="label">Project Status</div>
                                    {data.status}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Project Type</div>
                                    {data.type}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Project Format</div>
                                    {data.isMarketingProject ? "Sales Project" : "Quote Project"}
                                </div>
                            </div>

                            <div className="overview-divider"></div>

                            <div className="overview-row">
                                <div className="overview-container">
                                    <div className="label">Project Cost</div>
                                    {data.isMarketingProject ? "N/A" : projectCost(false)}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Project Discount</div>
                                    {data.discountType == "None" ? "N/A" : "-$" + data.projectDiscount.toFixed(2)}
                                </div>

                                <div className="overview-container">
                                    <div className="label">Discounted Cost</div>
                                    {data.isMarketingProject ? "N/A" : projectCost(true)}
                                </div>

                            </div>

                            <div className="overview-divider"></div>

                            <div className="notes-container" style={{ marginBottom: '30px' }}>
                                <div className="label">Project Notes</div>
                                {data.notes}
                            </div>

                            <div className="overview-divider"></div>

                            <div >

                                {data.products.length > 0 &&
                                    <>
                                        <div className="row">
                                            <div className="col-2 label">SKU</div>
                                            <div className="col-4 label">Product</div>
                                            <div className="col-3 label text-left">Price/Disc.</div>
                                            <div className="col-1 label text-left">QTY</div>
                                            <div className="col-2 label text-left">Total</div>
                                        </div>

                                        {data.products.map(product => {
                                            return (
                                                <div key={product.id} className="product-row">
                                                    <div className="row">
                                                        <div className="col-2">{product.sku}</div>
                                                        <div className="col-4">{product.title}</div>
                                                        <div className="col-3 text-left">
                                                            {product.ItemDiscount ? (
                                                                <>
                                                                    <span>{USDollarFormat.format(product.netPrice)} </span>
                                                                    <span className="product-card-price--original">{USDollarFormat.format(product.listPrice)}</span>

                                                                </>
                                                            ) : (
                                                                <span>{USDollarFormat.format(product.listPrice)}</span>
                                                            )
                                                            }
                                                        </div>

                                                        <div className="col-1 text-left">{product.quantity}</div>
                                                        <div className="col-2 text-left">{USDollarFormat.format(product.netPrice * product.quantity)}</div>
                                                    </div>

                                                    <div>
                                                        {product.accessories.map(accessory => {
                                                            return (
                                                                <div className="row" style={{ color: '#949292', marginTop: '10px' }}>
                                                                    <div className="col-2">{accessory.sku}</div>
                                                                    <div className="col-4">
                                                                        <img src='/img/icons8-attachment-24.png' style={{ height: '16px', width: '16px' }} />
                                                                        {accessory.title}
                                                                    </div>
                                                                    <div className="col-3 text-left">
                                                                        {accessory.itemDiscount ? (
                                                                            <>
                                                                                <span>{USDollarFormat.format(accessory.netPrice)} </span>
                                                                                <span className="product-card-price--original">{USDollarFormat.format(accessory.listPrice)}</span>

                                                                            </>
                                                                        ) : (
                                                                            <span>{USDollarFormat.format(accessory.listPrice)}</span>
                                                                        )
                                                                        }
                                                                    </div>

                                                                    <div className="col-1 text-left">{accessory.quantity}</div>
                                                                    <div className="col-2 text-left">{USDollarFormat.format(accessory.netPrice * accessory.quantity)}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>


                                                </div>

                                            )
                                        })}

                                    </>
                                }
                            </div>

                            <button className='btn btn-outline-alt' type='button' onClick={closeOverviewModal}>Close Overview</button>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}