import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useEditProject } from "../../../components/project-builder-custom-hooks";
import Select from 'react-select';

export default function EditProjectStatus({ projectId, status }) {
	const { data } = useQuery(['project-detail', projectId], () => getProject(projectId));
	const { mutate: editProject, isLoading: editIsLoading } = useEditProject(projectId);

	const options = [];
	const allStatusOptions = ["In Progress", "Outgoing", "Incoming", "Completed", "Archived", "None"];
	allStatusOptions.forEach((item) => {
		options.push({ value: item, label: item });
	});

	const handleEditStatusChange = (selectedFilter) => {
		let projectData = {
			id: projectId,
			status: selectedFilter.value
		}

		editProject(projectData, {
			onSuccess: () => {

			}
		});
	};

	return (
		<div className="pill">
			<Select
				classNamePrefix="no-border-select"
				options={options}
				defaultValue={status}
				value={status }
				isSearchable={false}
				onChange={(value) => handleEditStatusChange(value)}
			/>
		</div>
	);

}