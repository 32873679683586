import React from 'react';
import ReactDOM from 'react-dom';
import App from '../react/components/portal-dashboard/Dashboard.jsx';


export default class Dashboard {
    constructor() {
        this.container = document.querySelector('#portal-dashboard');

        if (this.container) {
            ReactDOM.render(
                <React.StrictMode>
                    <App portalModules={this.container.dataset.modules} contact={this.container.dataset.contact}
                        notifications={this.container.dataset.notifications} role={this.container.dataset.role}
                        filterableGrid={this.container.dataset.filterableGrid}
                    />
                </React.StrictMode >,
                document.getElementById('portal-dashboard')
            );
        }


    }
}

window.PortalDashboard = function () {
    ReactDOM.render(<App />, document.getElementById('portal-dashboard'));
    return this;
}