import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import AttachmentsListItem from './attachments-list-item.jsx';


export default function AttachmentsList({ recommendedAttachments, collapsedText, expandedText, deleteOnly, readOnly }) {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded((prev) => !prev);
    }


    return (
        <>
            <ul className="attachments-list attachments-list--paddingNone">
                {recommendedAttachments.slice(0, 4).map((attachment, index) => (
                    <AttachmentsListItem attachment={attachment} key={index} deleteOnly={deleteOnly} readOnly={readOnly} />
                ))}
            </ul>

            {recommendedAttachments.length > 4 &&
                <Accordion expanded={isExpanded}>
                    <AccordionSummary aria-controls="recommendedAttachments-content" id="recommendedAttachments-header" onClick={toggleAccordion}>
                        <div className="show-all">{isExpanded ? expandedText : collapsedText + " (" + recommendedAttachments.length + ")"}</div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <ul className="attachments-list attachments-list--paddingNone">
                            {recommendedAttachments.slice(4).map((attachment, index) => (
                                <AttachmentsListItem attachment={attachment} key={index} deleteOnly={deleteOnly} readOnly={readOnly} />
                            ))}
                        </ul>
                    </AccordionDetails>

                </Accordion>
            }
        </>
    );
}