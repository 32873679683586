import React, { Component } from 'react';
import { getDocuments, getDocCount, getDocCodes } from '../../../apis/search';
import LoaderSection from '../loader-section.jsx';
import DocumentCard from '../document-card.jsx';
import SearchSection from '../search-section.jsx';
import Product from '../product-item.jsx';
import Filters from '../filters.jsx';
import ReactPaginate from 'react-paginate';
import animatedScrollTo from 'animated-scroll-to';
import { getUrlParams } from '../../../utils/';


class SearchDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentItems: [],
            isLoading: false,
            pagePath: location.pathname,
            q: props.q,
            totalRecords: 0,
            productDocs: [],
            pageSize: 12,
            page: 1,
            showDocs: true,
            docTypes: {},
            showFilters: true,
            categoryList: [],
            firstLoad: true,
            docCount: {}
        }

        this.loadDocuments = this.loadDocuments.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.getDocTypeCount = this.getDocTypeCount.bind(this);
    }

    getDocumentCodes() {

        getDocCodes()
            .then(data => {
                this.setState({
                    docTypes: JSON.parse(data)
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.getDocumentCodes();
        this.getDocTypeCount();
        const params = getUrlParams();
        let pageNum, q;

        if (Object.keys(params).length) {
            pageNum = params.page && params.page !== '1' ? params.page : 1;
            q = params.q ? params.q : '';
            this.setState({
                page: pageNum,
                categoryList: [],
                q: q
            }, () => {
                this.loadDocuments();
            });
        } else {
            this.setState({
                categoryList: []
            }, () => {
                this.loadDocuments();
            });

        }

        window.addEventListener('popstate', e => {
            let params = getUrlParams();
            let pageNum;
            let q;

            if (Object.keys(params).length) {
                this.cleanUpState();
                pageNum = params.page && params.page !== '1' ? params.page : 1;
                q = params.q && params.q !== '' ? params.q : '';
                this.setState({
                    page: pageNum,
                    q: q,
                    categoryList: []
                }, () => {
                    this.loadDocuments(false);
                });
            } else {
                this.setState({ page: 1 }, () => {
                    this.resetSearch(e);
                });
            }
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.q !== this.props.q) {
            this.setState({
                q: this.props.q
            }, () => {
                this.loadDocuments();
            });
        }
    }

    resetFilters() {
        Array.from(document.querySelectorAll('.document-checkbox')).map((item, key) => {
            if (item.checked) item.checked = false;
        });
    }


    removeFiltersWithBullets(e) {

        const target = e.target,
            name = target.name;

        const categories = [...this.state.categoryList];
        Array.from(document.querySelectorAll('.document-checkbox')).map((item, key) => {
            if (item.checked && item.name === name) item.checked = false;
        });

        const filtered = categories.filter(item => item !== name);

        this.setState({
            categoryList: filtered
        }, () => {
            this.loadDocuments(filtered);
        });
    }


    resetSearch() {
        this.cleanUpState();
        this.resetFilters();
        this.searchInput.current.focus();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    //logic for filters
    onCheckBoxChange(e) {
        const target = e.target,
            isChecked = target.checked,
            name = target.name;
        let categories = [...this.state.categoryList];

        if (isChecked) {
            categories.push(name);
            this.setState({
                showDocs: true,
                categoryList: categories
            }, () => {
                this.loadDocuments(categories);
            });
        }
        else {
            const filtered = categories.filter(item => item !== name);

            this.setState({
                categoryList: filtered
            }, () => {
                this.loadDocuments(filtered);
            });
        }
    }

    cleanUpState() {
        this.setState({
            documentItems: [],
            directProduct: {},
            productDocs: [],
            page: 1,
            q: ''
        });
    }

    pages() {
        return Math.ceil(this.state.totalRecords / this.state.pageSize);
    }

    showPagination() {
        return this.state.totalRecords > this.state.pageSize;
    }

    handlePageClick(data) {
        this.setState({
            page: data.selected + 1
        }, () => {
            this.loadDocuments(this.state.categoryList, true);
            animatedScrollTo(document.querySelector('.documents-search-section'));
        });
    }

    getUrl() {
        const filters = {
            q: this.state.q,
            page: this.state.page
        };
        const baseUrl = `${window.location.pathname}`,
            params = [];
        for (const key in filters) {
            let val = filters[key];
            if (((key === 'page' && val > 1) || key !== 'page')) {
                if (typeof val === 'string') val = val.toLowerCase();
                if (val) params.push(`${key}=${encodeURIComponent(val)}`);
            }
        }

        return `${baseUrl}${params.length ? '?' : ''}${params.join('&')}`;
    }

    getDocTypeCount() {
        let q = this.state.q;
        if (this.state.q === '') q = '*';

        let params = {
            q: q
        }

        getDocCount(params)
            .then(data => {
                this.setState
                    ({
                        docCount: data
                    });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoading: false
                });
            });
    }


    //load search related documents if no direct match found
    loadDocuments(category, pushState = true) {
        console.log(category);
        let q = this.state.q;

        if (!this.state.q) q = '*';

        if (!category) category = '';

        this.setState({
            isLoading: true
        });
        if (pushState) history.pushState(null, document.title, this.getUrl());
        const params = {
            q: q,
            page: this.state.page,
            pageSize: this.state.pageSize,
            type: category
        };

        this.getDocTypeCount();

        getDocuments(params)
            .then(data => {
                this.setState({
                    documentItems: data.records,
                    totalRecords: data.totalRecords,
                    isLoading: false,
                    showDocs: true,
                    firstLoad: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {
        let documents,
            loader,
            productDocs,
            showPagination,
            showFilters,
            count;

        if (this.state.isLoading) {
            loader = <LoaderSection show={true} />;
        }

        const pagination = <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.pages()}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={this.state.page - 1}
        />;

        if (this.showPagination()) {
            showPagination = pagination;
        }

        if (this.state.showFilters) {
            showFilters =
                <Filters q={this.state.q} showSearch={false}>
                    <div className="filter-accordion-content">
                        {Object.entries(this.state.docTypes).map(([key, value], index) => {
                            const count = Object.entries(this.state.docCount).filter(item => item[0] === key);
                            const finalCount = count[0];
                            return <div key={key}>
                                <input type="checkbox" className="custom-checkbox square document-checkbox" id={index} ref={this.filterBox} name={key} value={value} autocomplete="off" onChange={this.onCheckBoxChange} />
                                <label htmlFor={index}>
                                    <span>{value}</span>
                                    {finalCount && finalCount.length &&
                                        <span className="dull">({finalCount[1]})</span>
                                    }
                                </label>
                            </div>
                        })}
                    </div>
                </Filters>;
        } else {
            showFilters = <div></div>;
        }

        count = this.state.isLoading
            ? <></>
            : <p className="search-result-message"><span className="search-accent">{this.state.totalRecords}</span> results found for <span className="search-accent">'{this.state.q}'</span></p>;


        const selected =
            <div className="row">
                {Object.entries(this.state.categoryList).map(([key, value]) => (
                    <div key={key}>
                        {value.length > 0 &&
                            <div>
                                <div className="filter-bullets">
                                    <input className="filter-bullets-checkbox" id={`${value}`
                                    } type="checkbox" name={value} value={value
                                    } onChange={this.removeFiltersWithBullets.bind(this)} />
                                    <label htmlFor={`${value}`}>
                                        <span>{value}<span className="dull">&nbsp;&nbsp;X</span></span>
                                    </label>
                                </div>

                            </div>
                        }
                    </div>
                ))}
            </div>;

        //product documents not related to a specific product match
        if (this.state.documentItems.length >= 0 && this.state.showDocs && !this.state.directMatch) {
            documents =
                <div className="documents-search-section">
                    {count}
                    <div className="row">
                        <div className="col-lg-3">
                            {showFilters}
                        </div>
                        <div className="col-lg-9">
                            {selected}
                            {loader}
                            {this.state.documentItems.map(
                                (document, index) => (
                                    <DocumentCard key={index} colClass="media-card-container" {...document} />
                                ))}
                            {showPagination}
                        </div >
                    </div>
                </div>;
        }

        return (
            <div>

                {productDocs}
                {documents}
            </div>
        );
    }
}

export default SearchDocuments;