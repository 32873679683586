import React, { useState, useEffect } from 'react';
import GlassInfoModel from './GlassInfoModel.jsx';
import GlassInfoSize from './GlassInfoSize.jsx';

export default function GlassInfo({ type, setInfoSelected, searchBySize, setSearchBySize, modelInfo, setModelInfo, sizeInfo, setSizeInfo, disabled }) {

	const handleChange = (searchBySize) => {
		setSearchBySize(searchBySize);
	}

	return (
		<div className={`step-container step-container--extraWide ${disabled ? "disabled" : ""}`}>
			<h3 className="step-header step-header--tall">Step 2: Glassware Information</h3>
			<div className="step-content no-pad-bottom">
				<div className="step-options step-options--moveUp">
					<div className="step-option">
						<i className={`fas fa-ruler-combined ${searchBySize ? "selected" : ""}`} onClick={() => handleChange(true)}></i>
						Search by Size
					</div>
					<div className="step-option">
						<i className={`fas fa-ad ${!searchBySize ? "selected" : ""}`} onClick={() => handleChange(false)}></i>
						Search by Model
					</div>
				</div>
			</div>
			{!searchBySize &&
				<GlassInfoModel type={type} searchBySize={searchBySize} setSearchBySize={setSearchBySize} setInfoSelected={setInfoSelected} modelInfo={modelInfo} setModelInfo={setModelInfo} />
			}
			{searchBySize &&
				<GlassInfoSize type={type} searchBySize={searchBySize} setSearchBySize={setSearchBySize} setInfoSelected={setInfoSelected} sizeInfo={sizeInfo} setSizeInfo={setSizeInfo} />
			}

		</div>
	)
}
