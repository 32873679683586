import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProject } from '../../../apis/project-builder';
import { useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import Carousel from '../../../../shared/react/components/carousel.jsx';
import Modal from 'react-modal';
import Select from 'react-select';
//import toast from '../../../../shared/components/toast';

export default function MoveToGroupModal({ isMoveToGroupOpen, closeMoveToGroupModal, selectedProductIds, setSelectedProductIds, closeSelectedActions, emptyGroups, setEmptyGroups }) {

    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));
    const { mutate: updateProduct, isLoading: editIsLoading } = useUpdateProduct(currentProjectId);

    const options = [{value: null, label: "Select A Group"}];

    data.ProductGroups.forEach((group) => {
        options.push({
            value: group.id,
            label: group.name
        });
    });

    emptyGroups.forEach((group) => {
        options.push({
            value: group.id,
            label: group.name
        });
    });

    let selectedProductData = [];

    if (isSuccess) {
        selectedProductIds.forEach((itemId) => {
            selectedProductData.push(data.products.find(product => product.id == itemId));
        });
    }

    const [moveToGroupId, setMoveToGroupId] = useState(null);


    const handleSelectGroup = (value) => {
        setMoveToGroupId(value.value);
    };

    const handleMoveProducts = () => {
        if (selectedProductData.length) {
            selectedProductData.forEach((product) => {
                let payload = {
                    id: product.id,
                    productGroupId: moveToGroupId
                }

                updateProduct(payload, {
                    onSuccess: () => {
                        //remove selected productGroup from emptyGroups array
                        setEmptyGroups(emptyGroups.filter((group) => { return group.id !== moveToGroupId }));

                        closeMoveToGroupModal();
                        closeSelectedActions();
                        setSelectedProductIds([]);
                    }
                });
            });
        }
    }

    return (
        <>
            {isSuccess
                ? <Modal
                    isOpen={isMoveToGroupOpen}
                    onRequestClose={closeMoveToGroupModal}
                    className="project-builder project-builder-modal project-builder-modal--xs project-builder-modal--paddingSmall project-builder-modal--move"
                >
                    <button className="project-builder-modal-close" onClick={closeMoveToGroupModal}>
                        <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                    </button>
                    <div className='modal-title text-left'>Move selected products to group</div>

                    <Carousel>
                        {selectedProductData?.map((product) => (
                            <div className="carousel-cell"><img src={product?.imageUrl} alt={product?.title} /></div>
                        ))}
                    </Carousel>

                    <label>Move to Group</label>
                    <Select
                        className="mb-2"
                        classNamePrefix="styled-select"
                        options={options}
                        defaultValue={options[0]}
                        onChange={handleSelectGroup}
                    />
                    <div className="text-center">
                        <button type="button" className="btn no-arrow mb-1" disabled={isLoading || !moveToGroupId} onClick={handleMoveProducts}>yes, move items</button>
                        <div>
                            <button className="link-btn" disabled={isLoading} onClick={closeMoveToGroupModal}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                : null
            }
        </>
    );
}