import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useQuery } from '@tanstack/react-query';
import { getRecommendedProductAttachments, getProject } from '../../../apis/project-builder';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/base/Popper';

import AttachmentsListItem from './attachments-list-item.jsx';

export default function AttachmentsModal({ attachments, productNodeId, readOnly }) {

    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();

    const { data: recommendedAttachmentsData, isSuccess, isLoading, isError, error } = useQuery(['product', productNodeId, 'recommendedProductAttachments'], () => getRecommendedProductAttachments(productNodeId));

    const { data: projectData, isSuccess: projectDataIsSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'attachments-modal' : undefined;

    return (
        <>
            {isSuccess &&
            <>
                <button className="link-btn underline popper-trigger" aria-describedby={id} variant="contained" open={anchorEl ? true : false} onClick={handleClick}>
                    Attachments {attachments?.length ? "(" + attachments.length + ")" : null}
                </button>

                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        placement="top"
                    >
                        <div className="project-builder">
                            <div className="popper popper--attachments rounded-border">
                                <button className="project-builder-modal-close" onClick={handleClose}>
                                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                                </button>
                                <div className="popper-title">{readOnly ? "Added" : "Recommended"} Attachments</div>
                                <ul className="attachments-list attachments-list--paddingNone">
                                    {readOnly ?
                                        attachments.map((attachment, index) => (
                                            <AttachmentsListItem attachment={attachment} key={index} readOnly={readOnly} />
                                        ))
                                        : recommendedAttachmentsData.map((attachment, index) => (
                                            <AttachmentsListItem attachment={attachment} key={index} readOnly={readOnly} />
                                        ))
                                    }

                                    {/*{data.length ?*/}
                                    {/*    data.map((attachment, index) => (*/}
                                    {/*        <AttachmentsListItem attachment={attachment} key={index} readOnly={readOnly} />*/}
                                    {/*    ))*/}
                                    {/*    : <li>No {readOnly ? "added" : "recommended"} attachments for this product</li>*/}
                                    {/*}*/}
                                </ul>
                            </div>
                        </div>
                    </Popper>
                </ClickAwayListener>
            </>
            }
            {isError && "error getting attachments" }
        </>
    );
}