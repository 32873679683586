import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useEditProject } from "../../../components/project-builder-custom-hooks";
import { getProject } from '../../../apis/project-builder';
import EditProjectModal from './edit-project-modal.jsx';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import SendQuoteConfirmationModal from './send-quote-confirmation-modal.jsx';
import DownloadsButtons from './downloads-buttons.jsx';
//import SidebarDiscount from './sidebar-discount.jsx';
import ProjectDiscountModal from './project-discount-modal.jsx';


export default function Sidebar({ review, received }) {

    const navigate = useNavigate();

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: editProject, isLoading: editIsLoading } = useEditProject(currentProjectId);

    //const [projectDiscount, setProjectDiscount] = useState(data.DiscountValue);

    const [salesTax, setSalesTax] = useState(data.salesTax);
    const [freight, setFreight] = useState(data.freight);

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [isCollapsed, setIsCollapsed] = useState(isMobile);

    const handleToggleCollapse = () => {
        setIsCollapsed(prev => !prev);
    }

    const handleReviewQuote = () => {
        navigate(`/project-builder/${currentProjectId}/review-quote`);
    }

    const handleEditClick = () => {
        navigate(`/project-builder/${currentProjectId}`);
    }

    const handleTaxFreightChange = () => {
        const name = event.target.name;
        const value = event.target.value;
        name === "SalesTax" ? setSalesTax(value) : setFreight(value);

        const payload = {
            id: currentProjectId,
            [name]: value,
        }

        editProject(payload);
    }

    let totalAccessoryProductCount = 0;

    data.Products?.map((product) => {
        totalAccessoryProductCount += product.accessories.length
    });

    let totalProductCount = data.products?.length + totalAccessoryProductCount;

    //sticky sidebar
    //didn't use position: sticky because parent #outer-container has overflow property which breaks position: sticky
    const [sidebarWidth, setSidebarWidth] = useState(undefined);
    const [sidebarTop, setSidebarTop] = useState(undefined);
    //const [containerBottom, setContainerBottom] = useState(undefined);

    const container = document.querySelector('.sidebar-wrapper');

    useEffect(() => {
        const sidebarEl = document.querySelector('.sidebar').getBoundingClientRect();
        //setContainerBottom(container.offsetHeight);
        setSidebarWidth(sidebarEl.width);
        setSidebarTop(sidebarEl.top);
    }, []);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [sidebarTop]);

    const isSticky = (e) => {
        const sidebarEl = document.querySelector('.sidebar');
        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarTop - 50) {
            sidebarEl.classList.remove('is-bottom');
            sidebarEl.classList.add('is-sticky');

            if (scrollTop >= container.offsetHeight - (review || received ? 500 : 0)) {
                sidebarEl.classList.remove('is-sticky');
                sidebarEl.classList.add('is-bottom');
            }
        } else {
            sidebarEl.classList.remove('is-sticky');
            sidebarEl.classList.remove('is-bottom');
        }
    };

    return (
        <div className="col-lg-4 sidebar-wrapper">
            {isLoading
                ? <LoaderSection show={true} />
                : <div className={`sidebar rounded-border ${isCollapsed ? 'collapsed' : ''}`} style={{ width: sidebarWidth }}>
                    <div className="sidebar-container">
                        <div className="collapse-toggle" onClick={handleToggleCollapse}></div>
                        {(!review && !received) &&
                            <>
                                <button className="btn btn-block no-arrow" type="button" onClick={handleReviewQuote}>{data.isMarketingProject ? "Review Project" : "Review Quote"}</button>
                                <hr className="extra-margin" />
                                <div className="quote-details">
                                    <div className="section-title section-title--small section-title--flex">
                                        {data.name}
                                        <EditProjectModal
                                            openButtonText=""
                                            buttonClass="link-btn mr-0 ml-auto" />
                                    </div>
                                    <ul>
                                        <li>Sending To: <span>{data.accountName}</span></li>
                                        <li>Products: <span>({totalProductCount})</span></li>
                                        <li>Attachments: <span>({data.attachements?.length ? data.attachements.length : 0})</span></li>
                                        <li>Status: <span>{data.status}</span></li>
                                        <li>Format: <span>{data.isMarketingProject ? "Sales Project" : "Quote Project"}</span></li>
                                        <li>Project ID: <span>{data.id}</span></li>
                                    </ul>
                                </div>
                                {data.isMarketingProject
                                    ? null
                                    : <>
                                        <hr />
                                        <div className="quote-totals">
                                            <ul>
                                                <li>Subtotal: <span>${data.totalProductPrice.toFixed(2)}</span></li>
                                                <li>
                                                    <ProjectDiscountModal discountType={data.discountType} discountValue={data.discountValue} />
                                                    <span>{data.discountType == "None" ? null : "-$" + data.projectDiscount.toFixed(2)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="quote-total-price">
                                            <span className="quote-total-price-label">
                                                QUOTE PRICE:
                                                <Tooltip title="Price does not include tax or freight." placement="top">
                                                    <HelpOutlineOutlinedIcon />
                                                </Tooltip>
                                            </span>
                                            <span>${(data.totalProductPrice.toFixed(2) - data.projectDiscount).toFixed(2)}</span>
                                        </div>
                                    </>
                                }
                            </>
                        }



                        {review &&
                            <>
                                <div className="buttons-container">
                                    <button className="btn btn-outline-alt mr-auto" type="button" onClick={handleEditClick}>{data.isMarketingProject ? "Back to Project" : "Back to Quote"}</button>
                                    <SendQuoteConfirmationModal currentProjectId={currentProjectId} />
                                </div>
                                <DownloadsButtons guid={data.guid} projectName={data.name} projectId={data.id} isMarketingProject={data.isMarketingProject} />
                                <hr className="extra-margin" />
                                <div className="quote-details">
                                    <div className="section-title section-title--small">{data.isMarketingProject ? "" : "quote"} summary</div>
                                    <ul>
                                        <li>Project Type: <span>{data.type}</span></li>
                                        <li>Project ID: <span>{data.id}</span></li>
                                        <li>Created By: <span>{data.createdBy}</span></li>
                                        <li>Created By Email: <span>{data.createdByEmail}</span></li>
                                        <li>Status: <span>{data.status}</span></li>
                                        <li>Format: <span>{data.isMarketingProject ? "Sales Project" : "Quote Project"}</span></li>
                                    </ul>
                                </div>
                                {data.isMarketingProject
                                    ? null
                                    : <>
                                        <hr />
                                        <div className="quote-totals">
                                            <ul>
                                                <li>Products: ({totalProductCount}) <span>${data.totalProductPrice.toFixed(2)}</span></li>
                                                <li>
                                                    <ProjectDiscountModal discountType={data.discountType} discountValue={data.discountValue} />
                                                    <span>{data.discountType == "None" ? null : "-$" + data.projectDiscount.toFixed(2)}</span>
                                                </li>
                                                <li>Attachments: ({data.attachements.length}) <span>$0.00</span></li>
                                                <li>
                                                    Sales Tax:
                                                    <span className="sales-tax-input-container">
                                                        <input
                                                            type="text"
                                                            name="SalesTax"
                                                            //pattern="\d*"
                                                            maxlength="8"
                                                            value={salesTax}
                                                            onBlur={handleTaxFreightChange} />
                                                    </span>
                                                </li>
                                                <li>
                                                    Freight:
                                                    <span className="freight-input-container">
                                                        <input
                                                            type="text"
                                                            name="Freight"
                                                            //pattern="\d*"
                                                            maxlength="8"
                                                            value={freight}
                                                            onBlur={handleTaxFreightChange} />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="quote-total-price">
                                            <span>QUOTE PRICE: </span>
                                            <span>${data.totalNetPrice.toFixed(2)}</span>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {received &&
                            <>
                                <div className="quote-details">
                                    <div className="section-title section-title--small">{data.isMarketingProject ? "" : "quote"} summary</div>
                                    <ul id="pdf-project-info">
                                        <li>Project Type: <span>{data.type}</span></li>
                                        <li>Project ID: <span>{data.id}</span></li>
                                        <li>Created By: <span>{data.createdBy}</span></li>
                                        <li>Created By Email: <span>{data.createdByEmail}</span></li>
                                        <li>Status: <span>{data.status}</span></li>
                                    </ul>
                                </div>
                                {data.isMarketingProject
                                    ? null
                                    : <>

                                        <hr />
                                        <div className="quote-totals">
                                            <ul>
                                                <li>Products: ({totalProductCount}) <span>${data.totalProductPrice.toFixed(2)}</span></li>
                                                {data.discountType == "None"
                                                    ? null
                                                    : <li>Project Discount (
                                                        {data.discountType == "FiftyTen" ? "50/10" : null}
                                                        {data.discountType == "FiftyTenOpen" ? "50/10/" + data.discountValue : null}
                                                        {data.discountType == "CustomPercentage" ? data.discountValue + "%" : null}
                                                        {data.discountType == "CustomDollarAmount" ? "$" + data.discountValue : null}
                                                        )<span>-${data.projectDiscount.toFixed(2)}</span>
                                                    </li>
                                                }

                                                <li id="pdf-attach">Attachments: ({data.attachements.length}) <span>$0.00</span></li>
                                                <li id="pdf-sales">Sales Tax: <span>${data.salesTax}</span></li>
                                                <li id="pdf-freight">Freight: <span>${data.freight}</span></li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="quote-total-price">
                                            <span>QUOTE PRICE: </span>
                                            <span>${data.totalNetPrice.toFixed(2)}</span>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    );
}