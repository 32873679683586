import React, { useState } from 'react';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
//import { useUpdateProduct } from '../../../components/project-builder-custom-hooks';
//import Modal from 'react-modal';
import ClickAwayListener from '@mui/base/ClickAwayListener';


export default function DiscountDropdown({ newDiscount, setNewDiscount, openAmount, setOpenAmount, percentageAmount, setPercentageAmount, dollarAmount, setDollarAmount, projectDiscount }) {

    const [showDropdown, setShowDropdown] = useState(true);

    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    }

    let dropdownText = "";

    switch (newDiscount.discountType) {
        case "FiftyTen":
            dropdownText = ("50/10 Discount");
            break;
        case "FiftyTenOpen":
            dropdownText = ("50/10/Open Discount");
            break;
        case "CustomPercentage":
            dropdownText = (newDiscount.discountValue + "% Discount");
            break;
        case "CustomDollarAmount":
            dropdownText = ("$" + newDiscount.discountValue + " Discount");
            break;
        default:
            dropdownText = ("Select Discount");
            break;
    }

    const handleRadioChange = (e) => {
        let value = e.target.value;
        let amount = 0;

        switch (value) {
            case "FiftyTen":
                amount = 0;
                break;
            case "FiftyTenOpen":
                amount = openAmount;
                break;
            case "CustomPercentage":
                amount = percentageAmount;
                break;
            case "CustomDollarAmount":
                amount = dollarAmount;
                break;
        }

        setNewDiscount(prevState => ({
            ...prevState,
            discountType: e.target.value,
            discountValue: amount
        }));
    }

    const handleAmountChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name == "openAmount") setOpenAmount(value);
        if (name == "percentageAmount") setPercentageAmount(value);
        if (name == "dollarAmount") setDollarAmount(value);

        //e.target.name == "percentageAmount" ? setPercentageAmount(e.target.value) : setDollarAmount(e.target.value);

        setNewDiscount(prevState => ({
            ...prevState,
            discountValue: e.target.value
        }));
    }

    const handleClickAway = () => {
        setShowDropdown(false);
    }

    return (
        <>
            <div className="dropdown dropdown--discount">
                {/*<div*/}
                {/*    className={`dropdown-trigger ${showDropdown ? 'active' : ''}`}*/}
                {/*    onClick={toggleDropdown}*/}
                {/*>*/}
                {/*    {dropdownText}*/}
                {/*</div>*/}

                {/*{showDropdown ? (*/}
                {/*    <ClickAwayListener onClickAway={handleClickAway}>*/}
                        {/*<div className="dropdown-menu">*/}
                        <div className="rounded-border rounded-border--paddingSmall">
                            <div className="dropdown-menu-item">
                                <input type="radio" id="None" name="discount" value="None" checked={newDiscount.discountType == "None"} onChange={handleRadioChange} />
                                <label for="None" className="">None</label>
                            </div>
                            <div className="dropdown-menu-item">
                                <input type="radio" id="FiftyTen" name="discount" value="FiftyTen" checked={newDiscount.discountType == "FiftyTen"} onChange={handleRadioChange} />
                                <label for="FiftyTen" className="">50/10 Discount</label>
                            </div>

                            {projectDiscount &&
                                <div className="dropdown-menu-item">
                                    <input type="radio" id="FiftyTenOpen" name="discount" value="FiftyTenOpen" checked={newDiscount.discountType == "FiftyTenOpen"} onChange={handleRadioChange} />
                                    <label for="FiftyTenOpen" className="">50/10/Open Discount</label>
                                    <div className={`discount-percentage-container percent-symbol ${newDiscount.discountType == "FiftyTenOpen" ? "active" : null}`}>
                                        <input className="form-control" type="text" id="OpenAmount" name="openAmount" maxLength="2" value={openAmount} onChange={handleAmountChange} />
                                        <label for="OpenAmount" className="sr-only">50/10/Open Amount</label>
                                    </div>
                                </div>
                            } 

                            <div className="dropdown-menu-item">
                                <input type="radio" id="CustomPercentage" name="discount" value="CustomPercentage" checked={newDiscount.discountType == "CustomPercentage"} onChange={handleRadioChange} />
                                <label for="CustomPercentage" className="">Custom Percentage Discount</label>
                                <div className={`discount-percentage-container percent-symbol ${newDiscount.discountType == "CustomPercentage" ? "active" : null}`}>
                                    <input className="form-control" type="text" id="PercentageAmount" name="percentageAmount" maxLength="2" value={percentageAmount} onChange={handleAmountChange} />
                                    <label for="PercentageAmount" className="sr-only">Percentage Amount</label>
                                </div>
                            </div>

                            <div className="dropdown-menu-item">
                                <input type="radio" id="CustomDollarAmount" name="discount" value="CustomDollarAmount" checked={newDiscount.discountType == "CustomDollarAmount"} onChange={handleRadioChange} />
                                <label for="CustomDollarAmount" className="">Custom Dollar Amount</label>
                                <div className={`discount-dollar-container dollar-symbol ${newDiscount.discountType == "CustomDollarAmount" ? "active" : null}`}>
                                    <input className="form-control" type="text" id="DollarAmount" name="dollarAmount" maxLength="5" value={dollarAmount} onChange={handleAmountChange} />
                                    <label for="DollarAmount" className="sr-only">Dollar Amount</label>
                                </div>
                            </div>
                        </div>
                {/*    </ClickAwayListener>*/}
                {/*) : null}*/}
            </div>
        </>
    );
}