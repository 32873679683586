import React, { Component } from 'react';
import {
    Route,
    //BrowserRouter as Router,
    Routes,
    Navigate
} from "react-router-dom";
import SearchProducts from '../../../../shared/react/components/search/search-products.jsx';
import SearchDocuments from '../../../../shared/react/components/search/search-documents.jsx';
import { getUrlParams } from '../../../../shared/utils/';
import SearchInput from '../../../../shared/react/components/search/search-input.jsx';
import SearchNav from './search-nav.jsx';
//import SearchBlog from '../../../../shared/react/components/search/search-blog.jsx';
import SearchVideos from '../../../../shared/react/components/search/search-videos.jsx';
import SearchTerms from '../../../../shared/react/components/search/search-terms.jsx';
//import SearchPodcasts from '../../../../shared/react/components/search/search-podcasts.jsx';
//import SearchPages from '../../../../shared/react/components/search/search-pages.jsx';
import { getSearchTerms } from '../../../../shared/apis/search';
import Breadcrumbs from './breadcrumbs.jsx';
import ProjectSelector from './project-selector.jsx';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useNavigate } from 'react-router-dom';

class SearchMainClass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            q: '',
            searchTerms: []
        }

        this.onSearchInput = this.onSearchInput.bind(this);
        this.searchInput = React.createRef();
    }

    componentDidMount() {
        const params = getUrlParams();
        this.getSearchTerms();

        this.setState({
            q: params.q
        });
    }

    getSearchTerms() {
        getSearchTerms()
            .then(data => {

                const parsedData = JSON.parse(data);
                this.setState({
                    searchTerms: parsedData
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onSearchInput(e) {
        e.preventDefault();
        this.setState({
            q: this.searchInput.current.value
        });
    }

    onClickSearchTerm(e) {
        const target = e.target;

        this.setState({
            q: target.value
        });
    }

    render() {
        return (
            <div className="project-builder-search project-builder container section equal-margin">
                <Breadcrumbs />
                <div className="row align-items-center mb-2">
                    <div className="col-lg-3">
                        <ProjectSelector />
                    </div>
                    <div className="col-lg-3">
                        {this.props.backBtn}
                    </div>
                </div>
                <div className="section-title">SEARCH</div>
                <SearchInput q={this.state.q} onSubmit={this.onSearchInput} searchInput={this.searchInput} />
                <SearchTerms searchTerms={this.state.searchTerms} clickTerm={this.onClickSearchTerm.bind(this)} />
                <SearchNav q={this.state.q} />
                <Routes>
                    <Route path="products" element={<SearchProducts q={this.state.q} colClass="col-md-6" quickAdd />} />
                    <Route path="documents" element={<SearchDocuments q={this.state.q} quickAdd={true} />} />
                    <Route path="videos" element={<SearchVideos q={this.state.q} quickAdd={true} />} />
                    <Route path="/" element={<Navigate to="products" />} />
                </Routes>
            </div>
        );
    }

}


SearchMain.defaultProps = {
    q: ''
};

//export default SearchMain;

export default function SearchMain() {

    const navigate = useNavigate();

    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();
    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const handleBack = () => {
        navigate(`/project-builder/${data.Id}`)
    }

    const backBtn = () => {
        return <button className="btn no-arrow" onClick={handleBack}>back to {data.Name}</button>
    }

    return <SearchMainClass backBtn={backBtn()} />
}

