//import "preact/debug";
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../react/components/lid-saver-configurator/LidSaverConfigurator.jsx';


export default class LidSaverConfigurator {
    constructor() {
        this.container = document.querySelector('#lid-saver-configurator');

        if (this.container) {
            ReactDOM.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode >,
                document.getElementById('lid-saver-configurator')
            );
        }

        
    }
}

window.LidSaverModule = function () {
    ReactDOM.render(<App />, document.getElementById('lid-saver-configurator'));
    return this;
}