import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import animatedScrollTo from 'animated-scroll-to';

const DashboardEvents = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        animatedScrollTo(document.querySelector('#code-top'));
    }, [])

    const navigateToEventCode = (item) => {
        navigate("/eventcodes", { state: { data: location.state.allCodes.filter(m => m.eventId === item.eventId) } }
        )
    }

    return (
        <>

            <div id="code-top" className="container">
                <div className="row section">
                    <div className="col-md-12 add-margin-btm-extra">
                        <NavLink to={`/my-vollrath-university`}>
                            Return
                        </NavLink>
                    </div>
                    <div className="col-md-12">

                        {location.state.data && location.state.data.length ?
                            <div>
                                <div className="section-header"><h3 className="section-title"><span>VU Event Codes</span></h3><div className="spacer"></div></div>
                                <ul className="row">
                                    {location.state.data.map((item, index) => {
                                        const startDate = new Date(Date.parse(item.eventDetails.startDate)).toDateString();
                                        const endDate = new Date(Date.parse(item.eventDetails.endDate)).toDateString();
                                        return <li key={index} className="col-md-3">
                                            <div className="vu-dashboard-quiz events">
                                                <div>
                                                    <img src={item.eventDetails.mainImage} alt="quiz badge" />
                                                </div>
                                                <div>
                                                    <p className="card-title">{`${item.eventDetails.title}`}  </p>
                                                    <p className="vu-desc">{`${startDate} - ${endDate}`}  </p>
                                                    <a className="text-link quiz-btn" onClick={() => navigateToEventCode(item)} >See Codes For This Event</a>
                                                </div>
                                            </div>
                                        </li>
                                    })}
                                </ul>

                            </div> :
                            <>
                            </>
                        }
                    </div>
                </div>
            </div>

        </>
    );
}

export default DashboardEvents;