import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import LoaderSection from './loader-section.jsx';
import { getUrlParams, generatePaginationHref } from '../../utils/';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import Filters from './filters.jsx';
import toast from '../../components/toast';
import FilteringBase from './filtering-base';
import CustomContentItem from "./custom-content-item.jsx";

class FilterableGrid extends FilteringBase {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.baseState, {
            filters: {},
            pageSize: this.props.cardsPerPage,
            firstSearch: true
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleNewPageClick = this.handleNewPageClick.bind(this);
        this.loadDebounce = debounce(this.load.bind(this), 1000);
    }

    handleNewPageClick(data) {
        this.setState({
            page: data.selected + 1
        }, () => {
            this.load();
        });
    }

    setFilters() {
        const categories = [...this.props.categories];
        categories.forEach(c => c.selected = false);
        return new Promise((resolve) => {
            this.setState({
                filters: {
                    Categories: categories,
                }
            }, () => resolve());
        });
    }

    componentDidMount() {
  
        this.setFilters().then(() => {
            this.updateFilters();
            let params = getUrlParams();
            let pageNum;
            if (Object.keys(params).length) {
                pageNum = params.page && params.page !== '1' ? params.page : 1;
                this.setState({ page: pageNum }, () => {
                    this.load();
                });
            } else {
                this.load();
            }

            window.addEventListener('popstate', e => {
                this.updateFilters();
                let params = getUrlParams();
                let pageNum;
                if (Object.keys(params).length) {
                    pageNum = params.page && params.page !== '1' ? params.page : 1;
                    this.setState({ page: pageNum }, () => {
                        this.load(false);
                    });
                } else {
                    this.setState({ page: 1 }, () => { this.load(false) });
                }
            });
        });
    }

    // load products from the api
    load(pushState = true) {
        if (this.ajaxPromise) this.ajaxPromise.controller.abort();

        this.setState({
            isLoading: true,
            items: []
        });

        if (pushState && !this.state.firstSearch) {
            history.pushState(null, document.title, this.getUrl());
        }

        // put together api parameters
        const params = this.getAllFilters();

        params.pageSize = this.state.pageSize;
        if (this.props.noPagination) {
            params.pageSize = this.props.children
            this.setState({
                pageSize: this.props.children
            })
        }

        // remove empty or undefined params
        Object.keys(params).forEach(key => {
            if (!params[key]) delete params[key];
        });

        this.setState({
            firstSearch: false
        });

        this.ajaxPromise = this.props.api(params);

        this.ajaxPromise.then(data => {
            console.log(data);
            this.setState({
                items: data.records,
                totalRecords: data.totalRecords,
                isLoading: false
            });
            return data;
        })
            .catch(error => {
                console.log(error);
                if (error.name === 'AbortError') return error;
                this.setState({
                    isLoading: false
                });
                toast.error('There was an error getting results');
                return error;
            });

        return this.ajaxPromise;
    }


    // set the selected state of the filters to false
    resetFilterData(data) {
        for (const [key, value] of Object.entries(data)) {
            value.forEach(v => v.selected = false);
        }
    }

    // update filters on page using the url parameters
    updateFilters() {
        const params = getUrlParams(),
            filters = Object.assign({}, this.state.filters);

        // reset filters to be unselected
        this.resetFilterData(filters);
        // go through params and update selected filters
        for (const paramKey in params) {
            const values = params[paramKey].split('|');
            const filterSection = Object.entries(filters).find(([key]) => kebabCase(key) === paramKey);
            console.log(filterSection, values);

            if (filterSection) {
                values.forEach(value => {
                    const filter = filterSection[1].find(f => f.id === +value);
                    if (filter) filter.selected = true;
                });
            }
        }

        // update search query
        if (params.q) this.setState({ q: params.q });

        this.setState({
            filters: filters,
            page: params['page'] ? +params['page'] : 1
        });
    }

    // get all the selected filters at this time
    getAllFilters() {
        const params = {
            q: this.state.q,
            page: this.state.page,
            nodeId: this.props.nodeId
        };

        for (const [key, value] of Object.entries(this.state.filters)) {
            value.forEach(v => {
                if (v.selected) {
                    const paramKey = kebabCase(key),
                        encodedValue = v.id;
                    if (params[paramKey]) params[paramKey].push(encodedValue);
                    else params[paramKey] = [encodedValue];
                }
            });
        }

        // pipe delimit array values
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) params[key] = params[key].join(',');
        });

        return params;
    }

    // get the url for the current window location
    getUrl() {
        const baseUrl = `${window.location.pathname}`,
            filters = this.getAllFilters(),
            params = [];
        for (const key in filters) {
            const val = filters[key];
            if (((key === 'page' && val > 1) || key !== 'page')) {
                if (val) params.push(`${key}=${encodeURIComponent(val)}`);
            }
        }

        return `${baseUrl}${params.length ? '?' : ''}${params.join('&')}`;
    }

    handleChange(e) {
        const target = e.target,
            isChecked = target.checked,
            name = target.name,
            value = +target.value,
            filters = Object.assign({}, this.state.filters);

        Object.keys(filters).forEach(key => {
            const match = filters[key].find(f => f.id === value && key === name);
            if (match) match.selected = isChecked;
        });

        this.setState({
            filters: filters,
            page: 1
        }, () => {
            this.load();
        });
    }

    render() {
        let pagination,
            loader,
            results,
            filter,
            colClassFilter,
            colClass,
            filterRow;

        if (this.showPagination()) {
            pagination = <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.pages()}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handleNewPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={this.state.page - 1}
                hrefBuilder={generatePaginationHref}
            />;
        }

        filterRow = this.props.showFilter ? 'row justify-content-staging' : '';

        filter = this.props.showFilter ?

            <div className="col-lg-3">
                <Filters q={this.state.q} showSearch={false} >
                    {Object.entries(this.state.filters).map(([key, value]) => (
                        <div key={key}>
                            {value.length > 0 &&
                                <details className="filter-accordion" open role="group" aria-labelledby={'filter-' + kebabCase(key)}>
                                    <summary className="filter-accordion-header" id={'filter-' + kebabCase(key)}><span>{key}</span></summary>
                                    <div className="filter-accordion-content">
                                        {value.map((filter, index) => (
                                            <div key={index}>
                                                <input className="custom-checkbox square" id={`${filter.name}${this.props.nodeId}`} type="checkbox" name={key} value={filter.id} onChange={this.handleChange} checked={filter.selected} />
                                                <label htmlFor={`${filter.name}${this.props.nodeId}`}>
                                                    <span>{filter.name}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </details>
                            }
                        </div>
                    ))}
                </Filters>
            </div> : <div></div>

        colClassFilter = this.props.showFilter ? 'col-lg-9' : '';
        colClass = 'col-lg-4 col-sm-6'
        if (!this.props.showFilter && !this.props.bigCards) {
            colClass = 'col-lg-3 col-sm-6';
        } 
        if (!this.props.showFilter && this.props.bigCards) {
            colClass = 'col-lg-6 col-sm-6';
        }

        if (this.state.items.length > 0) {
            results = <div className="row">
                {this.state.items.map((item, index) => (
                    <this.props.item key={index} {...item} colClass={colClass} cardType={this.props.cardType} />
                ))}
            </div>;
        }
        else if (!this.state.isLoading) {
            results = <p>No results found</p>;
        }

        if (this.state.isLoading) loader = <LoaderSection show={true} />;

        return (
            <div className={filterRow}>
                {filter}
                <div id="results" className={colClassFilter}>
                    {loader}
                    {results}
                    {pagination}
                </div>
            </div>
        );
    }
}

FilterableGrid.defaultProps = {
    categories: [],
    item: CustomContentItem
};

export default FilterableGrid;