import React, { useState } from 'react';
import Modal from 'react-modal';

import SearchMenuContainer from './search-menu.jsx';


export default function SearchModal({isSearchOpen, closeSearchModal}) {

    //Modal.setAppElement('#main');

    return (
        <>
            <Modal
                isOpen={isSearchOpen}
                onRequestClose={closeSearchModal}
                className="project-builder-modal project-builder-modal--large"
            >
                <button className="project-builder-modal-close" onClick={closeSearchModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container project-builder">
                    <div className='modal-title mb-1'>Search</div>
                    <div className="modal-subtitle mb-2">Search for product SKUs or keywords to find what you're looking for.</div>
                    <SearchMenuContainer />
                </div>
            </Modal>
        </>
    );
}