import React from 'react'
import ProductGroups from './ProductGroup.jsx'

export default function Step2({ isTokenError, authToken, dispenserType, onChange }) {

	return (
		<div className="lidSaverContainer lidSaverContainerStep2">
			<div className="stepHeader">
				<h2>{(isTokenError) ? "There was an error verifying your token. Please contact Vollrath for assistance." : "Select Dispenser Type"}</h2>
			</div>

			<div className="stepContainer dispenserTypeContainer step2">
				<ProductGroups
					authToken={authToken}
					dispenserType={dispenserType}
					onChange={onChange}
				/>
			</div>
		</div>
	)
}
