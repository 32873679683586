import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useEditProject } from "../../../components/project-builder-custom-hooks";
import Select from 'react-select';



export default function ProductsListHeader({ expandAll, setExpandAll, reviewList, handleAddProduct, handleSelectAllProducts}) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: editProject, isLoading: editIsLoading } = useEditProject(currentProjectId);

    const sortOptions = [
        { value: "Alphabetical", label: "A-Z" },
        { value: "DateAdded", label: "Newest Added" },
        { value: "ProductFamilyGrouping", label: "Product Family" },
        { value: "CustomGrouping", label: "Custom Grouping" },
        { value: "Sequence", label: "Sort Value"}
    ];

    const handleSortChange = (value) => {
        let payload = {
            id: data.id,
            sortType: value.value
        }
        editProject(payload , {
            //onSuccess: () => {

            //}
        });
    }

    const handleExpandAllClick = () => {
        setExpandAll(prevState => !prevState);
    }

    return (
        <>
            {isSuccess &&
                <div className="added-products-header">
                    {!reviewList ?
                        <>
                            <button className="btn no-arrow" type="button" onClick={handleAddProduct}>Add Product</button>
                            <button className="btn btn-outline-alt ml-1" type="button" onClick={handleSelectAllProducts}>Select All Products</button>
                        </>
                        : null
                    }

                    <div className=" display-flex ml-auto">
                        {(data.sortType == "ProductFamilyGrouping" || data.sortType == "CustomGrouping") &&
                            <div className="expand-all" onClick={handleExpandAllClick}>
                                {expandAll ? "Collapse All" : "Expand All"}
                                <span><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
                                <span><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
                            </div>
                        }

                        <label className="sr-only">Sort By</label>
                        <Select
                            classNamePrefix="styled-select"
                            options={sortOptions}
                            defaultValue={sortOptions.find(({ value }) => value === data.sortType)}
                            isSearchable={false}
                            onChange={handleSortChange}
                        />
                    </div>
                </div>
            }
        </>
    );
}