import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from "react-router-dom";
import CustomContentItem from '../../../../shared/react/components/custom-content-item.jsx';
import animatedScrollTo from 'animated-scroll-to';

const DashboardModule = (props) => {

    const location = useLocation();

    useEffect(() => {
        animatedScrollTo(document.querySelector('#module-top'));
    }, [])

    return (
        <>
            {!location.state.notify ?
                <div id="module-top" className="container">
                    <div className="row section">
                        <div className="col-md-12 add-margin-btm-extra">
                            <NavLink to={`/`}>
                                Return
                            </NavLink>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title section-header profile">{location.state.module.displayName} </div>
                            <div className="row">
                                {location.state.module.pages.map((page, index) => (
                                    <CustomContentItem key={index} {...page} colClass="col-md-3" cardType={false} />
                                ))}
                            </div>
                        </div>

                    </div>
                </div> :
                <div id="module-top" className="container">
                    <div className="row section">
                        <div className="col-md-12 add-margin-btm-extra">
                            <NavLink to={`/`}>
                                Return
                            </NavLink>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title section-header profile">Your New Portal Content </div>
                            <div className="row">
                                {location.state.pages.map((page, index) => (
                                    <CustomContentItem key={index} {...page} colClass="col-md-3" cardType={false} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
         

        </>
    );
}

export default DashboardModule;