import React, { useState } from 'react';
import { getProject } from '../../../apis/project-builder';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useAddAttachment, useDeleteAttachment } from '../../../components/project-builder-custom-hooks';
import Loader from '../../../../shared/react/components/loader.jsx';
import toast from '../../../../shared/components/toast';


export default function AddRemoveDocumentsButton({ itemSlug, videoNodeId, deleteOnly, isAdded, setIsAdded }) {
    //use videoNodeId for adding videos
    //use itemSlug for adding documents

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: addAttachment, isLoading: addIsLoading } = useAddAttachment(currentProjectId);
    const { mutate: removeAttachment, isLoading: deleteIsLoading } = useDeleteAttachment(currentProjectId);

    let payload = {};

    const handleAdd = () => {
        if (itemSlug) {
            payload = {
                documentLocalSlug: itemSlug
            }
        }
        else {
            payload = {
                videoNodeId: videoNodeId
            }
        }

        addAttachment(payload, {
            onSuccess: () => {
                if(setIsAdded) setIsAdded(true)
            }
        })
    }

    const handleDelete = () => {
        let addedAttachment = null;

        if (itemSlug) {
            addedAttachment = data.attachements.find(attachment => attachment.documentLocalSlug === itemSlug);
        }
        else {
            addedAttachment = data.attachements.find(attachment => attachment.videoNodeId === videoNodeId);
        }

        removeAttachment(addedAttachment.id, {
            onSuccess: () => {
                setIsAdded(false);
                toast.success("Attachment removed");
            }
        });
    }

    return (
        <>
            {(isLoading || addIsLoading || deleteIsLoading)
                ? <Loader className="ml-auto" show={true} />
                : isAdded
                    ? deleteOnly ? null : < img className="icon-deleteItem ml-auto" src="/img/icon-delete-item.png" alt="delete item" onClick={handleDelete} />
                    : deleteOnly ? null : <img className="icon-addItem ml-auto" src="/img/icon-add-item.png" alt="add item" onClick={handleAdd} />
            }
            {deleteOnly && !deleteIsLoading
                ? <button className="btn--plain ml-auto" type="button" onClick={handleDelete}><span className="icon icon--trash"></span></button>
                : null
            }
        </>
    );
}