import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useEditProject } from '../../../components/project-builder-custom-hooks';
import Modal from 'react-modal';
import DiscountDropdown from './discount-dropdown.jsx';


export default function ProjectDiscountModal({ discountType, discountValue }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { mutate: updateProject, isLoading } = useEditProject(currentProjectId);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const [newDiscount, setNewDiscount] = useState({
        discountType: discountType,
        discountValue: discountValue
    });

    const [openAmount, setOpenAmount] = useState(discountValue);
    const [percentageAmount, setPercentageAmount] = useState(discountValue);
    const [dollarAmount, setDollarAmount] = useState(discountValue);

    let buttonText = "";

    switch (discountType) {
        case "FiftyTen":
            buttonText = ("50/10 Discount");
            break;
        case "FiftyTenOpen":
            buttonText = ("50/10/" + discountValue + " Discount");
            break;
        case "CustomPercentage":
            buttonText = (discountValue + "% Discount");
            break;
        case "CustomDollarAmount":
            buttonText = ("$" + discountValue + " Discount");
            break;
        default:
            buttonText = ("Add Project Discount");
            break;
    }

    const handleApplyDiscount = () => {
        let discountPayload = {};
        
        discountPayload = {
            id: currentProjectId,
            discountType: newDiscount.discountType,
            discountValue: newDiscount.discountValue
        }

        updateProject(discountPayload, {
            onSuccess: () => {
                closeModal();
            }
        });
    }

    const handleRemoveDiscount = () => {
        let discountPayload = {};

        discountPayload = {
            id: currentProjectId,
            discountType: "None",
            discountValue: 0
        }

        updateProject(discountPayload, {
            onSuccess: () => {
                closeModal();
            }
        });
    }

    useEffect(() => {
        setNewDiscount({
            id: currentProjectId,
            discountType: discountType,
            discountValue: discountValue
        })
    }, [discountType])

    return (
        <>
            <button class="link-btn underline add-discount" type="button" onClick={openModal}>{buttonText}</button>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="project-builder project-builder-modal project-builder-modal--xs project-builder-modal--discount"
            >
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container">
                    <div className='modal-title'>Add Project Discount</div>
                    <p>Select discount to apply to all selected products.</p>
                    <DiscountDropdown
                        newDiscount={newDiscount}
                        setNewDiscount={setNewDiscount}
                        openAmount={openAmount}
                        setOpenAmount={setOpenAmount}
                        percentageAmount={percentageAmount}
                        setPercentageAmount={setPercentageAmount}
                        dollarAmount={dollarAmount}
                        setDollarAmount={setDollarAmount}
                        projectDiscount
                    />
                    <div className="form-actions">
                        <button class="btn btn-outline-alt" type="button" onClick={handleRemoveDiscount}>Remove Discount</button>
                        <button
                            className="btn"
                            type="button"
                            disabled={isLoading}
                            onClick={handleApplyDiscount}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}