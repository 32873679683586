import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import CustomContentItem from '../../../../shared/react/components/custom-content-item.jsx';
import FilterableGrid from '../../../../shared/react/components/filterable-grid.jsx';
import Cookies from 'js-cookie';

const DashboardModules = (props) => {
    const [modules, setModules] = useState([]);
    const [order, setOrder] = useState({
        order1: "",
        order2: "",
        order3: "",
        order4: "",
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props.contact && props.contact.Email) {
            const params = { email: props.contact.Email };

            Promise.all([
                props.getQuizzesForContact(params),
                props.getEventsForContact(params)
            ]).then(() => {
                setModules(props.modules);
                if (props.modules && props.modules.length) {
                    setOrder({
                        order1: props.modules[0].NodeId,
                        order2: props.modules[1].NodeId,
                        order3: props.modules[2].NodeId,
                        order4: props.modules[3].NodeId,
                    });
                }
                setIsLoading(false);
            }).catch(error => {
                console.error("Failed to fetch data:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [props.contact, props.modules]);

    const gravatarImg = "/img/generic-avatar.png";
    const navigate = useNavigate();

    const saveOrder = () => {
        console.log(order);
        props.updateOrder(order);
    };

    const handleChange = (e) => {
        setOrder({
            ...order,
            [e.target.name]: e.target.value
        });
    };

    const navigateToModule = (item) => {
        navigate("/module", { state: { module: item, notify: false } });
    };

    const navigateToNotifications = (item) => {
        Cookies.set("Notified", true);
        navigate("/module", { state: { pages: item, notify: true } });
    };

    const getFilterableGridHeading = (heading, headingType) => {
        switch (headingType) {
            case "default":
                return (<h2 className="heading">{heading}</h2>);
            case "section":
                return (
                    <div className="section-header">
                        <h2 className="section-title"><span>{heading}</span></h2>
                    </div>
                );
            case "centered":
                return (
                    <div className="row justify-content-center">
                        <h2 className="heading">{heading}</h2>
                    </div>
                );
            case "right":
                return (
                    <div className="row justify-content-end">
                        <h2 className="heading">{heading}</h2>
                    </div>
                );
            default:
                return null;
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const firstName = props.contact ? (props.contact.FirstName || props.contact.firstName) : "Name";
    const lastName = props.contact ? (props.contact.LastName || props.contact.lastName) : "Last Name";
    const title = props.contact ? (props.contact.Title || props.contact.title) : "Title";
    const company = props.contact ? (props.contact.Company || props.contact.company) : "Company";

    if (!props.contact) {
        return <div>No contact information available.</div>;
    }

    return (
        <>
            <div className="container">
                <div className="section row">
                    <h2 className="intro-blk-copy">Welcome to your Vollrath {props.role} Portal</h2>
                </div>

                <div className="row align-items-center profile-outer">
                    <div className="col-md-4 row align-items-center justify-content-around" style={{ borderRight: "1px solid black" }}>
                        <div className="profile-gravatar" style={{ backgroundImage: `url(${gravatarImg})` }}></div>
                        <div className="profile-container">
                            <p className="intro-blk-copy">Welcome back!</p>
                            <p className="section-title">{firstName} {lastName}</p>
                            <p className="intro-blk-copy">{title}</p>
                            <p className="intro-blk-copy">{company}</p>
                        </div>
                    </div>

                    {props.quizzes && props.quizzes.length ? (
                        <div className="col-md-4 justify-content-center" style={{ borderRight: "1px solid black" }}>
                            <ul className="row align-items-center justify-content-center">
                                {props.quizzes.slice(0, 2).map((item, index) => (
                                    <li key={index} className="col-md-3">
                                        <div className="profile-circle">
                                            <img src={item.quizInfo.badgeUrl} alt="quiz badge" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You've earned {props.quizzes.length} certificates</p>
                                <NavLink to={`/my-vollrath-university`}>View All</NavLink>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-4 justify-content-center" style={{ borderRight: "1px solid black" }}>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You've earned 0 certificates</p>
                                <NavLink to={`/my-vollrath-university`}>View All</NavLink>
                            </div>
                        </div>
                    )}

                    {props.events && props.events.length ? (
                        <div className="col-md-4 justify-content-center">
                            <ul className="row align-items-center justify-content-center">
                                {props.events.slice(0, 2).map((item, index) => (
                                    <li key={index} className="col-md-3">
                                        <div className="profile-circle">
                                            <img src={item.eventDetails.mainImage} alt="event badge" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You have {props.events.length} events coming up</p>
                                <NavLink to={`/my-vollrath-university`}>View All</NavLink>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-4 justify-content-center">
                            <div className="profile-container" style={{ textAlign: "center" }}>
                                <p className="intro-blk-copy">You have 0 events coming up</p>
                                <NavLink to={`/my-vollrath-university`}>View All</NavLink>
                            </div>
                        </div>
                    )}
                </div>

                <div className="Dashboard row section">
                    {!props.isEditing ? (
                        <>
                            <div className="col-md-12 add-margin-btm-extra">
                                <button className="btn" onClick={() => props.setIsEditing(!props.isEditing)}>Customize Your Dashboard</button>
                            </div>
                            {props.modules.slice(0, 4).map((item, index) => (
                                <div key={index} className={`col-md-6 ${index % 2 === 0 ? "profile-border" : ""}`}>
                                    <div className="section-title section-header profile justify-content-between portal-module">
                                        {item.displayName}
                                        <span className="section-icon" style={{ backgroundImage: `url(${item.icon})` }}></span>
                                        <span className="section-header-link" onClick={() => navigateToModule(item)}>View All &gt;</span>
                                    </div>
                                    <div className="row">
                                        {item.pages.slice(0, 2).map((page, pageIndex) => (
                                            <CustomContentItem key={pageIndex} {...page} colClass="col-md-6" cardType={false} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <div className="col-md-12 add-margin-btm-extra">
                                <button className="btn no-arrow btn-outline left-padding-20" style={{ marginRight: '20px' }} onClick={() => props.setIsEditing(!props.isEditing)}>Back</button>
                                <button className="btn no-arrow left-padding-20" onClick={() => saveOrder()}>Save</button>
                            </div>
                            {[1, 2, 3, 4].map(i => (
                                <div key={i} className="col-md-3">
                                    <div className="section-title section-header profile justify-content-between">Section {i}</div>
                                    <div className="form-group">
                                        <label htmlFor={`section${i}`}>Section {i}</label>
                                        <select className="form-control" id={`order${i}`} name={`order${i}`} onChange={handleChange} value={order[`order${i}`]}>
                                            {props.modules.map((item) => (
                                                <option key={item.nodeId} value={item.nodeId}>{item.displayName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {props.notifications && props.notifications.length && !Cookies.get("Notified") ? (
                        <div className="notification-box-outer">
                            <div className="notification-box-inner">
                                <p>You have new content available!</p>
                                <p><span onClick={() => navigateToNotifications(props.notifications)}>View All &gt;</span></p>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="section container">
                {props.filterableGrid.heading ? getFilterableGridHeading(props.filterableGrid.heading, props.filterableGrid.headingType) : null}
                {props.filterableGrid.api && (!props.filterableGrid.hideGrid ? (
                    <FilterableGrid {...props.filterableGrid} />
                ) : (
                    <div className="row justify-content-center">
                        <h3 className="section-title">Please check back soon...</h3>
                    </div>
                ))}
            </div>
        </>
    );
}

export default DashboardModules;
