import React, { useEffect, useState } from 'react';
import { getProductsWithChemicalCodeByItemNumber } from '../../../../shared/apis/products';
import ReactPaginate from 'react-paginate';
import animatedScrollTo from 'animated-scroll-to';
import toast from '../../../../shared/components/toast';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
const App = props => {

    const [q, setQ] = useState('');
    const [products, setProducts] = useState([]);
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize] = useState(25);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [codes, setCodes] = useState([]);

    useEffect(() => {
        animatedScrollTo(document.querySelector('#top-ab-table'));
        getRecords();

    }, [page]);

    useEffect(() => {
        setCodes(JSON.parse(props.codes));
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setPage(1);
        getRecords();
    }

    const getRecords = () => {
        setIsLoading(true);
        const params = {
            q: q,
            page: page,
            pageSize: pageSize
        }

        getProductsWithChemicalCodeByItemNumber(params)
            .then(data => {
                console.log(data);
                setProducts(data.records);
                setTotalRecords(data.totalRecords);
                setIsLoading(false);
                if (data.totalRecords) {
                    setMessage(`We found ${data.totalRecords} results`);
                } else {
                    setMessage('No results were found for this product number')
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
                toast.error("There was an error finding products")
            });
    }

    const handleCodeScroll = (item) => {
        animatedScrollTo(document.getElementById(`tag-${item.trim()}`), { offset: -50});
    } 

    const handleChange = (event) => {
        setQ(event.target.value);
    }

    const pages = () => {
        return Math.ceil(totalRecords / pageSize);
    }

    const showPagination = () => {
        return totalRecords > pageSize;
    }

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
    }

    const pagination = () => {
        if (showPagination()) {
            return <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pages()}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={page - 1}

            />;
        }
    }

    return (
        <div>

            <div className="container">
                <form method="get" onSubmit={onSubmit} >
                    <div className="form-group col-lg-6">
                        <label htmlFor="q">Enter an item or UPC number</label>
                        <input className="form-control" value={q} onChange={handleChange}
                            id="q" type="text" name="q" placeholder="Enter an item or UPC number..." autoCapitalize="none"
                            autoCorrect="off" autoFocus="true" />
                    </div>

                    <div className="form-group col-lg-2">
                        <button className="btn full-width" type="submit">
                            Submit
                        </button>
                    </div>

                </form>

                {message &&
                    <h2 className="section section-title">{message}</h2>
                }
                {isLoading ?
                    <div className="container"> <LoaderSection show={true} /></div> :
                    <div>
                        {products.length > 0 &&
                            <div className="panel no-padding">
                                <table className="table table-striped table-side-padding no-margin parts-table">
                                    <thead>
                                        <tr>
                                            <th>Item Number</th>
                                            <th>Product Name</th>
                                            <th>UPC</th>
                                            <th>Chemical Codes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((item) => {

                                            return <tr>
                                                <td><a href={item.aB1200NonComplianceLink}>{item.itemNumber}</a></td>
                                                <td>{item.aB1200NonComplianceDescription}</td>
                                                <td>{item.upc}</td>
                                                <td>{item.aB1200NonComplianceChemicalCode.split(',').map((item, index, arr) => {
                                                    if (arr.length - 1 === index)
                                                        return <a style={{ cursor: "pointer" }} key={index} onClick={() => handleCodeScroll(item)}>{item}</a>
                                                    else 
                                                        return <a style={{ cursor: "pointer" }} key={index} onClick={() => handleCodeScroll(item)}>{item},</a>
                                                    
                                                })}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {pagination()}
                    </div>
                }

                <div>
                    {codes.length > 0 &&
                        <div className="panel no-padding section">
                            <table className="table table-striped table-side-padding no-margin ab-table">
                                <thead>
                                    <tr>
                                        <th><strong>Ingredient Key Number</strong></th>
                                        <th>Contained Product</th>
                                        <th>CAS Number</th>
                                        <th>Reason For Use</th>
                                        <th>Authoritative List Name</th>
                                        <th>Info Links</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {codes.map((item) => {
                                        return <tr id={`tag-${item.IngredientKey}`}>
                                            <td style={{ verticalAlign: "middle" }}>{item.IngredientKey}</td>
                                            <td style={{ verticalAlign: "middle" }}>{item.ContainedProduct}</td>
                                            <td style={{ verticalAlign: "middle" }}>{item.CasNumber}</td>
                                            <td style={{ verticalAlign: "middle" }}>{item.ReasonForUse}</td>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {item.AuthoritativeListName.map((listItem) => {
                                                    return <div><p>{listItem}</p><br /></div>
                                                })}
                                            </td>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {item.InfoLinks.map((infoLink) => {
                                                    return <div><p><a href={infoLink}>{infoLink}</a></p><br /></div>
                                                })}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

App.defaultProps = {

};

export default App;