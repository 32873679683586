import jfetch from '@jontetz/jfetch';

const apiHeader = () => {
    let authToken = JSON.parse(sessionStorage.getItem('authToken'));
    let header = {
        'Authorization': 'Bearer ' + authToken.Token
    }
    return header;
}

export const getProjects = async () => {
    const res = await jfetch.get('/api/projectBuilder/projects', {}, {headers: apiHeader()});
    return res;
}

export const getProject = async (projectId) => {
    const res = await jfetch.get(`/api/projectBuilder/projects/${projectId}`, {}, { headers: apiHeader() });
    return res;
}

export const getRecommendedProjectAttachments = async (projectId) => {
    const res = await jfetch.get(`/api/projectBuilder/projects/${projectId}/recommendedAttachments`, {}, { headers: apiHeader() });
    return res;
}

export const getRecommendedProductAttachments = async (productNodeId) => {
    const res = await jfetch.get(`/api/projectBuilder/products/${productNodeId}/recommendedAttachments`, {}, { headers: apiHeader() });
    return res;
}

export const getRecommendedProductAccessories = async (productNodeId) => {
    const res = await jfetch.get(`/api/projectBuilder/products/${productNodeId}/recommendedAccessories`, {}, { headers: apiHeader() });
    return res;
}

export const sendQuote = async (projectId) => {
    const res = await jfetch.post(`/api/projectBuilder/projects/${projectId}/send`, {}, { headers: apiHeader() });
    return res;
}