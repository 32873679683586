import React from 'react';
import ReactDOM from 'react-dom';
import ProjectBuilderMain from '../react/components/project-builder/project-builder-main.jsx';


export default class ProjectBuilderReceivedQuote {
    constructor() {
        this.container = document.querySelector('#received-quote-view');

        if (this.container) {
            ReactDOM.render(
                <React.StrictMode>
                    <ProjectBuilderMain />
                </React.StrictMode>,
                document.getElementById('received-quote-view')
            );
        }
    }
}