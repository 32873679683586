import jfetch from '@jontetz/jfetch';

export function getPlacesToBuy(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getplacestobuy`, data);
}
export function getPlacesToBuyByState(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getplacestobuybystate`, data);
}

export function getServiceAgents(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getserviceagents`, data);
}

export function getNationalPlacesToBuy(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getnationalplacestobuy`, data);
}

export function getOnlinePlacesToBuy(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getonlineplacestobuy`, data);
}

export function getInternationalPlacesToBuy(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getinternationalplacestobuy`, data);
}

export function getSalesReps(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getdistrictsalesrepsbyzipcode`, data);
}

export function getDistributors(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getdistributorsbyzipcode`, data);
}

export function getIntlSalesReps(data) {
    console.log(data);
    return jfetch.get(`/api/dealers/getdistrictsalesrepsbycountry`, data);
}