import React from 'react';

export default function GlassType({ type, setType }) {
	const handleChange = (type) => {
		setType(type);
	}

	return (
		<div className="step-container">
			<h3 className="step-header step-header--tall">Step 1 - Glass Type</h3>
			<div className="step-content">
				<div className="step-options step-options--moveUp">
					<div className="step-option">
						<i className={`fas fa-glass-whiskey ${type === 1 ? "selected" : ""}`} onClick={() => handleChange(1)}></i>
						Glass
					</div>
					<div className="step-option">
						<i className={`fas fa-beer ${type === 2 ? "selected" : ""}`} onClick={() => handleChange(2)}></i>
						Mug/Cup
					</div>
				</div>

				<label className="control-label">Note:</label>
				<p>If mug with a stem, choose glass.</p>
			</div>
		</div>
	)
}
