import React, { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { getProjects, getProject } from '../../../apis/project-builder';
import { useAddProduct, useDeleteProduct, useAddAttachment, useDeleteAttachment } from '../../../components/project-builder-custom-hooks';
import Carousel from '../../../../shared/react/components/carousel.jsx';
import Modal from 'react-modal';
import Select from 'react-select';
import toast from '../../../../shared/components/toast';

export default function MoveModal({ isMoveOpen, closeMoveModal, selectedProductIds, selectedAttachmentIds, closeSelectedActions }) {

    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const [projectsQuery, projectDetailQuery] = useQueries({
        queries: [
            {
                queryKey: ['projects-list'],
                queryFn: getProjects
            },
            {
                queryKey: ['project-detail', currentProjectId],
                queryFn: () => getProject(currentProjectId)
            }
        ],
    });

    const options = [];

    projectsQuery.data.forEach((item) => {
        let id = item.id;
        let name = item.name;
        let productCount = item.products.length;
        options.push({
            value: id,
            label: name + " (" + productCount + ")"
        });
    });

    let selectedProductData = [];
    let selectedAttachmentData = [];

    if (projectDetailQuery.isSuccess) {
        selectedProductIds.forEach((itemId) => {
            selectedProductData.push(projectDetailQuery.data.products.find(product => product.id == itemId));
        });

        selectedAttachmentIds.forEach((itemId) => {
            selectedAttachmentData.push(projectDetailQuery.data.attachements.find(attachment => attachment.id == itemId));
        });
    }

    const [moveToProjectId, setMoveToProjectId] = useState(currentProjectId);

    const { mutate: addProduct, isLoading } = useAddProduct(moveToProjectId);
    const { mutate: deleteProduct, isLoading: deleteIsLoading } = useDeleteProduct(currentProjectId);

    const { mutate: addAttachment, isLoading: addAttachmentIsLoading } = useAddAttachment(moveToProjectId);
    const { mutate: deleteAttachment, isLoading: deleteAttachmentIsLoading } = useDeleteAttachment(currentProjectId);

    //undo move products
    const { mutate: addProductToCurrentProject } = useAddProduct(currentProjectId);
    const { mutate: deleteProductFromMoveToProject } = useDeleteProduct(moveToProjectId);

    //undo move attachments
    const { mutate: addAttachmentToCurrentProject } = useAddAttachment(currentProjectId);
    const { mutate: deleteAttachmentFromMoveToProject } = useDeleteAttachment(moveToProjectId);


    const handleSelectProject = (value) => {
        setMoveToProjectId(value.value);
    };

    const handleUndoMoveProducts = (products) => {
        products.forEach((product) => {
            addProductToCurrentProject(product);
            deleteProductFromMoveToProject(product.id, {
                onSuccess: () => toast.success("Products returned")
            });
        })
    }

    const handleUndoMoveAttachments = (attachments) => {
        attachments.forEach((attachment) => {
            const isVideo = attachment.isVideo;

            let payload = {
                documentLocalSlug: attachment.documentLocalSlug
            };

            isVideo ? payload = { videoNodeId: attachment.videoNodeId } : null

            addAttachmentToCurrentProject(payload);
            deleteAttachmentFromMoveToProject(attachment.id, {
                onSuccess: () => toast.success("Attachments returned")
            })
        })
    }

    const deleteProducts = () => {
        selectedProductData.forEach((product) => {
            deleteProduct(product.id, {
                onSuccess: () => {
                    closeMoveModal();
                    closeSelectedActions();
                    toast.success({
                        message: "Products moved <span id='undo-button'>Undo</span>",
                        className: "with-undo-button",
                        duration: 5000
                    })
                    .on('click', () => handleUndoMoveProducts(selectedProductData));
                }
            });
        });
    }

    const deleteAttachments = () => {
        selectedAttachmentData.forEach((attachment) => {
            deleteAttachment(attachment.id, {
                onSuccess: () => {
                    closeMoveModal();
                    closeSelectedActions();
                    toast.success({
                        message: "Attachments moved <span id='undo-button'>Undo</span>",
                        className: "with-undo-button",
                        duration: 5000
                    })
                    .on('click', () => handleUndoMoveAttachments(selectedAttachmentData));
                }
            });
        });
    }


    const handleMoveItems = () => {
        //first add items to new project
        if (selectedProductData.length) {
            selectedProductData.forEach((product) => {
                addProduct(product, {
                    onSuccess: () => {
                        //then delete from current project
                        deleteProducts();
                    }
                });
            });
        }

        if (selectedAttachmentData.length) {
            selectedAttachmentData.forEach((attachment) => {
                addAttachment(attachment, {
                    onSuccess: () => {
                        deleteAttachments();
                    }
                });
            });
        }
    }

    return (
        <>
            {(projectsQuery.isSuccess && projectDetailQuery.isSuccess)
               ? <Modal
                    isOpen={isMoveOpen}
                    onRequestClose={closeMoveModal}
                    className="project-builder project-builder-modal project-builder-modal--xs project-builder-modal--paddingSmall project-builder-modal--move"
                >
                    <button className="project-builder-modal-close" onClick={closeMoveModal}>
                        <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                    </button>
                        <div className='modal-title text-left'>Move selected items</div>

                    <Carousel>
                        {selectedProductData?.map((product) => (
                            <div className="carousel-cell"><img src={product?.imageUrl} alt={product?.title} /></div>
                        ))}
                    </Carousel>

                    <label>Move to Project</label>
                    <Select
                        className="mb-2"
                        classNamePrefix="styled-select"
                        options={options}
                        defaultValue={options[0]}
                        onChange={handleSelectProject}
                    />
                    <div className="text-center">
                        <button type="button" className="btn no-arrow mb-1" disabled={isLoading || deleteIsLoading || addAttachmentIsLoading} onClick={handleMoveItems}>yes, move items</button>
                        <div>
                            <button className="link-btn" disabled={isLoading || deleteIsLoading || addAttachmentIsLoading} onClick={closeMoveModal}>Cancel</button>
                            </div>
                        </div>
                </Modal>
                : null
            }
        </>
    );
}