import React from 'react'

export default function RackBrand({ disabled, brand, handleBrandChange }) {
	return (
		<div className={`step-container step-container--wide ${disabled ? "disabled" : ""}`}>
			<h3 className="step-header">Step 4 - Rack Brand</h3>
			<div className="step-content">
				<div className="step-options step-options--column rackBrand">
					<div className={`step-option step-option--brand ${brand === 1 ? "selected" : ""}`} onClick={() => handleBrandChange(1)}>
						<strong className="brand-logo">VOLLRATH </strong> Signature
					</div>
					<hr className="or-divider" />
					<div className={`step-option step-option--brand ${brand === 2 ? "selected" : ""}`} onClick={() => handleBrandChange(2)}>
						<strong className="brand-logo">VOLLRATH </strong> Traex
					</div>
				</div>
			</div>
		</div>
	)
}
