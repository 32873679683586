import React, { useState } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useDeleteProduct, useDeleteAttachment, useEditProject, useAddProduct, useAddAttachment } from '../../../components/project-builder-custom-hooks';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import Modal from 'react-modal';
import toast from '../../../../shared/components/toast';


export default function DeleteModal({ isDeleteOpen, closeDeleteModal, type, product, attachment, selectedProductIds, setSelectedProductIds, projectId, projectName, selectedAttachmentIds, setSelectedAttachmentIds, productsInFamily }) {
    //type can be project, product, or family
    //change text accordingly
    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess } = useQuery(['project-detail', projectId ? projectId : currentProjectId], () => getProject(projectId ? projectId : currentProjectId));

    const { mutate: deleteProduct, isLoading } = useDeleteProduct(currentProjectId);
    const { mutate: deleteAttachment, isLoading: attachmentIsLoading } = useDeleteAttachment(currentProjectId);
    const { mutate: editProject, isLoading: editProjectIsLoading } = useEditProject(currentProjectId);
    const { mutate: addProduct } = useAddProduct(currentProjectId);
    const { mutate: addAttachment } = useAddAttachment(currentProjectId);

    let titleText = "";
    let acceptBtnText = "";
    let cancelBtnText = "";

    if (type === "project") {
        titleText = "Are you sure you want to delete " + projectName + "?";
        acceptBtnText = "yes, delete project";
        cancelBtnText = "No, Keep Project";
    }
    else if (product) {
        titleText = "Are you sure you want to delete " + product.Title +  "?";
        acceptBtnText = "yes, delete product";
        cancelBtnText = "No, Keep Product";
    }
    else if (type === "family") {
        titleText = "Are you sure you want to delete section?";
        acceptBtnText = "yes, delete section";
        cancelBtnText = "No, Keep Section";
    }
    else if (attachment) {
        titleText = "Are you sure you want to delete attachment?";
        acceptBtnText = "Yes, delete attachment";
        cancelBtnText = "No, keep attachment";
    }
    else {
        titleText = "Are you sure you want to delete selected items?";
        acceptBtnText = "yes, delete items";
        cancelBtnText = "No, Keep Items";
    }

    const handleUndoDeleteProducts = (products) => {
        products.forEach((product) => {
            addProduct(product);
        })
    }

    const handleUndoDeleteAttachments = (attachments) => {
        attachments.forEach((attachment) => {
            const isVideo = attachment.isVideo;

            let payload = {
                documentLocalSlug: attachment.documentLocalSlug
            };

            if (isVideo) {
                payload = {
                    videoNodeId: attachment.videoNodeId
                };
            }

            addAttachment(payload, {
                onSuccess: () => toast.success("Attachment added")
            });
        })
    }

    const handleDeleteItems = () => {
        //delete single project
        if (projectId) {
            const payload = {
                id: projectId,
                status: "Deleted",
            }
            editProject(payload, {
                onSuccess: () => {
                    closeDeleteModal();
                    toast.success("Project Deleted");
                }
            })
        }

        //delete single product
        if (product && !selectedProductIds?.length) {
            deleteProduct(product.id, {
                onSuccess: () => {
                    closeDeleteModal();
                    toast.success({
                        message: "Removed from your project <span id='undo-button'>Undo</span>",
                        className: "with-undo-button",
                        duration: 5000
                    })
                    .on('click', () => handleUndoDeleteProducts([product]));
                }
            });
        }

        //delete single attachment
        if (attachment) {
            deleteAttachment(attachment.id, {
                onSuccess: () => {
                    closeDeleteModal();
                    toast.success({
                        message: "Attachment removed <span id='undo-button'>Undo</span>",
                        className: "with-undo-button",
                        duration: 5000
                    })
                    .on('click', () => handleUndoDeleteAttachments([attachment]));
                }
            });

        }

        //delete multiple products at once
        if (selectedProductIds?.length) {
            //get all data for selected products to pass to undo
            const selectedProductsData = data.products.filter((product) => selectedProductIds.includes(product.id));

            selectedProductIds.forEach((productId) => {
                deleteProduct(productId, {
                    onSuccess: () => {
                        closeDeleteModal();
                        toast.success({
                            message: "Removed from your project <span id='undo-button'>Undo</span>",
                            className: "with-undo-button",
                            duration: 5000
                        })
                        .on('click', () => handleUndoDeleteProducts(selectedProductsData));
                        //reset selectedProducts
                        setSelectedProductIds([]);
                    }
                });
            });

        }

        //delete multiple attachments at once
        if (selectedAttachmentIds?.length) {
            //get all data for selected products to pass to undo
            const selectedAttachmentsData = data.attachements.filter((attachment) => selectedAttachmentIds.includes(attachment.id));

            selectedAttachmentIds.forEach((attachmentId) => {
                deleteAttachment(attachmentId, {
                    onSuccess: () => {
                        closeDeleteModal();
                        toast.success({
                            message: "Removed from your project <span id='undo-button'>Undo</span>",
                            className: "with-undo-button",
                            duration: 5000
                        })
                        .on('click', () => handleUndoDeleteAttachments(selectedAttachmentsData));
                        //reset selectedAttachments
                        setSelectedAttachmentIds([]);
                    }
                });
            });

        }

        //delete family of products
        if (productsInFamily) {
            productsInFamily.forEach((product) => {
                deleteProduct(product.id, {
                    onSuccess: () => {
                        closeDeleteModal();
                    }
                });
            });
        }

        //todo - handle when sortType is custom grouping
    }

    return (
        <>
            <Modal
                isOpen={isDeleteOpen}
                onRequestClose={closeDeleteModal}
                className="project-builder project-builder-modal project-builder-modal--xs text-center"
            >
                <button className="project-builder-modal-close" onClick={closeDeleteModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container">
                    <div className='modal-title mb-2'>{titleText}</div>
                    <button type="button" className="btn no-arrow mb-1" disabled={isLoading || attachmentIsLoading} onClick={handleDeleteItems}>{acceptBtnText}</button>
                    <div>
                        <button className="link-btn" onClick={closeDeleteModal}>{cancelBtnText}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}