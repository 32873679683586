import React, { Component } from 'react';
import animatedScrollTo from 'animated-scroll-to';

export default class FilteringBase extends Component {
    constructor(props) {
        super(props);

        this.baseState = {
            q: '',
            items: [],
            page: 1,
            pageSize: 15,
            totalRecords: 0,
            isLoading: true
        };

        this.resultsSelector = '#results';
    }

    pages() {
        return Math.ceil(this.state.totalRecords / this.state.pageSize);
    }

    showPagination() {
        return this.state.totalRecords > this.state.pageSize && !this.state.isLoading;
    }

    handlePageClick(data) {
        this.setState({
            page: data.selected + 1
        }, () => {
            this.load();
            animatedScrollTo(document.querySelector(this.resultsSelector));
        });
    }

    resetPaging() {
        this.setState({
            page: 1
        });
    }
}