import React from 'react'

export default function DisplayModules({ moduleCount, activeModuleIndex, selectedComponents, onClick }) {
	let content = [];

	function displayModules() {
		for (let i = 0; i < moduleCount; i++) {
			content.push(
				<div
					key={i}
					className={`layoutModule bigSquare ${activeModuleIndex === "module" + i ? 'is-active' : ""}`}
					onClick={() => onClick(i)}
				>
					{selectedComponents[i] === undefined ? "_" : selectedComponents[i].code}
				</div>
			);
		}
		return content;
	}

	return (
		displayModules()
	)
}
