import React, { useState, useEffect } from 'react';

export default function RackColors({ type, modelInfo, sizeInfo, size, brand, searchBySize, colorsApiUrl, setIsColorsSelected, selectedColors, setSelectedColors, disabled }) {

	//const [baseColors, setBaseColors] = useState(null);
	//const [riserColors, setRiserColors] = useState(null);

	const [colorOptions, setColorOptions] = useState(null);
	const [colorOptionsLoaded, setColorOptionsLoaded] = useState(false);
	const [colorOptionsAreLoading, setColorOptionsAreLoading] = useState(false);

	if (colorOptionsLoaded) {
		var questionText = selectedColors.hasDifferentColoredTopRiser ? "Top" : "All";

		if (selectedColors.rackColorCode !== null && selectedColors.riserColorCode !== null && selectedColors.hasDifferentColoredTopRiser !== null) {
			setIsColorsSelected(true);
		}

		if (!colorOptions.shouldShowRiserColors) {
			if (selectedColors.rackColorCode) setIsColorsSelected(true);
		}

		if (!colorOptions.shouldShowBaseColors) {
			if (selectedColors.rackColorCode !== null && selectedColors.riserColorCode && selectedColors.hasDifferentColoredTopRiser !== null) setIsColorsSelected(true);
		}

		if (colorOptions.baseColors == null) setIsColorsSelected(true);
	}

	const handleColorChange = (e) => {
		let colorCode = null;
		let name = e.target.name;
		let selectedIndex = e.target.selectedIndex;

		if (selectedIndex === 0) {
			setIsColorsSelected(false);
			colorCode = null;

			if (name === "rackColorCode") {
				setSelectedColors({
					rackColorCode: null,
					riserColorCode: null,
					hasDifferentColoredTopRiser: null
				});
			}
		}
		else {
			if (name === "rackColorCode") {
				colorCode = colorOptions.baseColors[selectedIndex - 1].code;
			}
			else {
				colorCode = colorOptions.riserColors[selectedIndex - 1].code;
			}
			
		}

		setSelectedColors(prevState => ({
			...prevState,
			[name]: colorCode
		}));
	}

	const handleDifferentColoredTopRiserChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		(value === "yes") ? value = true : value = false;
		setSelectedColors(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const fetchColorOptions = () => {
		setColorOptionsAreLoading(true);

		const colorsRequestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		};

		fetch(colorsApiUrl, colorsRequestOptions)
			.then(response => response.json())
			.then(result => {
				setColorOptions(result);
				setColorOptionsLoaded(true);
			})
			.finally(() => {
				setColorOptionsAreLoading(false);
			});
	}

	const resetRackColors = () => {
		setColorOptionsLoaded(false);
		setIsColorsSelected(false);
		setSelectedColors({
			rackColorCode: null,
			riserColorCode: null,
			hasDifferentColoredTopRiser: null
		});
	}

	useEffect(() => {
		resetRackColors();
	}, [type, modelInfo, sizeInfo, size, brand, searchBySize]);

	return (
		<div className={`step-container step-container--extraWide ${disabled ? "disabled" : ""}`}>
			<h3 className="step-header">Step 5: Rack Colors</h3>
			<div className="step-content">
				<div className="step-options step-options--column">
					{!colorOptionsLoaded && !colorOptionsAreLoading &&
						<div className="step-option">
							<i className="fas fa-palette" onClick={fetchColorOptions}></i>
							Select Colors
						</div>
					}
					{colorOptionsAreLoading &&
						<div className="loader-section">
							<div className="loader-spinner"></div>
						</div>
					}
					{colorOptionsLoaded &&
						<>
							<label className="control-label" for="rackColorCode">Base Color*</label>
							<select className="form-control" name="rackColorCode" onChange={event => handleColorChange(event)}>
								<option value="">Select Base Color</option>
								{colorOptions.baseColors.map((color, index) => (
									<option key={index} value={color.displayName}>{color.displayName}</option>
								))}
						</select>
						{colorOptions.shouldShowRiserColors && selectedColors.rackColorCode !== null &&
								<div className="color-radios">
									<label className="control-label">Riser Color Configuration*</label>
									<label>
										<input
											type="radio"
											name="hasDifferentColoredTopRiser"
											value="yes"
											onChange={event => handleDifferentColoredTopRiserChange(event)}
										/>
												Top riser a different color
									</label>
									<label>
										<input
											type="radio"
											name="hasDifferentColoredTopRiser"
											value="no"
											onChange={event => handleDifferentColoredTopRiserChange(event)}
										/>
												All risers the same color
									</label>
								</div>
						}
						{colorOptions.shouldShowRiserColors && selectedColors.rackColorCode !== null && selectedColors.hasDifferentColoredTopRiser !== null &&
								<>
									<label className="control-label" for="riserColorCode">{questionText} Riser Color*</label>
									<select className="form-control" name="riserColorCode" onChange={event => handleColorChange(event)}>
									<option value="">Select {questionText} Riser Color</option>
										{colorOptions.riserColors.map((color, index) => (
											<option key={index} value={color.displayName}>{color.displayName}</option>
										))}
									</select>
								</>
							}
						</>
					
					}
					{colorOptionsLoaded && (colorOptions.baseColors == null) &&
						<h5 className="text-center">Color choices are not available for this product. Continue by clicking "Find My Rack" below.</h5>
					}
				</div>
			</div>
		</div>
	)
}
