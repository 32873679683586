import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import Modal from 'react-modal';
import DiscountDropdown from './discount-dropdown.jsx';


export default function DiscountModal({ productId, discountType, discountValue, closeSelectedActions }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { mutate: updateProduct, isLoading } = useUpdateProduct(currentProjectId);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const [newDiscount, setNewDiscount] = useState({
        id: productId,
        discountType: discountType,
        discountValue: discountValue
    });

    const [openAmount, setOpenAmount] = useState(discountValue);
    const [percentageAmount, setPercentageAmount] = useState(discountValue);
    const [dollarAmount, setDollarAmount] = useState(discountValue);

    let buttonText = "";

    switch (discountType) {
        case "FiftyTen":
            buttonText = ("50/10 Discount");
            break;
        case "CustomPercentage":
            buttonText = (discountValue + "% Discount");
            break;
        case "CustomDollarAmount":
            buttonText = ("$" + discountValue + " Discount");
            break;
        default:
            buttonText = ("Select Discount");
            break;
    }

    const handleApplyDiscount = () => {
        let discountPayload = {};
        productId.map((itemId) => {
            discountPayload = {
                id: itemId,
                discountType: newDiscount.discountType,
                discountValue: newDiscount.discountValue
            }

            updateProduct(discountPayload, {
                onSuccess: () => {
                    closeModal();
                    //closeSelectedActions();
                }
            });
        });
    }

    const handleRemoveDiscount = () => {
        let discountPayload = {};
        productId.map((itemId) => {
            discountPayload = {
                id: itemId,
                discountType: "None",
                discountValue: 0
            }

            updateProduct(discountPayload, {
                onSuccess: () => {
                    closeModal();
                    //closeSelectedActions();
                }
            });
        });
    }

    useEffect(() => {
        setNewDiscount({
            id: productId,
            discountType: discountType,
            discountValue: discountValue
        })
    }, [discountType])

    return (
        <>
            {discountType
                ? <button class="link-btn underline add-discount" type="button" onClick={openModal}>{buttonText}</button>
                : <button class="btn btn-outline-alt" type="button" onClick={openModal}>add discount ({productId.length})</button>
            }
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="project-builder project-builder-modal project-builder-modal--xs project-builder-modal--discount"
            >
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container">
                    <div className='modal-title'>Add Discount</div>
                    <p>Select discount to apply to all selected products.</p>
                    <DiscountDropdown
                        newDiscount={newDiscount}
                        setNewDiscount={setNewDiscount}
                        percentageAmount={percentageAmount}
                        setPercentageAmount={setPercentageAmount}
                        dollarAmount={dollarAmount}
                        setDollarAmount={setDollarAmount}
                    />
                    <div className="form-actions">
                        <button class="btn btn-outline-alt" type="button" onClick={handleRemoveDiscount}>Remove Discount</button>
                        <button
                            className="btn"
                            type="button"
                            disabled={isLoading}
                            onClick={handleApplyDiscount}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}