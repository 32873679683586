import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import animatedScrollTo from 'animated-scroll-to';
import { canContactExport } from '../../../../shared/apis/account';

const DashboardEventCodes = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [contactCodes, setContactCodes] = useState([]);
    const [nonContactCodes, setNonContactCodes] = useState([]);
    const [canExport, setCanExport] = useState(false);

    useEffect(() => {
        animatedScrollTo(document.querySelector('#code-top'));

        setContactCodes(location.state.data.filter(r =>props.contact.Email.toLowerCase() === r.dsm?.toLowerCase() || props.contact.Email.toLowerCase() === r.rsm?.toLowerCase() || r.admin?.includes(props.contact.Email.toLowerCase())))
        setNonContactCodes(location.state.data.filter(r =>  props.contact.Email.toLowerCase() !== r.dsm?.toLowerCase() && props.contact.Email.toLowerCase() !== r.rsm?.toLowerCase()))

        canContactExport()
            .then(data => {
                setCanExport(JSON.parse(data));

            })
            .catch(error => {
                console.log(error);

            });
    }, [])

    const navigateToEventCode = (item) => {
        navigate("/eventcode", { state: { data: item } })
    }

    const exportData = () => {
        const params = {
            eventId: location.state.data[0].eventId
        }

        props.exportEventData(params);
    }

    return (
        <>

            <div id="code-top" className="container">
                <div className="row section">
                    <div className="col-md-12 add-margin-btm-extra">
                        <NavLink to={`/my-vollrath-university`}>
                            Return
                        </NavLink>
                    </div>
                    <div className="col-md-12">
                        {canExport &&
                            <button className="btn add-margin-btm" onClick={() => exportData()}>Export Event Data</button>
                        }

                        {location.state.data && location.state.data.length ?
                            <div>
                                <div className="section-header"><h3 className="section-title"><span>VU Event Codes</span></h3><div className="spacer"></div></div>
                                <table className="table table-striped">
                                    <tbody>
                                        {contactCodes.length > 0 &&

                                            contactCodes.map((item, index) =>
                                                <tr key={index} className="">
                                                    <td className="vu-dashboard-content">{`Invite Code: ${item.registrationCode}`}</td>
                                                    <td className="vu-dashboard-content">{`Used By: ${item.usedBy}`}</td>
                                                    <td className="vu-dashboard-content">{`Is Registered: ${item.isRegistered}`}</td>
                                                    <td className="vu-dashboard-content">{`Is Confirmed: ${item.isConfirmed}`}</td>
                                                    <td className="vu-dashboard-content">{`RSM: ${item.rsm}`}</td>
                                                    <td className="vu-dashboard-content">{`DSM: ${item.dsm}`}</td>
                                                    <td><a className="text-link quiz-btn" onClick={() => navigateToEventCode(item)} >Edit This Code</a></td>

                                                </tr>
                                            )}



                                        {nonContactCodes.length > 0 &&

                                            nonContactCodes.map((item, index) =>
                                                <tr key={index} className="">
                                                    <td className="vu-dashboard-content">{`Invite Code: ${item.registrationCode}`}</td>
                                                    <td className="vu-dashboard-content">{`Used By: ${item.usedBy}`}</td>
                                                    <td className="vu-dashboard-content">{`Is Registered: ${item.isRegistered}`}</td>
                                                    <td className="vu-dashboard-content">{`Is Confirmed: ${item.isConfirmed}`}</td>
                                                    <td className="vu-dashboard-content">{`RSM: ${item.rsm}`}</td>
                                                    <td className="vu-dashboard-content">{`DSM: ${item.dsm}`}</td>
                                                </tr>
                                            )}

                                    </tbody>
                                </table>

                            </div> :
                            <>
                            </>
                        }
                    </div>
                </div>
            </div >

        </>
    );
}

export default DashboardEventCodes;