import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import toast from '../../../../shared/components/toast';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from 'react-select';
import MUIDataTable from 'mui-datatables';
import ReactPaginate from 'react-paginate';
import NewProjectModal from './new-project-modal.jsx';
import MyProjectsActions from './my-projects-actions.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import EditProjectModal from './edit-project-modal.jsx';
import EditProjectStatus from './edit-project-status.jsx';
import OverviewModal from './overview-modal.jsx';

export default function MyProjects() {

    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();


    const navigate = useNavigate();

    const VFSTheme = createTheme({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "none",
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        minHeight: '360px',
                        '.project-title': {
                            marginBottom: '5px',
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: '#5d6677',
                        fontSize: '16px',
                        fontWeight: '600',
                        fontFamily: '"proxima-nova", sans-serif',
                        '&::placeholder': {
                            color: '#5d6677'
                        }
                    },
                    root: {
                        '&:before': {
                            border: 'none !important'
                        }
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            background: 'none !important'
                        }
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        fontSize: "14px",
                        color: "#5d6677",
                        padding: "35px",
                    }
                }
            },
            MUIDataTableCell: {
                styleOverrides: {
                    root: {
                        margin: "0px",
                        padding: "0px",
                    }
                }
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        position: 'static',
                        '&:last-child': {
                            'div': {
                                display: 'none'
                            }
                        }
                    },
                    data: {
                        color: '#5d6677',
                        fontWeight: '700',
                        padding: "0px 15px",
                    },
                }
            },
            MUIDataTableSearch: {
                styleOverrides: {
                    main: {
                        borderRadius: "7px",
                        border: "1px solid #BEC3CD",
                        height: "62px",
                        padding: "5px 20px",
                        position: "absolute",
                        top: "-60px",
                        right: "0px",
                        width: "100%",
                        maxWidth: '425px',
                    },
                }
            },
            MUIDataTableBodyRow: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#f9fafd !important',
                            cursor: 'pointer'
                        }
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor: '#bec3cd',
                        padding: '12px 16px',
                        '&:last-child': {
                            textAlign: 'right',
                            'div:last-child': {
                                padding: '0px',
                            }
                        }
                    }
                }
            },
            MUIDataTableFilterList: {
                styleOverrides: {
                    root: {
                        display: 'none',
                    }
                }
            },
            MUIDataTable: {
                styleOverrides: {
                    responsiveBase: {
                        overflow: 'visible',
                    }
                }
            },
            MUIDataTableFooter: {
                styleOverrides: {
                    root: {
                        minHeight: 'auto',
                    }
                }
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        //display: 'none',
                    }
                }
            },

        }
    });

    const { data, isSuccess, isLoading, isError, error } = useQuery(['projects-list'], getProjects);

    const [activePage, setActivePage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState("In Progress");

    const [isOverviewOpen, setIsOverviewOpen] = useState(false);

    const openOverviewModal = projectId => {
        setCurrentProjectId(projectId)
        setIsOverviewOpen(true);
    }

    const closeOverviewModal = () => setIsOverviewOpen(false);

    const allStatusOptions = ["In Progress", "Outgoing", "Incoming", "Completed", "Archived", "None"];

    const handleRowClick = (projectId) => {
        setCurrentProjectId(projectId);
        navigate(`/project-builder/${projectId}`);
    }

    const StatusFilterDropdown = () => {

        const options = [];

        allStatusOptions.forEach((item) => {
            options.push({ value: item, label: item });
        });

        return (
            <th className="status-filter">
                <Select
                    classNamePrefix="styled-select"
                    options={options}
                    defaultValue={options[0]}
                    isSearchable={false}
                    onChange={handleStatusFilterChange}
                />
            </th>
        );
    };

    const handleStatusFilterChange = (selectedFilter) => {
        const filteredCols = [...cols];
        let filterList = [];
        setSelectedFilter(selectedFilter.value);
        if (selectedFilter.value === "None") {
            filterList = allStatusOptions;
        }
        else {
            filterList = [selectedFilter.value];
        }

        filteredCols[4].options.filterList = filterList;
        setCols(filteredCols);
    };

    const mobileView = window.innerWidth < 750;

    const initCols = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
                viewColumns: false
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                //sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="project-title">{tableMeta.rowData[1]}</div>
                            <div className="">{tableMeta.rowData[2]}</div>

                        </>
                    );
                }
            }
        },
        {
            name: "accountName",
            label: "Created By",
            options: {
                filter: false,
                sort: false,
                display: false,
                viewColumns: false,
            }
        },
        {
            name: "updatedAt",
            label: "Last Updated",
            options: {
                display: !mobileView,
                //sort: false,
                //sortOrder: 'desc',
                customBodyRender: value => <span>{new Date(value).toLocaleDateString('en-us', { month: "short", day: "numeric", year: "numeric" })}</span>,
            }
        },
        {
            name: "status",
            label: "Project Status",
            options: {
                customHeadRender: () => (
                    <StatusFilterDropdown />
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <EditProjectStatus projectId={tableMeta.rowData[0]} status={{ label: tableMeta.rowData[4], value: tableMeta.rowData[4] }} />
                    );
                },
                sort: false,
            }
        },
        {
            name: "isMarketingProject",
            label: "Project Type",
            options: {
                display: !mobileView,
                //sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div>{value ? 'Sales Project' : 'Quote Project'}</div>
                        </>
                    );
                },
            }
        },
        {
            name: "totalNetPrice",
            label: "Quote Price",
            options: {
                display: !mobileView,
                //sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return tableMeta.rowData[5] ? <span>N/A</span> :
                    <span>${value?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                }
            }
        },
        {
            name: "Overview",
            label: "Overview",
            options: {
                display: !mobileView,
                customBodyRender: (value, tableMeta) => {
                    return <>
                        <button type="button" className="link-btn" onClick={e=>openOverviewModal(tableMeta.rowData[0])}>
                            Overview
                        </button>
                        
                    </>
                }
            }
        },
        {
            name: "actions",
            label: "actions",
            options: {
                display: !mobileView,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <MyProjectsActions projectId={tableMeta.rowData[0]} projectName={tableMeta.rowData[1]} />
                )
            }
        }
    ];

    const [cols, setCols] = useState(initCols);

    const CustomFooter = ({ count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels }) => {

        const handlePageChange = (event) => {
            changePage(event.selected);
            setActivePage(event.selected);
        };

        return (
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={count / rowsPerPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={activePage}
            />
        );
    };

    const options = {
        selectableRows: 'none',
        download: false,
        filter: false,
        search: true,
        searchAlwaysOpen: true,
        searchPlaceholder: 'Search Projects',
        print: false,
        viewColumns: false,
        responsive: "standard",
        rowsPerPage: 10,
        sortOrder: {
            name: "UpdatedAt",
            direction: 'desc',
        },
        textLabels: {
            body: {
                toolTip: null,
                columnHeaderTooltip: null
            },
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    changePage={changePage}
                    textLabels={textLabels}
                />
            );
        },
        onCellClick: (cellIndex, rowIndex, rowData) => {
            const overviewIndex = 7;
            const actionsIndex = 8;
            const statusIndex = 4;

            const restrictedIndexes = [overviewIndex, actionsIndex, statusIndex];

            if (restrictedIndexes.includes(rowIndex.colIndex)) return;
            handleRowClick(data[rowIndex.dataIndex].id);
        },
        onTableChange: (action, tableState) => {
            if (action === "propsUpdate") {
                //if page is out of range set page to 0
                if ((tableState.page + 1) > Math.ceil(tableState.displayData.length / tableState.rowsPerPage)) {
                    setActivePage(0);
                    tableState.page = 0;
                }
            }
        },
        customSearch: (searchQuery, currentRow, columns) => {
            let isFound = false;
            currentRow.forEach(col => {
                if (col && col.toString().toLowerCase().indexOf(searchQuery) >= 0) {
                    isFound = true;
                }
            });
            return isFound;
        },
    }


    useEffect(() => {
        cols[4].options.filterList = ["In Progress"];
        const filteredCols = [...cols];
        setCols(filteredCols);
        //return () => {

        //};
    }, []);

    return (
        <div className="project-builder container section equal-margin project-builder-projects-list">
            <div className='section-title'>My Projects</div>

            {isLoading &&
                <LoaderSection show={true} />
            }

            {isError &&
                <div>Error!</div>
            }

            {isSuccess &&
                <>
                    <NewProjectModal
                        openButtonText="create new project"
                        buttonClass="btn no-arrow btn--add"
                    />

                    <ThemeProvider theme={VFSTheme}>
                        <MUIDataTable
                            data={data}
                            columns={cols}
                            options={options}
                        />
                    </ThemeProvider>
                </>
            }

            <OverviewModal
                isOpen={isOverviewOpen}
                closeOverviewModal={closeOverviewModal}
                projectId={currentProjectId}
            />
            
        </div>
    );
}