import React, { useState, useEffect } from 'react'

export default function ManufacturerOption({ type }) {
	const [manuOptions, setManuOptions] = useState([]);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	};

	let apiUrl = "";

	if (type === 1) {
		apiUrl = '/api/WarewashingConfiguration/GetManufacturers?glassType=Glass';
	}
	else {
		apiUrl = '/api/WarewashingConfiguration/GetManufacturers?glassType=MugCup';
	}


	useEffect(() => {
		if (type !== null) {
			fetch(apiUrl, requestOptions)
				.then(response => response.json())
				.then(result => {
					setManuOptions(result);
				});
		}
	}, [type]);

	return (
		<>
			{manuOptions.map((option, index) => (
				<option key={index} value={option}>
					{option}
				</option>
			))}
		</>
	)
}
