import React from 'react'

export default function RackSize({ size, disabled, handleSizeChange }) {

	return (
		<div className={`step-container ${disabled ? "disabled" : ""}`}>
			<h3 className="step-header step-header--tall">Step 3 - Rack Size</h3>
			<div className="step-content">
				<div className="step-options step-options--moveUp">
					<div className="step-option">
						<i className={`fas fa-th ${size === 1 ? "selected" : ""}`} onClick={() => handleSizeChange(1)}></i>
						Full
					</div>
					<div className="step-option">
						<i className={`fas fa-grip-vertical ${size === 2 ? "selected" : ""}`} onClick={() => handleSizeChange(2)}></i>
						Half
					</div>
				</div>
			</div>
		</div>
	)
}
