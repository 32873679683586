import React from 'react';

export default function PDFView() {

	let paramsString = new URLSearchParams(window.location.search);
	let currency = paramsString.get("currency");
	let dispenserType = paramsString.get("dispensertype").replaceAll("_", " ");
	let layout = paramsString.get("layout").replaceAll("_", " ");
	let itemNumber = paramsString.get("itemnumber");
	let price = paramsString.get("price").replaceAll("_", " ");
	let moduleCodes = paramsString.get("modulecodes").split(",");
	let layoutOrientation = layout.split(" ")[1];
	/*
	if (layoutOrientation === "Square") {
		let squareModuleCodes = [];
		squareModuleCodes[0] = moduleCodes[1];
		squareModuleCodes[1] = moduleCodes[3];
		squareModuleCodes[2] = moduleCodes[0];
		squareModuleCodes[3] = moduleCodes[2];

		moduleCodes = squareModuleCodes;
	}

	if (layoutOrientation === "Vertical") {
		moduleCodes.reverse();
	}
	*/

	return (
		<div id="printView">
			<h1>Your Vollrath Dispenser</h1>
			<div>Currency: {currency === "0" ? "US Dollars ($)" : "Euros (" + String.fromCharCode(8364) + ")"}</div>
			<div>Dispenser Type: {dispenserType}</div>
			<div>Layout: {layout}</div>
			<hr />
			<div className="center">
				<h2>Your Results</h2>
				<p>Your item number and total list price are shown below for the dispenser you have built online. Please contact your nearest Vollrath sales representative for more information. Contact information can be found at <a href="/contact-Us">Vollrath.com/contact-Us.</a></p>

				<div className={`modulesContainer results ${layoutOrientation}`}>
					{moduleCodes.map((item, i) => (
						<div key={i} className="layoutModule bigSquare">{item}</div>
					))}
				</div>

				<p className="large">Your Complete Item #: {itemNumber}</p>
				<p className="large">Total List Price: {price}</p>
				<p><strong>Component Key:</strong></p>
				<p>A,D=Cup, S=Straw, P=Condiment, Two-letter code (i.e. AB)=Lid</p>
				<hr />
				<p>Go to <a href="/">Vollrath.com</a> to find spec sheets, manuals, videos, or other resources for this product.</p>
				<hr />
			</div>
			<div id="printFooter">
				<div className="notes">
					<p>Note: LidSaver items are custom products and are non-returnable.</p>
					<p>Prices and availability subject to change without notice.</p>
					<p>Taxes are not included in prices.</p>
				</div>
				<div className="copyright">
					<p>&copy; 2021 The Vollrath Company, L.L.C.</p>
					<p>An ISO 9001 certified company</p>
				</div>
				<div className="social">
					<ul>
						<li>
							<a href="https://www.facebook.com/VollrathCo/" target="_blank">
								<svg class="icon icon-facebook-square"><use xlinkHref="#icon-facebook-square"></use></svg>
							<span class="sr-only">Facebook</span>
                        </a>
						</li>
						<li>
							<a href="https://twitter.com/VollrathCo" target="_blank">
								<svg class="icon icon-twitter-square"><use xlinkHref="#icon-twitter-square"></use></svg>
							<span class="sr-only">Twitter</span>
							</a>
						</li>
					<li>
							<a href="https://www.linkedin.com/showcase/vollrath-foodservice/" target="_blank">
							<svg class="icon icon-linkedin-square"><use xlinkHref="#icon-linkedin-square"></use></svg>
						<span class="sr-only">LinkedIn</span>
							</a>
						</li>
				<li>
							<a href="https://www.instagram.com/vollrathco" target="_blank">
						<svg class="icon" viewBox="0 0 60 61">
							<defs>
								<rect id="a" width="60" height="59" rx="7" />
								<path id="c" d="M0 .595h62v62H0z" />
								<path id="e" d="M0 60.405h62v-62H0z" />
							</defs>
							<g fill-rule="evenodd">
								<mask id="b" fill="#fff">
									<use xlinkHref="#a" />
										</mask>
								<path d="M50 44.868C50 47.692 47.69 50 44.867 50H15.136C12.31 50 10 47.692 10 44.868V15.135C10 12.31 12.311 10 15.136 10h29.73C47.69 10 50 12.31 50 15.135v29.733zM0 60h61V-1H0v61z" mask="url(#b)" />
								<path d="M42.998 29.936c0 6.836-5.563 12.381-12.423 12.381-6.858 0-12.421-5.545-12.421-12.381 0-1.229.181-2.41.513-3.53H15v17.368c0 .9.713 1.631 1.587 1.631h27.826c.874 0 1.587-.731 1.587-1.63v-17.37h-3.516c.333 1.121.514 2.302.514 3.53" mask="url(#b)" />
								<path d="M31 37.405a8 8 0 1 0 0-16.001 8 8 0 0 0 0 16.001M39.774 22.405h4.452c.976 0 1.774-.82 1.774-1.821v-4.355c0-1.002-.798-1.824-1.774-1.824h-4.452c-.977 0-1.774.822-1.774 1.824v4.355c0 1.002.797 1.821 1.774 1.821" mask="url(#b)" />
								<g mask="url(#b)">
									<g transform="translate(0 -1.595)">
										<mask id="d" fill="#fff">
											<use xlinkHref="#c" />
												</mask>
										<path fill="#221E1F" d="M0 .595v62h.062V.656h61.876v61.877H.062v.062H62v-62z" mask="url(#d)" />
									</g>
								</g>
								<path fill="#221E1F" d="M.062-1.533h61.875v61.877H.062v.061H62v-62H0v61.939h.062z" mask="url(#b)" />
								<mask id="f" fill="#fff">
									<use xlinkHref="#e" />
										</mask>
								<path fill="#221E1F" d="M0 60.48h.075v-.075H0z" mask="url(#f)" />
							</g>
						</svg>
						<span class="sr-only">Instagram</span>
					</a>
				</li>
				<li>
							<a href="https://www.youtube.com/channel/UC0aaXhUm5R_9Dxu4dY6rADw" target="_blank">
						<svg class="icon icon-youtube" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<path d="M10 9.333l5.333 2.662-5.333 2.672v-5.334zm14-9.333v24h-24v-24h24zm-4 12c-.02-4.123-.323-5.7-2.923-5.877-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.924 5.877.02 4.123.323 5.7 2.923 5.877 2.399.163 7.75.164 10.153 0 2.598-.177 2.904-1.747 2.924-5.877z" />
						</svg>
					</a>
				</li>
                </ul>
				</div>
			</div>
		</div>
	)
}
