
//Site Specific JS
import LidSaverConfigurator from './components/lid-saver-configurator';
import wwConfigurator from './components/ww-configurator';
import Dashboard from './components/dashboard';
import AB1200 from './components/ab-1200-app';
import ProjectBuilderReceivedQuote from './components/project-builder-received-quote';

new LidSaverConfigurator();
new wwConfigurator();
new ProjectBuilderReceivedQuote();
new Dashboard();
new AB1200();
