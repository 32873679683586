import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import SearchMenuBase from '../../../../shared/react/components/search-menu-base.jsx';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import AddRemoveProductsButton from './add-remove-products-button.jsx';
import AddRemoveDocumentsButton from './add-remove-documents-button.jsx';
import SearchForm from './search-form.jsx';

class SearchMenuContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);

    }

    handleCategoryClick(e) {
        this.setState({
            category: e.target.dataset.name
        });
    }

    handleDocumentClick(e) {
        if (this.searchInput.current.contains(e.target)) {
            return;
        }

        this.setState({
            isOpen: false,
        });
    }

    renderForm() {
        let loader,
            noResults,
            content;

        if (this.state.isLoading) {
            loader = <LoaderSection className="short" show={true} />;
        }

        if (!this.state.isLoading &&
            this.state.productItems.length === 0 &&
            this.state.keywordItems.length === 0 &&
            this.state.documentItems.length === 0 &&
            this.state.q) {
            noResults = <p className="search-drawer-no-results">No results found</p>;
        }

        if (this.state.q.length) {
            content = (
                <div className="search-drawer-content" >
                    {loader}
                    {noResults}
                    {
                        this.state.productItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Products</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.productItems.map((item, index) => (
                                        <li key={item.nodeId}>
                                            <a href={item.pageUrl} target="_blank">{item.skuNumber} - {item.documentName}</a>
                                            <AddRemoveProductsButton productNodeId={item.nodeId} />
                                        </li>
                                    ))}
                                    {this.state.productItems.length === 5 &&
                                        <li><button data-name="products" className="search-drawer-all-btn" onClick={this.handleCategoryClick}>View All &gt;</button></li>
                                    }
                                </ul>
                            </div>
                        </section>
                    }
                    {
                        this.state.keywordItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Categories</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.keywordItems.map((item, index) => (
                                        <li key={item.nodeId}>
                                            <a href={item.pageUrl} target="_blank">{item.documentName}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                    {
                        this.state.documentItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Documents</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.documentItems.map((item, index) => (
                                        <li key={item.nodeId}>
                                            <a href={`/downloads/${item.localSlug}`} target="_blank">{item.displayName}</a>
                                            <AddRemoveDocumentsButton itemSlug={item.localSlug} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                    {
                        this.state.videoItems?.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Videos</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.videoItems.map((item, index) => (
                                        <li key={item.nodeId}>
                                            <a href={`${item.url}`} target="_blank">{item.title}</a>
                                            <AddRemoveDocumentsButton videoNodeId={item.nodeId} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                </div>
            );
        }

        const form = (
            <div className="search-form-container">
                <SearchForm
                    content={content}
                    q={this.state.q}
                    searchInput={this.searchInput}
                    handleChange={this.handleChange}
                    inputValue={this.state.category}
                />
            </div>
        );

        return form;

    }

    render() {
        return this.renderForm();
    }
}

export default SearchMenuContainer;