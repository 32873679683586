import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useUpdateProduct } from '../../../components/project-builder-custom-hooks';
import { getProject } from '../../../apis/project-builder';
import Checkbox from '@mui/material/Checkbox';
//import Select from 'react-select';
import toast from '../../../../shared/components/toast';
import NotesModal from './notes-modal.jsx';
import DiscountModal from './discount-modal.jsx';
import AttachmentsModal from './attachments-modal.jsx';
import AccessoriesModal from './accessories-modal.jsx';
import DeleteModal from './delete-modal.jsx';

export default function ProductCard({ isSelectable, isFullWidth, reviewList, readOnly, selectedProductIds, setSelectedProductIds, product, parentId, sortType, isAccessory }) {

    const productId = product.id

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const { mutate: updateProduct, isLoading } = useUpdateProduct(currentProjectId);

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [quantity, setQuantity] = useState(product.Quantity);

    const handleCheckboxChange = (e) => {
        //const id = e.target.getAttribute('data-id');
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedProductIds(selectedProductIds.concat(productId))
        } else {
            setSelectedProductIds(selectedProductIds.filter(x => x !== productId))
        }
    }

    const handleSortOrderValueChange = (event) => {
        let value = event.target.value;
        //if (value < 1) {
        //    toast.error("Product quantity must be at least 1");
        //    return;
        //};

        if (value == product.sortOrder) {
            return;
        }

        updateProduct({
            id: productId,
            sortOrder: value,
        })
    }

    const handleQuantityChange = (event) => {
        let value = event.target.value;
        if (value < 1) {
            toast.error("Sort value must be at least 1");
            return;
        };

        if (value == product.quantity) {
            return;
        }

        updateProduct({
            id: productId,
            quantity: value,
        })

    }

    let discount = "";

    switch (product.discountType) {
        case "FiftyTen":
            discount = ("50/10 Discount");
            break;
        case "FiftyTenOpen":
            discount = ("50/10/" + product.discountValue + " Discount");
            break;
        case "CustomPercentage":
            discount = (product.discountValue + "% Discount");
            break;
        case "CustomDollarAmount":
            discount = ("$" + product.discountValue + " Discount");
            break;
        default:
            discount = ("None");
            break;
    }

    const openDeleteModal = () => setIsDeleteOpen(true);
    const closeDeleteModal = () => setIsDeleteOpen(false);

    return (
        <>
            <div className={`product-card rounded-border ${isFullWidth ? "product-card--fullWidth" : ""} ${isAccessory ? "product-card--accessory" : null}`}>
                {isAccessory && <div className="product-card-tag">Accessory - {parentId}</div>}
                {isSelectable &&
                    <Checkbox
                        key={productId}
                        className="item-select"
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={selectedProductIds?.includes(productId)}
                        inputProps={{
                            'aria-label': 'Product',
                        }}
                    />
                }

                {(sortType == "Sequence" || sortType == "ProductFamilyGrouping" || sortType == "CustomGrouping") && !readOnly && !isAccessory &&
                    <div className="input-quantity-container sort-value-container">
                        <input className="input-quantity sort-value" type="number" min="0" defaultValue={product.SortOrder} onBlur={handleSortOrderValueChange} />
                    </div>
                }
                <div className="product-card-media">
                    <img src={product.imageUrl ? product.imageUrl : "https://embed.widencdn.net/img/vollrath/j8hhfhfgqr/x300px@2x/PRO_Photo_Missing_glm_11_22.jpeg?q=80"} alt={product.Title} />
                </div>
                <div className="product-card-content">
                    <div className="product-card-sku">{product.sku}</div>
                    <a href={product.url} className="product-card-title" target="_blank">{product.title}</a>

                    <div className="product-card-attributes">

                        {data.isMarketingProject
                            ? null
                            : <div className="product-card-price">
                                <span className="product-card-price-label">List Price</span>
                                <div>
                                    {product.itemDiscount ? (
                                        <>
                                            <span>${product.netPrice.toFixed(2)} </span>
                                            <span className="product-card-price--original">${product.listPrice.toFixed(2)}</span>
                                        </>
                                    ) : (
                                        <span>${product.listPrice.toFixed(2)}</span>
                                    )
                                    }
                                </div>
                                <div className="product-attribute-discount pdf-view-only">
                                    <span className="product-attribute-label">Discount</span>
                                    <div>{discount}</div>
                                </div>
                            </div>
                        }

                        <div className="product-attribute-net condensed-view-only">
                            <span className="product-attribute-label">Net Total</span>
                            <div>${(product.netPrice * quantity).toFixed(2)}</div>
                        </div>
                        <div className="product-attribute-netEach condensed-view-only">
                            <span className="product-attribute-label">Net Each</span>
                            <div>${product.netPrice.toFixed(2)}</div>
                        </div>
                        <div className="product-attribute-discount condensed-view-only">
                            <span className="product-attribute-label">Discount</span>
                            <div>{discount}</div>
                        </div>
                        <div className="product-attribute-discount condensed-view-only">
                            <span className="product-attribute-label">List Each</span>
                            <div>${product.listPrice.toFixed(2)}</div>
                        </div>

                        {!readOnly
                            ? <>
                                {!data.isMarketingProject &&
                                    <DiscountModal
                                        productId={[productId]}
                                        discountType={product.discountType}
                                        discountValue={product.discountValue}
                                    />
                                }
                                <button className="btn--delete" type="button" onClick={openDeleteModal}>
                                    <span className="icon icon--trash"></span>
                                    <DeleteModal isDeleteOpen={isDeleteOpen} closeDeleteModal={closeDeleteModal} product={product} />
                                </button>
                                {!data.isMarketingProject &&
                                    <div className="input-quantity-container">
                                        <input className="input-quantity" type="number" min="1" defaultValue={quantity} onBlur={handleQuantityChange} />
                                    </div>
                                }

                                {/* <Select
                                    classNamePrefix="select--quantity styled-select--widthAuto styled-select"
                                    options={quantityOptions}
                                    value={quantityOptions.find(({ value }) => value === quantity)}
                                    onChange={handleQuantityChange}
                                />*/}
                            </>
                            : data.isMarketingProject ? null : <div className="ml-auto rounded-border quantity">Qty: ({quantity})</div>
                        }
                    </div>

                    <div className="product-card-actions">
                        <AttachmentsModal attachments={product.attachments} productNodeId={product.productNodeId} readOnly={readOnly} />
                        <span></span>
                        {!isAccessory &&
                            <>
                                <AccessoriesModal accessories={product.accessories} productNodeId={product.productNodeId} parentProductId={product.id} readOnly={readOnly} />
                            </>
                        }
                        <NotesModal readOnly={readOnly} product={product} />
                    </div>
                </div>
            </div>

        </>

    );
}