import React from 'react'

export default function tabs(props) {
	const steps = [
		// Hide currency selection step for now
		/*{
			id: 1,
			text: 'Currency'
		},*/
		{
			//id: 2,
			id: 1,
			text: 'Dispenser Type'
		},
		{
			//id: 3,
			id: 2,
			text: 'Layout'
		},
		{
			//id: 4,
			id: 3,
			text: 'Modules'
		},
		{
			//id: 5,
			id: 4,
			text: 'Results'
		}
	]

	return (
		<div className="lidSaverTabs">
			<ul>
				{steps.map(step => (
					<li key={step.id} className={props.currentStep === (step.id + 1) ? 'selected' : ''}>
						<span className="number">{step.id}</span>
						<span className="text">{step.text}</span>
						<span className="arrow"></span>
					</li>
				))}
			</ul>
		</div>
	)
}
