import React from 'react'

export default function Layout({ layoutGroup, selectedLayout, onChange }) {

	return (
		<ul>
			{layoutGroup.layouts.map((layout, k) => (
				<li key={k}>
					<label>
						<input
							type="radio"
							name="dispenserLayout"
							value={layout.displayName}
							onChange={() => onChange(layout)}
							checked={selectedLayout.displayName === layout.displayName ? "checked" : ""}
						/>
						{layout.displayName}
					</label>
				</li>
			))}
		</ul>
	)
}
