import "preact/debug";
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../react/components/ww-configurator/WWConfigurator.jsx';


export default class wwConfigurator {
    constructor() {
        this.container = document.querySelector('#wwConfigurator');

        if (this.container) {
            ReactDOM.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode >,
                document.getElementById('wwConfigurator')
            );
        }


    }
}

