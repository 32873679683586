import React, { useState } from 'react';
import Modal from 'react-modal';
import { useEditProject } from "../../../components/project-builder-custom-hooks";


export default function ArchiveModal({ isArchiveOpen, closeArchiveModal, projectId }) {

    Modal.setAppElement('#main');

    const { mutate: editProject, isLoading } = useEditProject(projectId);

    const handleArchiveProject = () => {
        let projectData = {
            id: projectId,
            status: "Archived"
        };

        editProject(projectData, {
            onSuccess: () => {
                closeArchiveModal();
            }
        });
    }

    return (
        <>
            <Modal
                isOpen={isArchiveOpen}
                onRequestClose={closeArchiveModal}
                className="project-builder project-builder-modal project-builder-modal--xs text-center"
            >
                <button className="project-builder-modal-close" onClick={closeArchiveModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container">
                    <div className='modal-title mb-2'>Are you sure you want to archive &lsquo;Project Name&rsquo;?</div>
                    <button type="button" className="btn no-arrow mb-1" disabled={isLoading} onClick={handleArchiveProject}>yes, archive project</button>
                    <div>
                        <button className="link-btn" disabled={isLoading}  onClick={closeArchiveModal}>No, Keep Current Project Status</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}