import React from 'react';
//import { useQuery } from '@tanstack/react-query';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
//import { getProject } from '../../../apis/project-builder';

export default function ProductsListByFamilyPdfView({ index, product, parentId, isAccessory, isMarketingProject }) {

    //const { currentProjectId } = useCurrentProjectIdContext();

    //const { data, isSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    let discount = "";

    switch (product.discountType) {
        case "FiftyTen":
            discount = ("50/10 Discount");
            break;
        case "FiftyTenOpen":
            discount = ("50/10/" + product.discountValue + " Discount");
            break;
        case "CustomPercentage":
            discount = (product.discountValue + "% Discount");
            break;
        case "CustomDollarAmount":
            discount = ("$" + product.discountValue + " Discount");
            break;
        default:
            discount = ("None");
            break;
    }

    return (
        <>
            <div className="product-card pdf-view-only" key={index}>
                {isAccessory && <div className="product-card-tag">Accessory - {parentId}</div>}

                <div className="product-card-media">
                    <img src={product.imageUrl ? product.imageUrl : "https://embed.widencdn.net/img/vollrath/j8hhfhfgqr/x300px@2x/PRO_Photo_Missing_glm_11_22.jpeg?q=80"} alt={product.Title} />
                </div>
                <div className="product-card-content">
                    <div className="product-card-sku">{product.sku}</div>
                    <a href={product.url} className="product-card-title" target="_blank">{product.title}</a>

                    <div className="product-card-attributes">

                        {isMarketingProject
                            ? null
                            : <div className="product-card-price">
                                <span className="product-card-price-label">List Price</span>
                                <div>
                                    {product.itemDiscount ? (
                                        <>
                                            <span>${product.netPrice.toFixed(2)} </span>
                                            <span className="product-card-price--original">${product.listPrice.toFixed(2)}</span>
                                        </>
                                    ) : (
                                        <span>${product.listPrice.toFixed(2)}</span>
                                    )
                                    }
                                </div>
                                <div className="product-attribute-discount">
                                    <span className="product-attribute-label">Discount</span>
                                    <div>{discount}</div>
                                </div>

                            </div>
                        }

                        <div className="product-attribute-net condensed-view-only">
                            <span className="product-attribute-label">Net Total</span>
                            <div>${(product.netPrice * product.quantity).toFixed(2)}</div>
                        </div>
                        <div className="product-attribute-netEach condensed-view-only">
                            <span className="product-attribute-label">Net Each</span>
                            <div>${product.netPrice.toFixed(2)}</div>
                        </div>
                        <div className="product-attribute-discount condensed-view-only">
                            <span className="product-attribute-label">Discount</span>
                            <div>{discount}</div>
                        </div>
                        <div className="product-attribute-discount condensed-view-only">
                            <span className="product-attribute-label">List Each</span>
                            <div>${product.listPrice.toFixed(2)}</div>
                        </div>

                        <div className="ml-auto rounded-border quantity">Qty: ({product.quantity})</div>
                    </div>
                </div>
            </div>

        </>

    );
}