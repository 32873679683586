import React from "react";
import {
    NavLink
} from "react-router-dom";

const DashboardNav = props => {

    return (
        <div className="header-primary-wrapper">
            <div className="header-primary portal">
                <div className="container">
                    <div className="header-logo-col">
                        <a className="header-logo campaign" href="/">
                            <svg width="200" height="60" viewBox="0 0 200 60"><use xlinkHref="#logo"></use></svg>
                            <img src="/img/logo-nav.svg" width="136" alt="" style={{ display: "none" }} />
                            <span className="sr-only">The Vollrath Company, LLC</span>
                        </a>
                    </div>

                    <nav className="header-secondary-second-col header-nav" aria-label="Utility">
                        <ul className="header-secondary-ul js-secondary-nav">
                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/`}>
                                    Home
                                </NavLink>
                            </li>

                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/my-vollrath-university`}>
                                    My Vollrath University
                                </NavLink>
                            </li>

                            {props.role === "Sales" &&
                                <li className="header-secondary-li">
                                    <NavLink className="portal" to={`/project-builder`}>
                                        Project Builder
                                    </NavLink>
                                </li>

                            }


                            <li className="header-secondary-li">
                                <NavLink className="portal" to={`/notifications`}>
                                    New Content ({props.notifications.length})
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <ul className="header-secondary-third-col js-account-options header-nav">
                        <li className="header-secondary-li">
                            <a className="has-dropdown account-heading add-account-margin">
                                <span className="account-name">Hi, {props.contact.FirstName}</span>
                                <span className=""><span className="your-account">Your</span> Account</span>
                            </a>

                            <ul class="menu-dropdown small">
                                <li>
                                    <NavLink to={`/profile`}>
                                        My Profile
                                    </NavLink>
                                    <a className="logout-nav js-logout">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default DashboardNav;