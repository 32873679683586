import React from 'react';
import Select from 'react-select';


export default function ProjectFormFields({ formData, setFormData, readOnly }) {

    const projectFormatOptions = [
        { value: false, label: "Quote Project"},
        { value: true, label: "Sales Project"}
    ];

    const projectStatusOptions = [
        { value: "InProgress", label: "In Progress" },
        { value: "Outgoing", label: "Outgoing" },
        { value: "Incoming", label: "Incoming" },
        { value: "Completed", label: "Completed" },
        { value: "Archived", label: "Archived" },
        { value: "None", label: "None" },
    ];

    const projectTypeOptions = [
        { value: "Independent Restaurant", label: "Independent Restaurant" },
        { value: "Chain Restaurant", label: "Chain Restaurant" },
        { value: "Grocery", label: "Grocery" },
        { value: "C-Store", label: "C-Store" },
        { value: "Stadium/Arena", label: "Stadium/Arena" },
        { value: "Zoo/Theater/Amusement Park", label: "Zoo/Theater/Amusement Park" },
        { value: "Golf Course", label: "Golf Course" },
        { value: "Hotel/Casino", label: "Hotel/Casino" },
        { value: "Airport", label: "Airport" },
        { value: "Convention Center", label: "Convention Center" },
        { value: "K-12", label: "K-12" },
        { value: "College/University", label: "College/University" },
        { value: "Hospital", label: "Hospital" },
        { value: "Assisted Living", label: "Assisted Living" },
        { value: "Government/Military", label: "Government/Military" },
        { value: "Religious Organization", label: "Religious Organization" },
        { value: "Business/Industry", label: "Business/Industry" },
        { value: "Ice Cream/Frozen Yogurt Shop", label: "Ice Cream/Frozen Yogurt Shop" },
        { value: "Distributor", label: "Distributor" },
        { value: "Other", label: "Other" },
    ];

    const handleChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelectChange = (value, action) => {
        setFormData(prevState => ({
            ...prevState,
            [action.name]: value
        }));
    }

    return (
        <>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="projectName">Project Name *</label>
                    <input className="form-control" type="text" id="projectName" name="projectName" required value={formData.projectName} disabled={readOnly} onChange={handleChange} />
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="id">Project ID</label>
                    <input className="form-control" type="text" id="projectId" name="id" placeholder="Auto Generated" disabled value={formData.id} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="accountName">Account Name</label>
                    <input className="form-control" type="text" id="accountName" name="accountName" value={formData.accountName} disabled={readOnly} onChange={handleChange} />
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="zipcode">Zipcode *</label>
                    <input className="form-control" type="text" id="zipCode" name="zipCode" required value={formData.zipCode} disabled={readOnly} onChange={handleChange} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="phoneNumber">phone number <span>(customer)</span></label>
                    <input className="form-control" type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} disabled={readOnly} onChange={handleChange} />
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="emailAddress">Email address <span>(customer)</span></label>
                    <input className="form-control" type="email" id="emailAddress" name="emailAddress" value={formData.emailAddress} disabled={readOnly} onChange={handleChange} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="status">project status</label>
                    <Select
                        classNamePrefix="styled-select"
                        name="status"
                        id="status"
                        options={projectStatusOptions}
                        value={formData.status}
                        isDisabled={readOnly}
                        isSearchable={false}
                        onChange={handleSelectChange}
                    />
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="type">project type</label>
                    <Select
                        classNamePrefix="styled-select"
                        name="type"
                        id="type"
                        options={projectTypeOptions}
                        value={formData.type}
                        isDisabled={readOnly}
                        isSearchable={false}
                        onChange={handleSelectChange}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="isMarketingProject">project format</label>
                    <Select
                        classNamePrefix="styled-select"
                        name="isMarketingProject"
                        id="isMarketingProject"
                        options={projectFormatOptions}
                        value={formData.isMarketingProject}
                        isDisabled={readOnly}
                        isSearchable={false}
                        onChange={handleSelectChange}
                    />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="notes">Project Header</label>
                <textarea className="form-control" id="project-header" name="headerText" rows="6"
                    value={formData.headerText}
                    disabled={readOnly}
                    onChange={handleChange}>
                </textarea>
            </div>

            <div className="form-group">
                <label htmlFor="project-header">Project Notes</label>
                <textarea className="form-control" id="notes" name="notes" rows="6"
                    value={formData.notes} disabled={readOnly} onChange={handleChange}></textarea>
            </div>
        </>
    );
}