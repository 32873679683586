import React, { useState, useEffect } from 'react'

export default function ModelOption({ type, selectedManu }) {
	const [modelOptions, setModelOptions] = useState([]);
	//const [isLoaded, setIsLoaded] = useState(false);

	let glassType = "";

	(type === 1) ? glassType = "Glass" : glassType = "MugCup";

	//Fetch model data
	const requestOptions = {
		method: 'GET',
		//method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			//body: JSON.stringify(selectedManu);
		}
	};

	let apiUrl = '/api/WarewashingConfiguration/GetGlasswareModels?manufacturerName=' + selectedManu + '&glassType=' + glassType;

	useEffect(() => {
		fetch(apiUrl, requestOptions)
			.then(response => response.json())
			.then(result => {
				//console.log(result);
				setModelOptions(result);
			});
	}, [selectedManu]);

	return (
		<>
			{modelOptions.map((option, index) => (
				<option
					key={index}
					value={option.name}
					din={(option.diameter == null) ? "N/A" : option.diameter.inches}
					dcm={(option.diameter == null) ? "N/A" : option.diameter.centimeters}
					hin={(option.height == null) ? "N/A" : option.height.inches}
					hcm={(option.height == null) ? "N/A" : option.height.centimeters}
					diagin={(type === 1 || option.diagonal == null) ? "N/A" : option.diagonal.inches}
					diagcm={(type === 1 || option.diagonal == null) ? "N/A" : option.diagonal.centimeters}
				>
					{option.name}
				</option>
			))}
		</>
	)
}
