import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';


export default function Breadcrumbs() {

    const location = useLocation();
    const path = location.pathname.split(/[/]/);

    return (
        <div className="row">
            <div className="col-12">
                <nav aria-label="Breadcrumbs">
                    <ul class="breadcrumbs">
                        {
                            path.map((p) => <li><NavLink to={(p === "project-builder") ? "/project-builder" : "/project-builder/" + p}>{p.replace("-", " ")}</NavLink></li>).slice(0, path.length - 1)
                        }
                        {path.map((p) => <li className="selected"><span>{p.replace("-", " ")}</span></li>).slice(path.length - 1)}
                    </ul>
                </nav>
            </div>
        </div>
    );
}