import jfetch from '@jontetz/jfetch';

export function getCurrentContact(params) {
    return jfetch.get('/api/dashboard/getcurrentcontact', params);
}

export function updateCurrentContact(params) {
    return jfetch.post('/api/dashboard/updatecurrentcontact', params);
}

export function getQuizzesForContact(params) {
    return jfetch.get('/api/elearning/getquizzesforcontact', params);
}

export function generateCertificate(params) {
    return jfetch.post('/api/elearning/generatecertificate', params, { responseType: 'arraybuffer'});
}


export function getEventsForContact(params) {
    return jfetch.get('/api/elearning/geteventsforcontact', params);
}


export function updateModuleOrder(params) {
    return jfetch.post('/api/dashboard/updatemoduleorder', params);
}

export function getEventsWithCodes(params) {
    return jfetch.get('/api/dashboard/geteventswithcodes', params);
}



export function updateEventData(params) {
    return jfetch.post('/api/dashboard/updateeventdata', params);
}

export function exportData(params) {
    return jfetch.get('/api/dashboard/exporteventdata', params, { responseType: 'arraybuffer' });
}

export function canContactExport() {
    return jfetch.get('/api/dashboard/cancontactexport');
}

export function sendNotificationEmail(params) {
    return jfetch.post('/api/dashboard/sendnotificationemail', params);
}