import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useQuery } from '@tanstack/react-query';
import { getRecommendedProductAccessories, getProject } from '../../../apis/project-builder';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/base/Popper';
import AccessoriesListItem from './accessories-list-item.jsx';

export default function AccessoriesModal({ accessories, productNodeId, parentProductId, readOnly }) {

    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();

    const { data: recommendedAccessoriesData, isSuccess, isLoading, isError, error } = useQuery(['product', productNodeId, 'recommendedProductAccessories'], () => getRecommendedProductAccessories(productNodeId));

    //const { data: projectData, isSuccess: projectDataIsSuccess } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'attachments-modal' : undefined;

    return (
        <>
            {isSuccess &&
                <>
                    {((!readOnly || (readOnly && accessories?.length)) && recommendedAccessoriesData.length > 0)
                    ? <>
                        <button className="link-btn underline popper-trigger" aria-describedby={id} variant="contained" open={anchorEl ? true : false} onClick={handleClick}>
                            Accessories {accessories?.length ? "(" + accessories.length + ")" : null}
                        </button>
                        <span></span>
                        </>
                        : null
                    }

                {recommendedAccessoriesData.length > 0 && 
                    <ClickAwayListener onClickAway={handleClose}>
                        <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            placement="top"
                        >
                            <div className="project-builder">
                                <div className="popper popper--attachments rounded-border">
                                    <button className="project-builder-modal-close" onClick={handleClose}>
                                        <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                                    </button>
                                    <div className="popper-title">{readOnly ? "Added" : "Recommended"} Accessories</div>
                                    <ul className="attachments-list attachments-list--paddingNone">
                                        {readOnly ?
                                            accessories?.map((accessory, index) => (
                                                <AccessoriesListItem accessory={accessory} key={index} readOnly={readOnly} />
                                            ))
                                        : recommendedAccessoriesData.map((accessory, index) => (
                                            <AccessoriesListItem isAdded={accessories.find((item) => item.productNodeId == accessory.productNodeId)} accessory={accessory} parentProductId={parentProductId} key={index} readOnly={readOnly} />
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Popper>
                    </ClickAwayListener>
                }
                </>
            }
            {isError && "error getting attachments"}
        </>
    );
}