import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';


export default function SearchForm({ content, q, searchInput, handleChange, inputValue  }) {

    const [showContent, setShowContent] = useState(false);

    const navigate = useNavigate();

    const searchForm = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/project-builder/search?q=${q}&search=true`);
    }

    const handleDocumentClick = (e) => {
        searchForm.current?.contains(e.target) ? setShowContent(true) : setShowContent(false);
    }

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, false);

        return () => {
            document.removeEventListener('click', this.handleDocumentClick, false);
        };
    }, []);

    return (
        <form className="search-form" method="get" ref={searchForm} onSubmit={handleSubmit}>
            <div className="search-drawer-input-container">
                <label className="sr-only" htmlFor="search-field">Search</label>
                <input ref={searchInput} className="search-drawer-input" id="search-field" type="text" name="q" placeholder="Search..." onChange={handleChange} required autoCapitalize="none" autoCorrect="off" />
                <input type="hidden" name="category" value={inputValue} />
                <button className="search-drawer-btn">
                    <svg className="icon" width="40" height="40"><use xlinkHref="#icon-search"></use></svg>
                    <span className="sr-only">Search</span>
                </button>
            </div>
            {showContent && content}
        </form>
    );
}