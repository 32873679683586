import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { sendQuote, getProject } from '../../../apis/project-builder';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useEditProject } from "../../../components/project-builder-custom-hooks";
import Modal from 'react-modal';
import toast from '../../../../shared/components/toast';
import EditProjectModal from './edit-project-modal.jsx';


export default function SendQuoteConfirmationModal() {

    Modal.setAppElement('#main');

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));
    const { mutate: editProject, isLoading: editIsLoading } = useEditProject(currentProjectId);

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const copyToClipboard = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url.split("/")[0] + "//" + url.split("/")[2] + "/projects?projectGuid=" + data.guid);
        toast.success("Project URL copied to clipboard");
    }

    const handleMessageChange = (e) => {
        let value = e.target.value;
        let payload = {
            id: data.id,
            emailMessage: value
        }
        editProject(payload, {
            //onSuccess: () => {

            //}
        });
    }

    const handleSendQuote = (e) => {
        e.preventDefault();
        sendQuote(currentProjectId);
        navigate(`/project-builder/${currentProjectId}/received-quote`);
    }

    return (
        <>
            <button class="btn no-arrow" type="button" onClick={openModal}>{data.isMarketingProject ? "Send Project" : "Send Quote"}</button>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="project-builder project-builder-modal project-builder-modal--xs"
            >
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div>
                    <div className='modal-title'>{data.isMarketingProject ? "Send Project" : "Send Quote"}</div>
                    <p>Please confirm all information before sending quote.</p>

                    <div className="quote-confirmation-content">
                        <div className="quote-details">
                            <ul>
                                <li>Account Name: <span>{data.accountName}</span></li>
                                <li>Phone Number: <span>{data.contactPhoneNumber}</span></li>
                                <li>Email Address: <span>{data.contactEmailAddress}</span></li>
                                <li>Zip Code: <span>{data.zipCode}</span></li>
                            </ul>
                        </div>
                        <EditProjectModal
                            formType="edit"
                            openButtonText="Edit"
                            buttonClass="link-btn underline"
                        />
                    </div>

                    <div className="form-group quote-confirmation">
                        <label htmlFor="notes">Email Message</label>
                        <textarea className="form-control" id="message" name="message" rows="5" value={data.emailMessage} placeholder="" onBlur={handleMessageChange}></textarea>
                    </div>

                    <div className="quote-confirmation-actions">
                        <button className="btn btn-block no-arrow" type="button" onClick={handleSendQuote}>{data.isMarketingProject ? "Send Project" : "Send Quote"}</button>
                        <button className="link-btn" type="button" onClick={copyToClipboard}>Copy Link URL</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}