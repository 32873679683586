import React, { useState, useEffect } from 'react';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
import LoaderSection from '../../../../shared/react/components/loader-section.jsx';
import ProductsFamilyGroup from './products-family-group.jsx';
import ProductCard from './product-card.jsx';

export default function ProductsListByFamily({ selectedProductIds, setSelectedProductIds, handleAddProduct, expandAll, emptyGroups, reviewList, readOnly }) {

    const { currentProjectId } = useCurrentProjectIdContext();

    const { data, isSuccess, isLoading, isError, error } = useQuery(['project-detail', currentProjectId], () => getProject(currentProjectId));

    const [families, setFamilies] = useState([]);

    useEffect(() => {
        let newFamilies = [];

        if (isSuccess) {
            data.products.forEach((product) => {
                newFamilies.includes(product.family) ? null : newFamilies.push(product.family);
            })
            setFamilies(newFamilies);
        };

    }, [data, emptyGroups]);

    let cardCount = 0;

    const getColSpan = () => {
        cardCount = cardCount + 1;
        return (cardCount % 3 === 0 || reviewList) ? "12" : "6";
    }

    const isFullWidth = () => {
        return (reviewList ? true : (cardCount % 3 === 0) ? true : false);
    }

    return (
        <>
            {isLoading && <LoaderSection show={true} />}
            {isError && <h3>There was an error loading the products</h3>}

            {isSuccess &&
                <>

                    {emptyGroups?.length > 0 &&
                        emptyGroups.map((group, index) => (
                            <ProductsFamilyGroup
                                selectedProductIds={selectedProductIds}
                                setSelectedProductIds={setSelectedProductIds}
                                family={group.name}
                                index={index}
                                families={families}
                                setFamilies={setFamilies}
                                expandAll={expandAll}
                                reviewList={reviewList}
                                readOnly={readOnly}
                            />
                        ))
                    }

                    {(data.sortType == "CustomGrouping" && data.productGroups.length > 0) &&
                        <>
                            {data.productGroups.map((group, index) => (
                                <ProductsFamilyGroup
                                    selectedProductIds={selectedProductIds}
                                    setSelectedProductIds={setSelectedProductIds}
                                    family={group.name}
                                    index={index}
                                    families={families}
                                    setFamilies={setFamilies}
                                    expandAll={expandAll}
                                    reviewList={reviewList}
                                    readOnly={readOnly}
                                    groupId={group.id}
                                    groupSortOrder={group.sortOrder}
                                />
                            ))}
                            <hr />
                        </>
                    }

                    {data.sortType == "ProductFamilyGrouping" &&
                        families.map((family, index) => (
                            <ProductsFamilyGroup
                                selectedProductIds={selectedProductIds}
                                setSelectedProductIds={setSelectedProductIds}
                                family={family}
                                index={index}
                                families={families}
                                setFamilies={setFamilies}
                                expandAll={expandAll}
                                reviewList={reviewList}
                                readOnly={readOnly}
                            />
                        ))
                    }

                    {data.sortType == "CustomGrouping" &&
                        <>
                            <div className="row print-my-0">
                                {data.products.filter((product) => product.productGroupId < 1).map((product, index) => (
                                    <>
                                        <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                            <ProductCard
                                                isSelectable={!reviewList}
                                                isFullWidth={isFullWidth()}
                                                reviewList={reviewList}
                                                readOnly={readOnly}
                                                key={product.id}
                                                product={product}
                                                selectedProductIds={selectedProductIds}
                                                setSelectedProductIds={setSelectedProductIds}
                                                sortType={data.sortType}
                                            />
                                        </div>
                                        {
                                            product.accessories.map((accessory, accessoryIndex) => (
                                                <div class={`col-md-${getColSpan()} ${reviewList ? "" : "mb-2"}`}>
                                                    <ProductCard
                                                        isSelectable={!reviewList}
                                                        isFullWidth={isFullWidth()}
                                                        reviewList={reviewList}
                                                        readOnly={readOnly}
                                                        key={accessory.id}
                                                        product={accessory}
                                                        parentId={product.sku}
                                                        selectedProductIds={selectedProductIds}
                                                        setSelectedProductIds={setSelectedProductIds}
                                                        sortType={data.sortType}
                                                        isAccessory
                                                    />
                                                </div>
                                            ))
                                        }
                                    </>
                                ))}
                            </div>
                        </>
                    }


                    {reviewList ? null
                        : <div className="row">
                            <div className={`col-md-${getColSpan()} mb-2`}>
                                <div className="product-card product-card--add rounded-border" onClick={handleAddProduct}>
                                    <button className="btn-add" type="button"><span className="plus-icon">+</span></button>
                                    <div className="cta-title">Add Product</div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}