import React, { Component, Suspense, lazy } from "react";
import ReactPaginate from 'react-paginate';
import LoaderSection from '../loader-section.jsx';
import { getUrlParams, generatePaginationHref } from '../../../utils/';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import Filters from '../filters.jsx';
import toast from '../../../components/toast';
import FilteringBase from '../filtering-base';
import ProductBase from '../product-base.jsx';
import animatedScrollTo from 'animated-scroll-to';
import { getProductsForSale } from "../../../apis/custom-content.js"
import { getProductsForSaleFilters, getForSaleAndDiscontinuedCount } from "../../../apis/filters.js"


class SearchProducts extends FilteringBase {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, this.baseState, {
            filters: {},
            pageSize: 12,
            firstSearch: true,
            isLoadingFilters: false,
            q: props.q,
            forSale: false,
            discontinued: false,
            discontinuedCount: 0,
            productsForSaleCount: 0
        });


        this.handleChange = this.handleChange.bind(this);
        this.handleNewPageClick = this.handleNewPageClick.bind(this);
        this.loadDebounce = debounce(this.load.bind(this), 1000);
        this.filterDebounce = debounce(this.setNewFilters.bind(this), 1000);
    }


    handleNewPageClick(data) {
        this.setState({
            page: data.selected + 1
        }, () => {
            animatedScrollTo(document.querySelector('.product-results-top'));
            this.load();
        });
    }


    setFilters() {
        const params = this.getAllFilters();
        return getProductsForSaleFilters(params).then(data => {
            console.log(data);
            data.forEach(c => c.selected = false);

            const orders = data.filter(o => o.category === "Order");
            const family = data.filter(f => f.category === "Family");

            this.setState({
                filters: {
                    Product_Orders: orders,
                    Product_Families: family
                }
            },
                () => {
                    this.updateFilters();
                });
            return data;
        }).catch(err => {
            console.log(err);
            if (err.name === 'AbortError') return err;
        });
    }

    setNewFilters() {
        const params = this.getAllFilters();

        this.setState({
            isLoadingFilters: true
        });

        getProductsForSaleFilters(params).then(data => {
            console.log(data);

            const orders = data.filter(o => o.category === "Order");
            const family = data.filter(f => f.category === "Family");

            this.setState({
                filters: {
                    Product_Orders: orders,
                    Product_Families: family
                }
            },
                () => {
                    this.updateFilters();
                });
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.setState({
                isLoadingFilters: false
            });
        });
    }

    async componentDidMount() {
        this.mounted = true;
        this.setFilters().then((data) => {
            console.log(data);
            const params = getUrlParams();

            if (Object.keys(params).length) {
                const pageNum = params.page && params.page !== '1' ? params.page : 1;
                this.setState({ page: pageNum },
                    () => {
                        this.setNewFilters();
                        this.load();
                        this.getProductsForSaleAndDiscontinuedCount();
                    });
            } else {
                this.setNewFilters();
                this.load();
                this.getProductsForSaleAndDiscontinuedCount();
            }

        }).catch(err => {
            console.log(err);
        });
    }

    getProductsForSaleAndDiscontinuedCount() {

        const params = {
            q: this.state.q
        }

        getForSaleAndDiscontinuedCount(params)
            .then(data => {
                this.setState({
                    productsForSaleCount: data.product,
                    discontinuedCount: data.discontinued
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.q !== this.props.q) {
            this.setState({
                q: this.props.q,
                page: 1
            }, () => {
                this.getProductsForSaleAndDiscontinuedCount();
                this.filterDebounce();
                this.loadDebounce();
            });
        }
    }

    // load products from the api
    load(pushState = true) {
        if (this.ajaxPromise) this.ajaxPromise.controller.abort();

        this.setState({
            isLoading: true,
            items: []
        });

        if (pushState) {
            history.pushState(null, document.title, this.getUrl());
        }

        // put together api parameters
        const params = this.getAllFilters();

        params.pageSize = this.state.pageSize;

        params.search = true;

        // remove empty or undefined params
        Object.keys(params).forEach(key => {
            if (!params[key]) delete params[key];
        });

        this.setState({
            firstSearch: false
        });

        this.ajaxPromise = getProductsForSale(params);

        this.ajaxPromise.then(data => {
            console.log(data);
            this.setState({
                items: data.records,
                totalRecords: data.totalRecords,
                isLoading: false
            });
            return data;
        })
            .catch(error => {
                console.log(error);
                if (error.name === 'AbortError') return error;
                this.setState({
                    isLoading: false
                });
                toast.error('There was an error getting results');
                return error;
            });

        return this.ajaxPromise;
    }


    // set the selected state of the filters to false
    resetFilterData(data) {
        for (const [key, value] of Object.entries(data)) {
            value.forEach(v => v.selected = false);
        }
    }

    // update filters on page using the url parameters
    updateFilters() {
        const params = getUrlParams(),
            filters = Object.assign({}, this.state.filters);

        // reset filters to be unselected
        this.resetFilterData(filters);
        // go through params and update selected filters
        for (const paramKey in params) {
            const values = params[paramKey].split('|');
            const filterSection = Object.entries(filters).find(([key]) => kebabCase(key) === paramKey);
            console.log(filterSection, values);

            if (filterSection) {
                values.forEach(value => {
                    const filter = filterSection[1].find(f => f.id === +value);
                    if (filter) filter.selected = true;
                });
            }
        }

        // update search query
        if (params.q) this.setState({ q: params.q });

        this.setState({
            filters: filters,
            page: params['page'] ? +params['page'] : 1
        });
    }

    // get all the selected filters at this time
    getAllFilters() {

        const params = {
            q: this.state.q,
            page: this.state.page,
            forSale: this.state.forSale,
            discontinued: this.state.discontinued,
            search: true
        };

        for (const [key, value] of Object.entries(this.state.filters)) {
            value.forEach(v => {
                if (v.selected) {
                    const paramKey = kebabCase(key),
                        encodedValue = v.id;
                    if (params[paramKey]) params[paramKey].push(encodedValue);
                    else params[paramKey] = [encodedValue];
                }
            });
        }

        // pipe delimit array values
        Object.keys(params).forEach(key => {
            if (Array.isArray(params[key])) params[key] = params[key].join(',');
        });

        return params;
    }

    // get the url for the current window location
    getUrl() {
        const baseUrl = `${window.location.pathname}`,
            filters = this.getAllFilters(),
            params = [];
        for (const key in filters) {
            const val = filters[key];
            if (((key === 'page' && val > 1) || key !== 'page')) {
                if (val) params.push(`${key}=${encodeURIComponent(val)}`);
            }
        }

        return `${baseUrl}${params.length ? '?' : ''}${params.join('&')}`;
    }

    handleForSaleChange(e) {
        const target = e.target,
            isChecked = target.checked;

        const forSale = isChecked;

        this.setState({
            forSale: forSale,
            page: 1
        }, () => {
            this.setNewFilters();
            this.load();
        });
    }

    handleDiscontinuedChange(e) {
        const target = e.target,
            isChecked = target.checked;

        const discontinued = isChecked;

        this.setState({
            discontinued: discontinued,
            page: 1
        }, () => {
            this.setNewFilters();
            this.load();
        });

    }

    handleChange(e) {
        const target = e.target,
            isChecked = target.checked,
            name = target.name,
            value = +target.value,
            filters = Object.assign({}, this.state.filters);

        Object.keys(filters).forEach(key => {
            const match = filters[key].find(f => f.id === value && key === name);
            if (match) match.selected = isChecked;
        });

        this.setState({
            filters: filters,
            page: 1
        }, () => {
            this.setNewFilters();
            this.load();
        });
    }

    render() {
        let pagination,
            loader,
            results,
            filter,
            filterRow,
            selected,
            count,
            actions;

        if (this.showPagination()) {
            pagination = <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.pages()}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handleNewPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={this.state.page - 1}
                hrefBuilder={generatePaginationHref}
            />;
        }

        filterRow = 'row justify-content-center';

        selected =
            <div className="row">
                {Object.entries(this.state.filters).map(([key, value]) => (
                    <div key={key}>
                        {value.length > 0 &&
                            <div>
                                {value.filter(m => m.selected).map((filter, index) => (
                                    <div className="filter-bullets" key={index}>
                                        <input className="filter-bullets-checkbox" id={`${filter.name}${this.props
                                            .nodeId}`
                                        } type="checkbox" name={key} value={filter.id
                                        } onChange={this.handleChange} disabled={this.state.isLoadingFilters} />
                                        <label htmlFor={`${filter.name}${this.props.nodeId}`}>
                                            <span>{filter.name}<span className="dull">&nbsp;&nbsp;X</span></span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                ))}
            </div>;

        const productsForSaleFilter = !this.state.discontinued
            ? <div className="filter-accordion-content">
                <div>
                    <input className="custom-checkbox square" id={`forSale`} type="checkbox" name="forSale" value={
                        this.state.forSale} onChange={this.handleForSaleChange.bind(this)} disabled={this.state.isLoadingFilters} />
                    <label htmlFor={`forSale`}>
                        <span>Liquidated Products For Sale
                              <span className="dull">({this.state.productsForSaleCount
                            })</span></span>
                    </label>
                </div>
            </div>
            : <></>;

        const discontinuedFilter = !this.state.forSale
            ? <div className="filter-accordion-content">
                <div>
                    <input className="custom-checkbox square" id={`discontinued`
                    } type="checkbox" name="discontinued" value={this.state.discontinued} onChange={this.handleDiscontinuedChange.bind(this)
                    } disabled={this.state.isLoadingFilters} />
                    <label htmlFor={`discontinued`}>
                        <span>Discontinued Products
                              <span className="dull">({this.state.discontinuedCount
                            })</span></span>
                    </label>
                </div>
            </div>
            : <></>;

        filter =
            <div className="col-lg-3">
                <Filters q={this.state.q} showSearch={false}>
                    <div>
                        <details className="filter-accordion" open role="group">
                            <summary className="filter-accordion-header" id={'filter-custom'
                            }><span>Additional Filters</span></summary>
                            {productsForSaleFilter}
                            {discontinuedFilter}
                        </details>
                    </div>
                    {Object.entries(this.state.filters).map(([key, value]) => (
                        <div key={key}>
                            {value.length > 0 &&
                                <details className="filter-accordion" open role="group" aria-labelledby={'filter-' +
                                    kebabCase(key)}>
                                    <summary className="filter-accordion-header" id={'filter-' + kebabCase(key)
                                    }><span>{key.replace('_', ' ')}</span></summary>
                                    <div className="filter-accordion-content">
                                        {value.map((filter, index) => (
                                            <div key={index}>
                                                <input className="custom-checkbox square" id={`${filter.name}${this.props.nodeId}`} type="checkbox" name={key} value={filter.id
                                                } onChange={this.handleChange} checked={filter.selected} disabled={this.state.isLoadingFilters} />
                                                <label htmlFor={`${filter.name}${this.props.nodeId}`}>
                                                    <span>{filter.name}<span className="dull">({filter.count})</span></span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </details>
                            }
                        </div>
                    ))
                    }
                </Filters>
            </div>;

        if (this.state.items.length > 0) {
            if (this.props.quickAdd && (window.location.host == ("localhost:44335") || window.location.hostname.includes("vollrathfoodservice.com"))) {
                var QuickAdd = lazy(() => import("../../../../site-js/react/components/project-builder/quick-add.jsx"));
            }

  

            results = <div className="row product-results-top">
                {this.state.items.map((item, index) => (
                    //QuickAdd component is used on the project builder search
                    <ProductBase key={index} userType={false} colClass={this.props.colClass} actions={this.props.quickAdd ? <Suspense><QuickAdd item={item} index={index} /></Suspense> : ""} {...item} />
                ))}
            </div>;
        }
        else if (!this.state.isLoading) {
            results = <p>No results found</p>;
        }

        count = this.state.isLoading
            ? <></>
            : <p className="search-result-message"><span className="search-accent">{this.state.totalRecords}</span> results found for <span className="search-accent">'{this.state.q}'</span></p>;

        if (this.state.isLoading) loader = <LoaderSection show={true} />;

        return (
            <div>
                {count}
                <div className={filterRow}>
                    {filter}
                    <div id="results" className="col-lg-9">
                        {selected}
                        {loader}
                        {results}
                        {pagination}
                    </div>
                </div>
            </div>

        );
    }
}

SearchProducts.defaultProps = {
    categories: [],
    colClass: 'col-md-4'
};

export default SearchProducts;