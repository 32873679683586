import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import jfetch from '@jontetz/jfetch';
import Modal from 'react-modal';
import ProjectFormFields from './project-form-fields.jsx';


export default function NewProjectModal({ buttonClass, openButtonText }) {
    Modal.setAppElement('#main');

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { currentProjectId, setCurrentProjectId } = useCurrentProjectIdContext();

    const [isOpen, setIsOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [formData, setFormData] = useState({
        projectName: '',
        id: null,
        accountName: '',
        zipCode: '',
        phoneNumber: '',
        emailAddress: '',
        status: { value: "InProgress", label: "In Progress" },
        type: { value: "Independent Restaurant", label: "Independent Restaurant" },
        isMarketingProject: { value: false, label: "Quote Project" },
        notes: '',
    });

    const createProject = useMutation((newProject) => jfetch.post(`/api/projectBuilder/projects`, newProject), {
        onSuccess: (data) => {
            let projectId = data.id
            setCurrentProjectId(projectId);
            queryClient.invalidateQueries(['projects-list']);
            navigate(`/project-builder/${projectId}`);
        }
    });


	const openModal = () => {
		setIsOpen(true);
	}

	const closeModal = () => {
		setIsOpen(false);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        let projectData = {
            name: formData.projectName, 
            status: formData.status.value,
            accountName: formData.accountName,
            contactPhoneNumber: formData.phoneNumber,
            contactEmailAddress: formData.emailAddress,
            zipCode: formData.zipCode,
            freight: 0,
            salesTax: 0,
            notes: formData.notes,
            type: formData.type.value,
            isMarketingProject: formData.isMarketingProject.value
        }

        createProject.mutate(projectData);

    }

    useEffect(() => {
        
    }, []);

    return (
        <>
            <button className={buttonClass} type='button' onClick={openModal}>{openButtonText}</button>
			<Modal
                isOpen={isOpen}
                onRequestClose={closeModal} 
                className="project-builder-modal project-builder-modal--large"
			>
                <button className="project-builder-modal-close" onClick={closeModal}>
                    <svg id="header-ask-close-svg" className="icon icon-close"><use xlinkHref="#icon-close"></use></svg>
                </button>
                <div className="container project-builder">
                    <div className='modal-title'>New Project</div>

                    <form className="project-builder-form" method="post" onSubmit={handleFormSubmit}>

                        <ProjectFormFields
                            formData={formData}
                            setFormData={setFormData}
                        />

                        <div className="form-actions">
                            <button className='btn no-arrow' type='submit' disabled={isSending}>create project</button>
                        </div>
                    </form>
                </div>
			</Modal>
        </>
	);
}