import React from "react";

const SearchTerms = props => {

    return (
        <div className="row justify-content-center">
            <div className="search-term-container">
                {props.searchTerms.map((item, index) => {
                    return <button className="search-term" onClick={props.clickTerm} key={index} value={item.SearchTerm}>{item.SearchTerm}</button>;
                })}
            </div>
            </div>
        
    );
}

export default SearchTerms;