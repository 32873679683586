import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProject } from '../../../apis/project-builder';
//import { useCurrentProjectIdContext } from "./project-builder-main.jsx";
import EditProjectModal from './edit-project-modal.jsx';
import DuplicateProjectModal from './duplicate-project-modal.jsx';
import ArchiveModal from './archive-modal.jsx';
import DeleteModal from './delete-modal.jsx';
//import ClickAwayListener from '@mui/base/ClickAwayListener';

export default function MyProjectsActions({ projectId, projectName }) {

    const { data } = useQuery(['project-detail', projectId], () => getProject(projectId));

    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const openArchiveModal = () => setIsArchiveOpen(true);
    const closeArchiveModal = () => setIsArchiveOpen(false);

    const openDeleteModal = () => setIsDeleteOpen(true);
    const closeDeleteModal = () => setIsDeleteOpen(false);

    const [showDropdown, setShowDropdown] = useState(false);

    const [pdfIsLoading, setPdfIsLoading] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    }

    const handleDownloadPdf = () => {
        setPdfIsLoading(true);

        const url = window.location.href;
        //let pdfUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/Projects/QuotePdf?projectGuid=" + data.Guid;
        let pdfUrl = url.split("/")[0] + "//" + url.split("/")[2] + "/api/projectBuilder/projects/" + projectId + "/pdf";

        fetch(pdfUrl, {
            //headers: {
            //    'Authorization': 'Bearer ' + authToken.Token
            //}
        }).then(x => x.blob())
        .then(x => {
            let a = document.createElement('a');
            a.href = URL.createObjectURL(x);
            a.setAttribute('target', '_blank');
            a.setAttribute('download', data.Name + ' Project Quote');
            a.click();
            a.remove();
            setPdfIsLoading(false);
        });

    }

    //const handleClickAway = () => {
    //    setShowDropdown(false);
    //}

    return (
        <div className="dropdown project-actions">
            <button
                className={`link-btn underline kebab dropdown-trigger ${showDropdown ? 'active' : ''}`}
                onClick={toggleDropdown}
            >
                <span></span>
                <span></span>
                <span></span>
            </button>

            {showDropdown ? (
                    <div className="dropdown-menu project-actions">
                        <div className="rounded-border rounded-border--paddingSmall">
                                <ul className="actions-list my-projects-actions">
                                    <li>
                                        <EditProjectModal
                                            openButtonText="Edit project details"
                                            buttonClass="link-btn"
                                            projectId={projectId}
                                            setShowDropdown={setShowDropdown}
                                        />
                                    </li>
                                    <li>
                                        <DuplicateProjectModal
                                            openButtonText="Duplicate this project"
                                            buttonClass="link-btn"
                                            projectId={projectId}
                                            setShowDropdown={setShowDropdown}
                                        />
                                    </li>
                                    <li>
                                        <button type="button" className="link-btn" onClick={openArchiveModal}>
                                            Archive this project
                                            <ArchiveModal isArchiveOpen={isArchiveOpen} closeArchiveModal={closeArchiveModal} projectId={projectId} />
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="link-btn" onClick={openDeleteModal}>
                                            Delete this project
                                    <DeleteModal isDeleteOpen={isDeleteOpen} closeDeleteModal={closeDeleteModal} type="project" projectId={projectId} projectName={projectName} />
                                        </button>
                                    </li>
                                    <li>
                                        {pdfIsLoading
                                            ? <div>Downloading PDF...</div>
                                            : <div onClick={handleDownloadPdf}>Download PDF Quote</div>
                                        }
                                    </li>
                                </ul>
                            </div>
                    </div>
            ) : null}
            
        </div>
    );
}